import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import RightPanelContainer from 'pages/viewer/ui/common/RightPanelContainer'
import TopToolbarContainer from 'pages/viewer/ui/common/TopToolbarContainer'
import ViewerLayout from 'pages/viewer/ui/common/ViewerLayout'
import { useRef } from 'react'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'

import DefectsLeftPanelContainer from './tree/DefectsLeftPanelContainer'

const DefectsViewerContainer = () => {
  const ref = useRef<HTMLDivElement>(null)
  const { isDefectsVisible } = useSettingsAndUserRoles()
  const { slides } = useSlidesDefects()

  return isDefectsVisible ? (
    <ViewerLayout
      slides={slides}
      leftRef={ref.current}
      topSlot={<TopToolbarContainer />}
      leftSlot={<DefectsLeftPanelContainer leftRef={ref} />}
      rightSlot={<RightPanelContainer />}
    />
  ) : null
}

export default DefectsViewerContainer
