import { useTypedSelector } from 'app/redux/lib/selector'
import { AxiosRequestConfig } from 'axios'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { useMemo } from 'react'
import IUserRole, { IRoleStatus } from 'types/IUserRole'
import {
  ISetting,
  IWorkspace,
  SettingsNameEnum,
  TWsSettingsBoolean,
  TWsSettingsInteger,
  TWsSettingsString,
} from 'types/IWorkspaces'

const WORKSPACE_URLS = new Map()
WORKSPACE_URLS.set('department', true)
WORKSPACE_URLS.set('patient', true)
WORKSPACE_URLS.set('case', true)
WORKSPACE_URLS.set('users', true)
WORKSPACE_URLS.set('share', true)
WORKSPACE_URLS.set('uploaded-file', true)
WORKSPACE_URLS.set('uploaded-files', true)
WORKSPACE_URLS.set('dictionary', true)

export const getStorageWorkspaceId = () => localStorage.getItem('workspaceId')

export const workspaceURLHandler = (url: string, config?: AxiosRequestConfig | undefined, workspaceId?: string) => {
  const finalWorkspaceId = workspaceId || getStorageWorkspaceId() || getWorkspaceIdUrl(window.location.href)
  const parsedUrl = url.split('/')
  if (WORKSPACE_URLS.get(parsedUrl[1])) {
    return '/workspace/' + (config?.params?.source === 'ATLAS' ? 'atlas' : finalWorkspaceId) + url
  } else return url
}

export const workspaceChangeIdURL = (url: string, id: string) => {
  const parsedUrl = url.split('/')
  let index
  parsedUrl?.find((item, i) => {
    if (item === 'workspace') index = i
  })
  if (!index) return
  parsedUrl[index + 1] = id
  return parsedUrl.join('/')
}

export const getWorkspaceIdUrl = (url: string) => {
  const parsedUrl = url.split('/')
  let index
  parsedUrl?.find((item, i) => {
    if (item === 'workspace') index = i
  })
  if (!index) return
  return parsedUrl[index + 1]
}

export const configURLHandler = (config?: AxiosRequestConfig | undefined) => {
  if (config?.params?.source) {
    delete config?.params.source
  }
  return config
}
/** Функция определения доступности в зависимости от настроек workspace и значений по умолчанию */
export const getBooleanOrDefaultAccess = (
  /** Название поля */
  name: SettingsNameEnum,
  /** Доступность при отсутствии данных по полю */
  defaultAccess: boolean,
  /** Воркспейс, из которого получены настройки */
  currentWorkspace?: IWorkspace | null,
) => {
  const access = currentWorkspace?.settings.find((key: { fieldName: SettingsNameEnum }) => key.fieldName === name)
  if (access?.fieldName === SettingsNameEnum.LisMode) return access.value === 'gemotest'
  if (access?.fieldName === SettingsNameEnum.CaseStageManagement) return access.value === 'lisOnly'
  return access ? access.value === 'true' : defaultAccess
}

const useUserRoles = (authorities: IUserRole[]) =>
  useMemo(() => {
    const roleMapping = {
      [IUserRole.ROLE_ARCHIVE_MANAGER]: 'isRoleArchiveManager',
      [IUserRole.ROLE_ATLAS_VALIDATION]: 'isRoleAtlasValidation',
      [IUserRole.ROLE_CASE_ASSIGNMENT]: 'isRoleCaseAssignment',
      [IUserRole.ROLE_GUEST]: 'isRoleGuest',
      [IUserRole.ROLE_IMAGE_MANAGER]: 'isRoleImageManager',
      [IUserRole.ROLE_MARKUP_USER]: 'isRoleMarkupUser',
      [IUserRole.ROLE_SLIDE_DOWNLOAD_ATLAS]: 'isRoleSlideDownloadAtlas',
      [IUserRole.ROLE_TEST_USER]: 'isRoleTestUser',
      [IUserRole.ROLE_UNSORTED_IMAGE_MANAGE]: 'isRoleUnsortedImageManage',
      [IUserRole.ROLE_DOCTOR]: 'isRoleDoctor',
      [IUserRole.ROLE_USER]: 'isRoleUser',
      [IUserRole.ROLE_QUALITY_MANAGER]: 'isRoleQualityManager',
    }

    const roles: IRoleStatus = {}

    for (const [key, value] of Object.entries(roleMapping)) {
      roles[value as keyof IRoleStatus] = authorities.includes(IUserRole[key as IUserRole])
    }

    return roles
  }, [authorities])

const useWorkspaceSettings = (settings: ISetting[]) =>
  useMemo(
    () =>
      Object.values(SettingsNameEnum).reduce(
        (acc, settingKey) => {
          const setting = settings.find((s) => s.fieldName === (settingKey as string))

          if (setting) {
            if (setting.value === 'true' || setting.value === 'false') {
              acc.booleanSettings[settingKey as keyof TWsSettingsBoolean] = setting.value === 'true'
            } else if (setting.valueInt != null) {
              acc.integerSettings[settingKey as keyof TWsSettingsInteger] = setting.valueInt
            } else if (setting.value) {
              acc.stringSettings[settingKey as keyof TWsSettingsString] = setting.value
            }
          }

          return acc
        },
        {
          /** булевые настройки */
          booleanSettings: {} as TWsSettingsBoolean,
          /** числовые настройки */
          integerSettings: {} as TWsSettingsInteger,
          /** строковые настройки */
          stringSettings: {} as TWsSettingsString,
        },
      ),
    [settings],
  )

export const useSettingsAndUserRoles = () => {
  const authorities = useTypedSelector((state) => state.user.authorities)
  const settings = useTypedSelector((state) => state.workspaces.currentWorkspace?.settings)

  /** Объект с ролями юзера. Роль - true / false */
  const roles = useUserRoles(authorities || [])
  /** Объект со всеми настройками ws */
  const wsSettings = useWorkspaceSettings(settings || [])
  /** Активная интеграция */
  const isLisMode = useLisMode() !== 'none'
  /** Активен контроль сроков */
  const isDeadlineMode = wsSettings.stringSettings.deadlineMode !== 'none'

  const { isRoleCaseAssignment, isRoleGuest, isRoleQualityManager, isRoleUser } = roles

  /** Состояние настройки ws*/
  /** caseRouting*/
  const isCaseRouting = wsSettings?.booleanSettings?.caseRouting
  /** Доступность меню табов */
  /** распределение*/
  const isRoutingVisible = wsSettings?.booleanSettings?.caseRouting && isRoleCaseAssignment
  /** мои случаи */
  const isWorklistVisible = isRoleUser || isRoleGuest
  /** все случаи */
  const isWorkspaceVisible = isCaseRouting && (isRoleUser || isRoleCaseAssignment)
  /** Доступность меню табов 2-го уровня */
  const isRemoveVisible = wsSettings?.booleanSettings?.canRemoveCase
  /** Доступность информации о "дозаказах" */
  const isResearchRequestEnabled = !!wsSettings?.booleanSettings?.researchRequestEnabled
  /** Доступность аннотирования слайда дефектом */
  const isEnableSlideDefects = wsSettings.booleanSettings.enableSlideDefects
  /** дефекты */
  const isDefectsVisible = isEnableSlideDefects && isRoleQualityManager

  return {
    isCaseRouting,
    isDeadlineMode,
    isDefectsVisible,
    isEnableSlideDefects,
    isLisMode,
    isRemoveVisible,
    isResearchRequestEnabled,
    isRoleCaseAssignment,
    isRoleQualityManager,
    isRoutingVisible,
    isWorklistVisible,
    isWorkspaceVisible,
    roles,
    ...wsSettings,
  }
}

/** Хук со списком доступных табов */
export const useAvailableMenuTabs = () => {
  const { isDefectsVisible, isRoutingVisible, isWorklistVisible, isWorkspaceVisible } = useSettingsAndUserRoles()
  return useMemo(
    () =>
      [
        {
          menuTab: ECaseTableType.ROUTING,
          visible: isRoutingVisible,
        },
        { menuTab: ECaseTableType.WORKLIST, visible: isWorklistVisible },
        { menuTab: ECaseTableType.WORKSPACE, visible: isWorkspaceVisible },
        { menuTab: ECaseTableType.DEFECTS, visible: isDefectsVisible },
      ]
        .filter((menu) => menu.visible)
        .map((menu) => menu.menuTab),
    [isRoutingVisible, isWorklistVisible, isWorkspaceVisible],
  )
}

export const useLisModeAsGemotest = () => {
  const integration = useLisMode()
  return integration === 'gemotest'
}
