import React, { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { pluralText } from 'shared/lib/text'
import { SmallIconButtonElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { createPolygonAreaLabel } from 'viewer/map/layers/annotations/lib/annotationsDrawHelpers'

type Props = {
  numberOfAnnotations: number
  totalArea: number
  groupName: string
  icon: ReactNode
  setAnnotationsIsVisibleByClass: (name: string) => void
  isVisible: boolean
}

const StyledAnnotationListItem = styled.div<{ active?: boolean }>`
  color: var(--color-text-3);
  gap: 4px;
  background: ${({ active }) => (active ? 'var(--color-bg-3)' : 'transparent')};
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 4px;

  &:hover {
    background: var(--color-bg-3);
  }
`

const AnnotationsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`
const Title = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: 8px;
  align-items: center;
`
const AnnotationByGroupListItem = ({
  groupName,
  icon,
  isVisible,
  numberOfAnnotations,
  setAnnotationsIsVisibleByClass,
  totalArea,
}: Props) => {
  const { t } = useTranslation()
  return (
    <StyledAnnotationListItem>
      <Title>
        {icon}
        <TextElement ellipsis>{groupName}</TextElement>
        <div />
        <AnnotationsInfo>
          <div style={{ display: 'flex' }}>
            <div>{`${numberOfAnnotations} ${pluralText(numberOfAnnotations, [
              t('область'),
              t('области'),
              t('областей'),
            ])}`}</div>
            {totalArea ? (
              <div
                dangerouslySetInnerHTML={{
                  __html: `, ${createPolygonAreaLabel(totalArea, 'area')}`,
                }}
              />
            ) : (
              ''
            )}
          </div>
          <SmallIconButtonElement
            icon={isVisible ? 'eye' : 'eyeClose'}
            style={{ color: 'var(--color-text-3)' }}
            onClick={() => setAnnotationsIsVisibleByClass(groupName)}
          />
        </AnnotationsInfo>
      </Title>
    </StyledAnnotationListItem>
  )
}

export default AnnotationByGroupListItem
