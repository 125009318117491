import { calculateVisibleRows, getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { ECaseCursor } from 'features/cases-management/types/ECaseTableType'
import { notices } from 'features/notices'
import { useEffect } from 'react'
import { convertToUpperCaseWithUnderscore } from 'shared/ui/table/lib/helpers'
import { ICaseDTO } from 'types/ICase'
import useDeepCompareEffect from 'use-deep-compare-effect'

import { IPageParams, TUseCustomTableEffects } from './useCustomTableEffects.types'

const getCaseByKey = (key: number, data: ICaseDTO[]) => data.filter((item) => item.caseId === key)
const getIndexByKey = (key: number, data: ICaseDTO[]) => data.findIndex((item) => item.caseId === key)

/** Таймаут для того, чтобы начать скроллить к выделленому кесу. Нужен для того, чтобы все эффекты успели обработаться и обновились стили*/
const SCROLL_TIMEOUT = 100

export const useCustomTableEffects = ({
  casesList,
  checkScrollDirection,
  cursor,
  fetchNextPage,
  fetchPrevCursor,
  fileCaseId,
  filterParams,
  firstRender,
  handleRowClick,
  hasNextPage,
  isCaseAssigned,
  isCaseRouting,
  isDeadlineMode,
  isFetching,
  isGemotest,
  isLoading,
  isPanelPreviewVisible,
  menuTab,
  openPanel,
  prevSelectedRowIndexRef,
  queryClient,
  refetch,
  resetSelection,
  searchParams,
  selectedCase,
  selectedCaseId,
  selectedCases,
  selectedContextCase,
  selectedRecord,
  selectedRowIndex,
  setCursor,
  setIsCaseAssigned,
  setOpenPanel,
  setPanelPreviewVisible,
  setSelectedCase,
  setSelectedCases,
  setSelectedRowIndex,
  setTableBodyHeight,
  setTabsQueryParams,
  setVisibleRowsCount,
  sortConfig,
  tabType,
  tableContainerRef,
  tabsQueryParams,
  tusFileIds,
  visibleRowsCount,
  workspaceId,
}: TUseCustomTableEffects) => {
  /** При изменении масштаба страницы, перезапрашиваем данные для таблицы*/
  useEffect(() => {
    refetchPages(
      queryClient,
      menuTab,
      { tab: tabType, ...getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting, isDeadlineMode, isGemotest) },
      filterParams,
      workspaceId,
      visibleRowsCount,
    )
  }, [visibleRowsCount])

  /** Изменение сортировки */
  useDeepCompareEffect(() => {
    setTabsQueryParams({
      ...tabsQueryParams,
      [menuTab]: {
        ...tabsQueryParams[menuTab],
        [tabType]: {
          queryParams: {
            sortBy:
              (sortConfig?.field && convertToUpperCaseWithUnderscore(sortConfig?.field)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting, isDeadlineMode, isGemotest)?.sortBy,
            sortDir:
              (sortConfig?.order && convertToUpperCaseWithUnderscore(sortConfig?.order)) ||
              getQueryParams(tabsQueryParams, menuTab, tabType, isCaseRouting, isDeadlineMode, isGemotest).sortDir,
          },
        },
      },
    })
  }, [tabsQueryParams, sortConfig, menuTab, tabType, isCaseRouting, isDeadlineMode, isGemotest])

  /** Установка курсора в стейт для инициоравания запроса на список в таблицу */
  useEffect(() => {
    if (selectedCaseId) {
      fetchPrevCursor(selectedCaseId).then((cursor) => {
        if (cursor) setCursor(cursor)
      })
    } else {
      setCursor(ECaseCursor.NULL)
    }
  }, [])

  /** После подгрузки курсора, выделяем нужную строку */
  useEffect(() => {
    if (!isLoading && !isFetching && cursor && cursor !== ECaseCursor.NULL) {
      // кейс, когда выделили первую запись и для нее приходит previousPageCursor
      // TODO бек обещал доработать данную проблему и для первой записи списка не присылать previousPageCursor
      if (!casesList?.length) {
        setCursor(ECaseCursor.NULL)
        // делаем перезапрос макротаской для того, чтобы успел установиться стейт курсора
        // TODO + добавил время таймаута, почему-то нулевой таймайт перестал отрабатывать в нужное время, разобраться!!!
        setTimeout(() => {
          refetch().then((result) => {
            // индекс первой записи
            setSelectedRowIndex(0)
            // берем первую запись первой (едиственной) страницы
            setSelectedCase({ index: 0, record: result.data?.pages[0][0] as ICaseDTO })
          })
        }, 1)
      } else if (hasNextPage) {
        fetchNextPage().then((result) => {
          const pageParams = result?.data?.pageParams as IPageParams[]
          const pageParamsSize = pageParams?.[0]?.size
          // берем последнюю загруженную страницу
          const currentPage = result.data?.pages?.at(-1) || []
          // получаем количество записей, перед строкой, которую нужно выделить
          const previousCount = pageParamsSize * (Number(result?.data?.pages?.length) - 1)
          const selRecordIndex = currentPage?.findIndex((caseItem) => caseItem.caseId === Number(selectedCaseId))
          if (selRecordIndex !== -1) {
            setPanelPreviewVisible(true)
            const nodeRows = document.querySelectorAll('.ant-table-row')
            // берем последнюю строку
            const lastRow = nodeRows[nodeRows.length - 1]
            setSelectedRowIndex(previousCount + selRecordIndex)
            setSelectedCase({ index: previousCount + selRecordIndex, record: currentPage[selRecordIndex] })
            setTimeout(() => {
              lastRow?.scrollIntoView({ behavior: 'smooth', block: 'start' })
            }, SCROLL_TIMEOUT)
          }
        })
        setCursor(ECaseCursor.NULL)
      }
    }
  }, [isLoading, isFetching, cursor])

  useDeepCompareEffect(() => {
    const updatedCases = selectedCases.map((prevSelectedCase: ICaseDTO) => {
      const updatedValue = casesList.find((updatedValue: ICaseDTO) => updatedValue.caseId === prevSelectedCase.caseId)
      return updatedValue ?? prevSelectedCase
    })
    setSelectedCases(updatedCases)

    /** Проверяем наличие выбранного кейса в обновленной таблице */
    if (Number.isInteger(selectedCase?.index) && selectedRowIndex && !isFetching && !isLoading) {
      const isCaseInList = casesList.find((item) => item.caseId === selectedCase?.record?.caseId)
      /** При отсутствии кейса, вызывать нотификацию с подсказкой что случай больше не доступен */
      if (!isCaseInList && !isCaseAssigned) {
        notices.openCaseOtherTopic({
          numberCase: String(selectedCase?.record?.name),
        })
        setIsCaseAssigned(false)
        setSelectedRowIndex(null)
        setPanelPreviewVisible(false)
        return
      }
    }
    // обработка для открытия панели превью при смене таба, если есть ранее выделенная строка
    if (selectedRowIndex && !isLoading && !isFetching) {
      if (tabType === searchParams.get('tab') && !!selectedCaseId) {
        setSelectedCase({ index: selectedRowIndex, record: casesList[selectedRowIndex] })
        setPanelPreviewVisible(!!casesList[selectedRowIndex])
      } else {
        setSelectedRowIndex(null)
        setPanelPreviewVisible(false)
      }
    }
  }, [casesList, selectedCase, selectedRowIndex, isLoading, isFetching, tabType, isCaseAssigned])

  useEffect(() => {
    const hasSelectedCases = selectedCases.length
    /** Проверяем наличие выбранных кейсов в обновленной таблице */
    if (hasSelectedCases && !isFetching && !isLoading && !isCaseAssigned) {
      const deletedSelectedCases = selectedCases.filter((element) => !casesList.includes(element))
      /** При отсутствии кейсов, вызывать нотификацию с подсказкой что случай больше не доступен */
      if (deletedSelectedCases.length) {
        deletedSelectedCases.forEach((selectedCase) =>
          notices.openCaseOtherTopic({
            numberCase: String(selectedCase.name),
          }),
        )
        setPanelPreviewVisible(false)

        const filteredSelectedCases = selectedCases.filter((element) => !deletedSelectedCases.includes(element))
        setSelectedCases(filteredSelectedCases)
        return
      }
    }
  }, [selectedCases, casesList, isLoading, isFetching, isCaseAssigned])

  // сбрасывваем выделение кейса при смене фильтров
  useDeepCompareEffect(() => {
    // resetSelectedRow() меняет URL. Для правильной работы ссылок пропускаем первый рендер
    if (!firstRender.current) resetSelection()
    else firstRender.current = false
  }, [filterParams])

  useEffect(() => {
    const csId = selectedContextCase?.caseId
    if (!csId) return
    const caseData = getCaseByKey(csId, casesList)[0]
    const indexCaseId = getIndexByKey(csId, casesList)
    if (openPanel && csId && selectedRecord?.caseId !== caseData?.caseId) {
      handleRowClick(caseData, indexCaseId ?? null)
      setOpenPanel(false)
    }
  }, [fileCaseId, tusFileIds, openPanel, selectedRecord?.caseId])

  useEffect(() => {
    if (tusFileIds && openPanel) {
      setOpenPanel(false)
    }
  }, [openPanel, tusFileIds])

  useEffect(() => {
    if (!isPanelPreviewVisible) {
      setSelectedCase(null)
      setOpenPanel(false)
      calculateVisibleRows(tableContainerRef, setTableBodyHeight, setVisibleRowsCount)
    }
  }, [isPanelPreviewVisible])

  useEffect(() => {
    const currentSelectedRow = document.querySelector('.selected-row') as HTMLElement | null
    const previousRow = currentSelectedRow?.previousElementSibling as HTMLElement | null
    const tableOverFlowWrapper = document.querySelector('.over-flow-table-wrapper') as HTMLElement | null

    if (currentSelectedRow !== null && previousRow !== null) {
      if (checkScrollDirection() === 'down') {
        currentSelectedRow.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
        })
      } else {
        previousRow.scrollIntoView({ behavior: 'smooth', block: 'nearest' })
      }
    }
    //Скролл в начало таблицы, когда скролим вверх и нужно доскролить до первого ряда
    if (tableOverFlowWrapper !== null && previousRow === null) {
      tableOverFlowWrapper.scrollTo({ behavior: 'smooth', top: 0 })
    }
    prevSelectedRowIndexRef.current = selectedRowIndex
  }, [selectedRowIndex])

  useEffect(() => {
    const resizeHandle = () => {
      setPanelPreviewVisible(false)
      calculateVisibleRows(tableContainerRef, setTableBodyHeight, setVisibleRowsCount)
    }
    /** Рассчитываем количество строк при монтировании компонента */
    resizeHandle()
    /** Добавляем обработчик изменения размера окна для пересчета количества строк */
    window.addEventListener('resize', resizeHandle)
    return () => {
      /** Убираем обработчик при размонтировании компонента  */
      window.removeEventListener('resize', resizeHandle)
    }
  }, [])
}
