import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { RESEARCH_REQUEST_TYPE, STAIN_TYPE } from 'features/additional-order/api/type'
import { ADDITIONAL_ORDER } from 'features/additional-order/ui/AdditionalOrderInfo/constants'
import { TStainDict } from 'features/dictionaries/lib/handlers'
import { t } from 'i18next'
import React, { useMemo, useState } from 'react'
import { CheckboxElement, InputElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'

import { Stain, StainSelectorProps } from './types'

/**
 * Компонент для выбора окрасок.
 *
 * @component
 * @param {StainSelectorProps} props - Свойства компонента StainSelector.
 * @param {Stain[]} props.selectedStains - Массив выбранных окрасок.
 * @param {function} props.onStainSelect - Функция обратного вызова для обработки выбора окрасок.
 * @param {function} props.requestType -  Тип дозаказа из новой окраски.
 * @returns {JSX.Element} Возвращает компонент StainSelector.
 */
export const StainSelector: React.FC<StainSelectorProps> = ({
  disabled,
  onStainSelect,
  requestType,
  selectedStains,
  onBlur,
  onFocus,
}: StainSelectorProps): JSX.Element => {
  const dictionary = useLiveQuery(() => getDataFromDB('STAIN')) as TStainDict
  const { data: stains = [] } = { ...dictionary }
  const [search, setSearch] = useState('')

  /** Отфильтрованные окраски по типу дозаказа и полю поиска */
  const filteredStains = useMemo(
    () =>
      stains?.filter(({ code, name, shortName, stainType }) => {
        if (
          (requestType === RESEARCH_REQUEST_TYPE.NEW_STAIN_HC && stainType !== STAIN_TYPE.HC) ||
          (requestType !== RESEARCH_REQUEST_TYPE.NEW_STAIN_HC && stainType === STAIN_TYPE.HC)
        ) {
          return false
        }
        return (
          code.toLowerCase().includes(search.toLowerCase()) ||
          shortName.toLowerCase().includes(search.toLowerCase()) ||
          name.toLowerCase().includes(search.toLowerCase())
        )
      }),

    [stains, search, requestType],
  )

  // Группировка окрасок по категориям
  const groupedStains = useMemo(
    () =>
      filteredStains?.reduce<Record<string, Stain[]>>((groups, stain) => {
        const category: string = stain.stainType ? ADDITIONAL_ORDER[`NEW_STAIN_${stain.stainType}`] : t('Без категории')
        if (!groups[category]) {
          groups[category] = []
        }
        groups[category].push(stain)
        return groups
      }, {}),
    [filteredStains],
  )

  /**
   * Обработчик выбора окраски.
   *
   * @param {Stain} stain - Элемент выбранной окраски.
   */
  const handleSelect = (stain: Stain) => {
    const updatedStains = selectedStains?.find((selectedStain) => selectedStain.id === stain.id)
      ? selectedStains.filter((selectedStain) => selectedStain.id !== stain.id)
      : [...selectedStains, stain]

    onStainSelect(updatedStains)
  }

  return (
    <Container>
      <InputElement
        style={{ marginTop: `8px` }}
        placeholder={t('Поиск по окраскам')}
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      <CheckboxContainer>
        {Object.keys(groupedStains).map((category) => (
          <div key={category}>
            <CategoryLabel>{t(category)}</CategoryLabel>
            {groupedStains[category].map((stain) => (
              <div key={stain.id}>
                <StainCheckboxElement
                  disabled={disabled}
                  checked={!!selectedStains.find((selectedStain) => selectedStain?.id == stain.id)}
                  onChange={() => handleSelect(stain)}
                >
                  <StainTextElement ellipsis>{t(stain.name)}</StainTextElement>
                </StainCheckboxElement>
              </div>
            ))}
          </div>
        ))}
      </CheckboxContainer>
    </Container>
  )
}

/** Стили для основного контейнера */
const Container = styled.div`
  display: flex;
  flex-direction: column;
`

/** Стили для контейнера чекбоксов с фиксированной высотой и прокруткой */
const CheckboxContainer = styled.div`
  max-height: 260px;
  overflow-y: auto;
  padding: 0 4px;
  margin-top: 8px;
`

/** Стили для заголовка категории */
const CategoryLabel = styled.div`
  font-weight: 500;
  line-height: 24px;
`
const StainCheckboxElement = styled(CheckboxElement)`
  padding: 4px 6px;
  border-radius: 5px;
  width: 100%;

  :hover {
    background-color: var(--color-bg-3);
  }
`

const StainTextElement = styled(TextElement)`
  &.ant-typography {
    font-size: 13px;
    line-height: 18px;
    max-width: 200px;
  }
`
