import { Feature } from 'ol'
import { MultiPolygon, Polygon } from 'ol/geom'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import { Fill, Stroke, Style } from 'ol/style'
import Hover from 'ol-ext/interaction/Hover'
import Tooltip from 'ol-ext/overlay/Tooltip'
import { useViewerIdMap } from 'pages/viewer/lib/common/ViewerPageProvider'
import { FC, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import TViewerId from 'types/TViewerId'
import useDeepCompareEffect from 'use-deep-compare-effect'
import { INTERNAL_TYPE_ID, LayerType } from 'viewer/map/lib/MapConstants'

import { useArtefactsContext } from './ArtefactsContext'

const HUNDRED = 100

type Props = {
  viewerId: TViewerId
}

const ArtefactsLayer: FC<Props> = ({ viewerId }) => {
  const map = useViewerIdMap(viewerId)
  const { defectsInfoDict, features, hoveredClass, opacity, setHoveredClass } = useArtefactsContext()
  const { t } = useTranslation()

  /** Тултип для отображения информации при наведении */
  const tooltip = useRef<Tooltip | null>(null)
  /** Слой векторов для отрисовки заливки полигонов */
  const fillLayer = useRef<VectorLayer<VectorSource<Polygon>> | null>(null)
  /** Слой векторов для отрисовки обводки полигонов */
  const strokeLayer = useRef<VectorLayer<VectorSource<Polygon>> | null>(null)
  /** Обработчик наведения курсора для интерактивности */
  const hover = useRef<Hover | null>(null)

  /**
   * Обработчик события наведения курсора на объект карты
   * @param {Object} evt - Событие наведения курсора
   * @param {Feature} evt.feature - Объект, на который наведен курсор
   */
  const onHoverEnter = (evt: any) => {
    if (!tooltip.current || !map) return
    tooltip.current.setFeature(evt.feature)
    map.addOverlay(tooltip.current)
    setHoveredClass(evt.feature.get('class'))
  }

  /**
   * Обработчик события ухода курсора с объекта карты
   * Очищает тултип и сбрасывает выделенный класс объекта
   */
  const onHoverLeave = () => {
    if (!tooltip.current || !map) return
    tooltip.current.setFeature(null)
    map.removeOverlay(tooltip.current)
    setHoveredClass()
  }

  // Инициализация всех рефов
  useDeepCompareEffect(() => {
    if (!map || !defectsInfoDict) return

    // Инициализация tooltip
    tooltip.current = new Tooltip({
      getHTML: (f: Feature<MultiPolygon>) =>
        `<span>${defectsInfoDict[f.get('class')] ? t(defectsInfoDict[f.get('class')].name) : ''}</span>`,
      maximumFractionDigits: 2,
      offsetBox: 20,
      popupClass: 'custom-ol-draw-tooltip',
      positionning: 'auto',
    } as any)

    // Инициализация fillLayer
    fillLayer.current = new VectorLayer({
      opacity: opacity / HUNDRED,
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          fill: new Fill({
            color: defectsInfoDict[f.get('class')].mlClassColor,
          }),
        }),
      visible: true,
      zIndex: 1,
    })

    // Инициализация strokeLayer
    strokeLayer.current = new VectorLayer({
      source: new VectorSource({ features }),
      style: (f) =>
        new Style({
          stroke: new Stroke({
            color: defectsInfoDict[f.get('class')].mlClassColor,
            width: 2,
          }),
        }),
      visible: true,
      zIndex: 1,
    })

    // Инициализация hover
    // @ts-ignore
    hover.current = new Hover({
      cursor: 'pointer',
      hitTolerance: 10,
      layerFilter: (layer) => layer?.get(INTERNAL_TYPE_ID) === LayerType.ARTEFACTS,
    })

    // Добавление слоев на карту
    if (fillLayer.current) {
      fillLayer.current.set(INTERNAL_TYPE_ID, LayerType.ARTEFACTS)
      map.addLayer(fillLayer.current)
    }
    if (strokeLayer.current) {
      map.addLayer(strokeLayer.current)
    }

    return () => {
      if (fillLayer.current) map.removeLayer(fillLayer.current)
      if (strokeLayer.current) map.removeLayer(strokeLayer.current)
    }
  }, [map, defectsInfoDict, features, opacity, t])

  useEffect(() => {
    if (fillLayer.current) {
      fillLayer.current.setOpacity(opacity / HUNDRED)
    }
  }, [opacity])

  useEffect(() => {
    if (strokeLayer.current && defectsInfoDict) {
      strokeLayer.current.setStyle((f) => {
        const className = f.get('class') as string
        return new Style({
          stroke:
            hoveredClass === className
              ? new Stroke({
                  color: defectsInfoDict[className].mlClassColor,
                  width: 2,
                })
              : undefined,
        })
      })
    }
  }, [hoveredClass, defectsInfoDict])

  useDeepCompareEffect(() => {
    if (!map || !hover.current || !fillLayer.current || !strokeLayer.current) return

    fillLayer.current.getSource()?.clear()
    fillLayer.current.getSource()?.addFeatures(features)
    strokeLayer.current.getSource()?.clear()
    strokeLayer.current.getSource()?.addFeatures(features)

    // @ts-ignore
    hover.current.on('enter', onHoverEnter)
    // @ts-ignore
    hover.current.on('leave', onHoverLeave)
    map.addInteraction(hover.current)

    return () => {
      if (hover.current && map) {
        onHoverLeave()
        hover.current = null
      }
    }
  }, [features, map, defectsInfoDict])

  return null
}

export default ArtefactsLayer
