import { Department } from 'entities/department/types'
import { FieldSet } from 'entities/lis-integration/types'
import { IPermissionLevel } from 'entities/permissions'
import { EFavoriteList } from 'features/cases-management/types/ECaseTableType'

import ICaseStatus from './ICaseStatus'
import ICommentReadStatus from './ICommentReadStatus'
import { DictionaryItem } from './IDictionary'
import IPatient, { IPatientDTO } from './IPatient'
import IReport from './IReport'
import { SlideDefectType } from './ISlide'
import ISource from './ISource'
import IUser from './IUser'
import { IWorkspace } from './IWorkspaces'

export type ICaseType = 'PATHOLOGICAL' | 'RADIOLOGICAL'
export enum ICaseRelation {
  OWNER = 'OWNER',
  SHARED = 'SHARED',
  RESTRICTED = 'RESTRICTED',
}
export type ICaseUser = {
  email: string
  fullname: string
  userId: number
}

export type TSlideStats = {
  referencesCount: number
  slidesCount: number
}

export type ICaseDTO = {
  createdAt: string | null // 2020-09-07T09:43:32.486051
  description: string
  caseId: number
  casePathologicalInfo: ICasePathologicalInfo
  caseRadiologicalInfo: ICaseRadiologicalInfo
  materialTakeoutDate: string //2020-09-07T09:43:32.486051
  name: string | null
  /** Информация о пациенте */
  patient: IPatientDTO | IPatient
  /** Дата истечения срока */
  dueDate: Date | null
  /** Статус истечения срока */
  expirationState: EExpirationState | null
  relation: ICaseRelation | null
  caseType: ICaseType
  source: ISource
  status: ICaseStatus | null
  slideDefect: SlideDefectType | null
  slideStats: TSlideStats
  updatedAt: string | null // 2020-09-07T09:43:32.486051
  deletedAt: string | null // 2020-09-07T09:43:32.486051
  deleted?: boolean
  user: ICaseUser
  siteSpecificMetadata?: {
    type: string
    gleasonGroupCalculated?: number
    gleasonGroupManual?: number
  } | null
  /** информация о комментариях */
  commentInfo: ICommentReadStatus | null
  /** заключения случая */
  reports?: IReport[]
  /** уровень пользователей */
  permissionLevel?: IPermissionLevel
  /** информация о воркспейсе */
  workspace?: IWorkspace
  integration?: { active: boolean }
  modalities: IModalities[]
  services?: DictionaryItem[]
  assignedDoctor?: IAssignedDoctor
  stage?: ECaseStageQuery
  /** Срочность */
  urgent?: boolean
  /** причина отказа */
  declinementComment?: string
  /** Списки отслежтиваемых для кейса */
  favoriteLists: EFavoriteList[]
} & CaseIntegration

type CaseIntegration = {
  // gemotest
  caseDate?: string
  /** Номер заказа */
  orderNumber?: string
  comment?: string
  // DZM
  /** Случай ИГХ или не ИГХ */
  fieldSet?: FieldSet
  /** Номер пробы (МИС) */
  biomaterial?: string
}

export type ICase = ICaseDTO &
  CaseIntegration & {
    ohivPath?: string
  }

export type ICaseDocument = {
  caseId: number
  createdAt: string
  documentId: number
  fileName: string
  fileSize: number
  mimeType: string
  userId: number
}

export type ICaseDocumentData = {
  description: string
  filename: string
  inputStream: Record<string, never>
  open: boolean
  readable: boolean
  uri: string
  url: string
}

type CasePathologicalInfoIntegration = {
  // gemotest
  materialType?: DictionaryItem
  caseResearchType?: DictionaryItem
  task?: string
}

export type ICasePathologicalInfo = CasePathologicalInfoIntegration & {
  casePathologicalInfoId: number
  additionalInfo?: string
  clinicalInfo?: string
  department?: Department | null
  diagnosticProcedureType?: DictionaryItem | null
  incomingIcd10?: Partial<DictionaryItem> | null
  macroDescription?: string
  sites: { container: null; site: DictionaryItem }[] | null
}

export type ICaseRadiologicalInfo = {
  description: string
  site: string
}

export type IModalities = {
  modalityId: number
  name: string
  description: string
}
export default ICase

export type IAssignedDoctor = {
  userId: number
  email: string
  login: string
  fullname: string
  preferredLanguage: string
}

export type ICaseHistory = {
  /** Идентификатор лога изменения стадии дела. */
  caseStageLogId: number
  /** Пользователь, который инициировал изменение стадии. */
  user: IUser
  /** Предыдущая стадия дела. */
  oldStage: ECaseStageQuery
  /** Новая стадия дела. */
  newStage: ECaseStageQuery
  /** Доктор, назначенный ранее. */
  oldAssignedDoctor: IUser
  /** Новый назначенный доктор. */
  newAssignedDoctor: IUser
  /** Комментарий к изменению стадии. */
  comment: string
  /** Дата и время изменения стадии. */
  stageChangedAt: string
}

export enum ECaseStageQuery {
  OPEN = 'OPEN',
  WAITING_SLIDES = 'WAITING_SLIDES',
  ON_DISTRIBUTION = 'ON_DISTRIBUTION',
  ASSIGNED = 'ASSIGNED',
  ON_DISTRIBUTION_DECLINED = 'ON_DISTRIBUTION_DECLINED',
  COMPLETED = 'COMPLETED',
}

/** Тип связанных кейсов для запроса */
export enum ECaseLinkFilter {
  /** Только родительские */
  PARENT = 'PARENT',
  /** Родительские и дочерние */
  ALL = 'ALL',
}

/** Тип связанного кейса */
export enum ECaseLinkType {
  /** Родительский */
  PARENT = 'PARENT',
  /** Дочерний */
  CHILD = 'CHILD',
}

/** Статус срока */
export enum EExpirationState {
  /** Не истек */
  NOT_EXPIRED = 'NOT_EXPIRED',
  /** Мало времени */
  ALMOST_EXPIRED = 'ALMOST_EXPIRED',
  /** Истек */
  EXPIRED = 'EXPIRED',
}

/** Тип для связанных кейсов */
export type TCaseLinkDTO = {
  caseId: number
  name: string
  /** Тип связи кейса */
  linkType: ECaseLinkType | null
  caseDate: string
  relation: ICaseRelation
  status: ICaseStatus | null
  createdAt: string
  user: ICaseUser
  workspace: IWorkspace
  slideStats: TSlideStats
  incomingIcd10: Partial<DictionaryItem> | null
  diagnosticProcedureType: DictionaryItem | null
  reports: IReport[]
}
