import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations'
import { useCaseQuery } from 'features/cases/api/query'
import { notices } from 'features/notices'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import { useViewerRoute } from 'pages/viewer/lib/common/hooks/useViewerRoute'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import {
  selectUrlCaseId,
  selectUrlSlideId,
  SideRightPanelType,
  viewerPageSlice,
} from 'pages/viewer/model/viewerPageSlice'
import NoSlides from 'pages/viewer/ui/common/NoSlides'
import RightPanelContainer from 'pages/viewer/ui/common/RightPanelContainer'
import TopToolbarContainer from 'pages/viewer/ui/common/TopToolbarContainer'
import ViewerLayout from 'pages/viewer/ui/common/ViewerLayout'
import WrongCase from 'pages/viewer/ui/common/WrongCase'
import { useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { ORIGIN_CASES_DATA_KEY } from 'shared/lib/common/storageKeys'
import { useQueryParams } from 'shared/lib/hooks'
import { Center, SpinElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'

import LeftPanelContainer from './tree/LeftPanelContainer'

const LoadingScreen = styled(Center)`
  background-color: var(--color-bg-1);
`

export const ViewerContainer = () => {
  const dispatch = useDispatch()
  const queryParams = useQueryParams()
  const { t } = useTranslation()
  const commentId = queryParams.get('commentId')
  const reportId: string | null = queryParams.get('medicalReportId')
  const ref = useRef<HTMLDivElement>(null)
  const { goToCase, goToCasesList, isCaseLoading, isNoSlide, isWrongCase } = useViewerRoute()
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId } = useViewerIdSlideState(viewerId)
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const urlSlideId = useSelector(selectUrlSlideId)
  const mainSelectedSimilarRegion = useTypedSelector((state) => state.search.mainSelectedSimilarRegion)
  const { data: caseResult } = useCaseQuery({ caseId: urlCaseId, source: 'PLATFORM' })
  const rightPanelType = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const { isLoadingCaseGrouped, slides, slidesIds } = useSlideGrouped(urlCaseId)

  useEffect(() => {
    const isSlideUnavailable = !slidesIds.some((item) => item === urlSlideId)
    if (isSlideUnavailable && urlSlideId && !isLoadingCaseGrouped) {
      notices.error({
        message: t(
          'Недоступен слайд, с которым вы работали до просмотра другого случая пациента. Открыт первый слайд.',
        ),
      })
    }
  }, [slidesIds, urlSlideId, isLoadingCaseGrouped])

  useEffect(() => {
    localStorage.removeItem(ORIGIN_CASES_DATA_KEY)
  }, [])

  useEffect(() => {
    const isCommentOrReport = commentId || reportId

    isCommentOrReport &&
      dispatch(
        viewerPageSlice.actions.showViewerRightPanel(commentId ? SideRightPanelType.COMMENTS : SideRightPanelType.CASE),
      )
  }, [])

  useEffect(() => {
    dispatch(annotationsSlice.actions.resetAnnotationFilter())
    return () => {
      dispatch(annotationsSlice.actions.resetAnnotationFilter())
    }
  }, [caseId])

  if (isCaseLoading && !mainSelectedSimilarRegion) {
    return (
      <LoadingScreen>
        <SpinElement size="large" />
      </LoadingScreen>
    )
  }

  if (isWrongCase && !mainSelectedSimilarRegion) {
    return (
      <LoadingScreen>
        <WrongCase onClick={goToCasesList} />
      </LoadingScreen>
    )
  }

  if (isNoSlide) {
    return <NoSlides onClick={goToCase} />
  }

  return (
    <ViewerLayout
      slides={slides}
      leftRef={ref.current}
      topSlot={<TopToolbarContainer />}
      leftSlot={<LeftPanelContainer leftRef={ref} caseResult={caseResult} />}
      rightSlot={rightPanelType && <RightPanelContainer />}
    />
  )
}

export default ViewerContainer
