import { TMode, useThemeContext } from 'app/styled/ThemeProvider'
import { FC, memo } from 'react'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { IconElement, TextElement } from 'shared/ui/kit'
import styled from 'styled-components'
import { SlideDefectType } from 'types/ISlide'

type TCaseNameProps = {
  /** Архивный случай? */
  isArchive: boolean
  caseName: string
  countDone?: number
  countTotal?: number
  key: React.Key
  slideDefect?: SlideDefectType | null
}

export const CaseName: FC<TCaseNameProps> = memo(({ caseName, countDone, countTotal, isArchive, key, slideDefect }) => {
  const { isLisMode } = useSettingsAndUserRoles()
  const colorTheme = useThemeContext()

  return (
    <CaseNameContainer key={key} data-testid={'management-table-row'}>
      {slideDefect && (
        <IconElement
          name={slideDefect === SlideDefectType.DEFECT ? 'defectSlideSmall' : 'falseDefectSlideSmall'}
          fill={slideDefect === SlideDefectType.DEFECT ? 'var(--color-red)' : 'var(--color-text-3)'}
          size="md"
          style={{
            flexShrink: 0,
          }}
        />
      )}
      <TextElement ellipsis>{caseName}</TextElement>

      {/* информация по кол-ву слайдов может не приходить если кейс еще в бд не имеет caseId */}
      {countDone !== undefined && (
        <CaseCountWrapper done={!isLisMode || countDone === countTotal} theme={colorTheme.theme}>
          <span data-testid="slide-reference-uploaded">{countDone}</span>
          {isLisMode && countTotal !== undefined ? (
            <span data-testid="slide-reference-total"> / {countTotal}</span>
          ) : (
            ''
          )}
        </CaseCountWrapper>
      )}
      {isArchive && (
        <IconElement
          name={'archiveSmall2'}
          style={{
            color: 'var(--color-text-3)',
            flexShrink: 0,
            height: '11px',
            marginLeft: '1px',
            width: '14px',
          }}
        />
      )}
    </CaseNameContainer>
  )
})

const CaseNameContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  width: 100%;
`

const CaseCountWrapper = styled.div<{ done: boolean; theme: TMode }>`
  padding: 2px 4px;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-bg-3);
  border-radius: 2px;
  background: ${(props) => (props.done ? 'var(--color-bg-3)' : 'var(--color-red)')};
  font-size: 10px;
  font-weight: ${(props) => (props.theme === 'dark' ? '600' : '500')};
  line-height: 12px;
  letter-spacing: 0;
  color: ${(props) =>
    props.done ? 'var(--color-text-1)' : props.theme === 'dark' ? 'var(--color-black)' : 'var(--color-white)'};
  white-space: nowrap;
`
