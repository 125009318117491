import 'ol/ol.css'

import { useTypedSelector } from 'app/redux/lib/selector'
import { annotationsSlice } from 'features/annotations/model/annotationsSlice'
import { viewerAttachmentsSlice } from 'features/attachments'
import { useUserStatusContext } from 'features/multiplayer/lib'
import { viewerPageSlice } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SideRightPanelType } from 'pages/viewer/model/viewerPageSlice'
import { RefObject, useEffect } from 'react'
import { MapProvider } from 'shared/lib/map'
import { getSlideMppx } from 'shared/lib/metadata'
import { IMapOl } from 'types/IMapOl'
import ISlide from 'types/ISlide'
import TViewerId from 'types/TViewerId'
import { useViewerAttachmentsSelector, useViewerDispatch, viewerSlice } from 'viewer/container'
import { ViewerToolsGridContainer } from 'viewer/tools'

import AnnotationsLayerContainer from './layers/annotations/AnnotationsLayerContainer'
import { ArtefactsProvider } from './layers/artefacts/ArtefactsContext'
import ArtefactsLayerContainer from './layers/artefacts/ArtefactsLayerContainer'
import AttachmentLayerContainer from './layers/attachments/AttachmentLayerContainer'
import { Ki67Provider } from './layers/ki67/Ki67Context'
import Ki67Layer from './layers/ki67/Ki67Layer'
import MapViewContainer from './layers/MapView'
import { MedicalNeuronetsProvider } from './layers/medicalNeuronets/MedicalNeuronetsContext'
import MedicalNeuronetsLayer from './layers/medicalNeuronets/MedicalNeuronetsLayer'
import ObjectsCountingLayer from './layers/objects-counting/ObjectsCountingLayer'
import { ObjectsCountingProvider } from './layers/objects-counting/ui/ObjectsCountingContext'
import { PathVisionSegmentationProvider } from './layers/path-vision-segmentation/PathVisionSegmentationContext'
import PathVisionSegmentationLayer from './layers/path-vision-segmentation/PathVisionSegmentationLayer'
import SearchLayerContainer from './layers/search/SearchLayerContainer'
import SegmentationLayersContainer from './layers/segmentation/SegmentationLayersContainer'
import SlideLayerProvider from './layers/slide/SlideLayerProvider'

type Props = {
  /** Id вьювера */
  viewerId: TViewerId
  /** Экземпляр olMap карты */
  map: IMapOl
  /** Данные слайда в текущем вьювере*/
  slide: ISlide
  /** Ссылка на вьювер как на HTMLDivElement */
  viewerGridRef: RefObject<HTMLDivElement>
}

const MapContainer = ({ map, slide, viewerGridRef, viewerId }: Props) => {
  const { caseId, slideId, source } = useViewerIdSlideState(viewerId)
  const mppx = getSlideMppx(slide)
  const { ARTEFACTS, KI67, MEDICALNEURONETS_CRC, MITOSIS, PV_PROSTATE, SEGMENTATION } = useTypedSelector(
    (state) => state.viewerPage.tools,
  )
  const { slideId: attachmentSlideId } = useViewerAttachmentsSelector(viewerId)
  const { targetUserId } = useUserStatusContext()
  const { activeViewerId, openViewerIds } = useOpenViewers()
  const rightPanel = useTypedSelector((state) => state.viewerPage.rightPanelType)
  const viewerDispatch = useViewerDispatch(viewerId)
  const isVisibleSearchMorphology = useTypedSelector((state) => state.viewerPage.tools.SEARCH_MORPHOLOGY.isVisible)

  useEffect(() => {
    if (slideId) {
      viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
      viewerDispatch(viewerSlice.actions.setBuffer(undefined))
      viewerDispatch(viewerAttachmentsSlice.actions.setAttachmentMode(false))
      if (slideId !== attachmentSlideId) {
        viewerDispatch(viewerAttachmentsSlice.actions.selectBbox(null))
        viewerDispatch(viewerAttachmentsSlice.actions.selectSlide(null))
      }
    }
  }, [slideId])

  useEffect(
    () => () => {
      viewerDispatch(viewerAttachmentsSlice.actions.setInitialState())
      viewerDispatch(annotationsSlice.actions.setInitialState())
      viewerDispatch(viewerPageSlice.actions.setTool({ tool: 'MEDICALNEURONETS_CRC', value: false }))
    },
    [],
  )

  return (
    <MapProvider viewerGridRef={viewerGridRef} viewerId={viewerId} map={map} mppX={mppx}>
      <Ki67Provider slideId={slideId}>
        <ObjectsCountingProvider
          isVisible={MITOSIS.isVisible}
          caseId={caseId}
          slideId={slideId}
          mppX={mppx}
          viewerId={viewerId}
        >
          <PathVisionSegmentationProvider
            isVisible={PV_PROSTATE.isVisible}
            caseId={caseId}
            slideId={slideId}
            viewerId={viewerId}
          >
            <ArtefactsProvider isVisible={ARTEFACTS.isVisible} viewerId={viewerId} caseId={caseId} slideId={slideId}>
              <MedicalNeuronetsProvider
                isVisible={MEDICALNEURONETS_CRC.isVisible}
                viewerId={viewerId}
                caseId={caseId}
                slideId={slideId}
              >
                <SlideLayerProvider viewerId={viewerId}>
                  <MapViewContainer />
                  {viewerId === activeViewerId && <ViewerToolsGridContainer viewerId={viewerId} mppX={mppx} />}
                </SlideLayerProvider>
                {SEGMENTATION.isVisible && <SegmentationLayersContainer />}
                {!MITOSIS.isVisible && <AnnotationsLayerContainer viewerId={activeViewerId} mppX={mppx} />}
                {rightPanel === SideRightPanelType.COMMENTS && !targetUserId && (
                  <AttachmentLayerContainer displayedSlideId={slideId} />
                )}
                {isVisibleSearchMorphology && (
                  <SearchLayerContainer slideId={slideId} caseId={caseId} iiif2Url={slide?.iiif2Url} source={source} />
                )}
                {KI67.isVisible && <Ki67Layer mppX={mppx} slideId={slideId} />}
                {MITOSIS.isVisible && <ObjectsCountingLayer mppX={mppx} slide={slide} />}
                {PV_PROSTATE.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
                  <PathVisionSegmentationLayer viewerId={activeViewerId} />
                )}
                {ARTEFACTS.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
                  <ArtefactsLayerContainer viewerId={viewerId} />
                )}
                {MEDICALNEURONETS_CRC.isVisible && viewerId === activeViewerId && openViewerIds.length === 1 && (
                  <MedicalNeuronetsLayer viewerId={viewerId} slideId={slideId} />
                )}
              </MedicalNeuronetsProvider>
            </ArtefactsProvider>
          </PathVisionSegmentationProvider>
        </ObjectsCountingProvider>
      </Ki67Provider>
    </MapProvider>
  )
}

export default MapContainer
