import * as Sentry from '@sentry/react'
import { Severity } from '@sentry/react'
import Feature from 'ol/Feature'
import { Polygon } from 'ol/geom'
import { TDefectTypeDictionaryItem } from 'types/IDictionary'

/** Регулярное выражение для проверки HEX-кода цвета */
const HEX_COLOR_REGEX = /^#[0-9a-fA-F]{6}$/

/** Цвет по умолчанию, используемый при некорректном значении цвета дефекта */
const FALLBACK_COLOR = '#CCCCCC'

/**
 * Фильтрует и валидирует типы дефектов из словаря на основе списка полигональных дефектов OpenLayers
 *
 * @param defectTypesDict - Массив элементов словаря типов дефектов
 * @param features - Массив полигональных объектов OpenLayers, представляющих дефекты.
 *                  Каждый объект должен иметь свойство 'class', соответствующее mlClassName из словаря
 * @returns Объект с валидными типами дефектов, где ключом является mlClassName
 *
 * @remarks
 * Функция выполняет следующие действия:
 * 1. Извлекает классы дефектов из свойства 'class' каждого полигона OpenLayers
 * 2. Фильтрует дефекты из словаря, оставляя только те, чьи mlClassName присутствуют в полигонах
 * 3. Пропускает дефекты с пустым mlClassName
 * 4. Проверяет корректность формата цвета (HEX)
 * 5. При некорректном цвете отправляет ошибку в Sentry и использует цвет по умолчанию
 *
 * @throws {Error} Не выбрасывает ошибок, но отправляет сообщения об ошибках в Sentry
 *                 при обнаружении некорректного цвета дефекта
 *
 * @example
 * ```typescript
 * const defects = [
 *   { id: 1, name: 'Scratch', mlClassName: 'scratch', mlClassColor: '#FF0000' },
 *   { id: 2, name: 'Dent', mlClassName: 'dent', mlClassColor: '#00FF00' }
 * ]
 *
 * const validDefects = getValidDefectTypes(defects, [polygon])
 * // validDefects будет содержать только дефект 'scratch'
 * ```
 *
 */
export const getValidDefectTypes = (defectTypesDict: TDefectTypeDictionaryItem[], features: Feature<Polygon>[]) => {
  // Создаем Set из активных классов для быстрого поиска
  const activeClasses = new Set(features.map((defect) => defect.get('class') as string))

  return defectTypesDict.reduce((acc, defect) => {
    // Пропускаем дефекты с пустым mlClassName или если их нет в списке активных
    if (!defect.mlClassName?.length || !activeClasses.has(defect.mlClassName)) {
      return acc
    }

    // Проверяем валидность цвета
    if (!defect.mlClassColor || !HEX_COLOR_REGEX.test(defect.mlClassColor)) {
      Sentry.withScope((scope) => {
        scope.setExtra('defectType', defect)
        scope.setTag('feature', 'defectColor')
        scope.setLevel(Severity.Error)
        Sentry.captureMessage(
          `Некорректный цвет дефекта ${defect.name} (ID: ${defect.id}, mlClassName: ${defect.mlClassName}): ${
            defect.mlClassColor ?? 'NULL'
          }`,
        )
      })
      acc[defect.mlClassName] = {
        ...defect,
        mlClassColor: FALLBACK_COLOR,
      }
    } else {
      acc[defect.mlClassName] = defect
    }
    return acc
  }, {} as Record<string, TDefectTypeDictionaryItem>)
}
