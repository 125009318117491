import { APP_VERSION_KEY, TABS_QUERY_PARAMS_KEY } from 'shared/lib/common/storageKeys'

type MigrationFn = () => void

type Migrations = {
  [version: string]: MigrationFn
}

/** Текущая версия приложения */
const APP_VERSION = '1'

/** Определение миграций */
const migrations: Migrations = {
  '1': () => {
    localStorage.removeItem(TABS_QUERY_PARAMS_KEY)
  },
}

/** Выполняет миграции для localStorage */
export const runLocalStorageMigrations = (): void => {
  const savedVersion = localStorage.getItem(APP_VERSION_KEY) || '0'

  // Выполняем только те миграции, которые относятся к версиям выше сохраненной
  Object.keys(migrations)
    .filter((version) => version > savedVersion)
    .sort() // для последовательной миграции
    .forEach((version) => {
      migrations[version]()
    })

  // Обновляем версию после миграций
  localStorage.setItem(APP_VERSION_KEY, APP_VERSION)
}
