import { useTypedSelector } from 'app/redux/lib/selector'
import { useCaseLinkQuery } from 'features/case-info/api/query'
import { useCaseQuery, useChangeCaseStatusMutation, useDeleteCaseMutation } from 'features/cases'
import {
  useAddCasesToFavoritesMutation,
  useDeleteCasesFromFavoritesMutation,
} from 'features/cases-management/api/query'
import { EAllCasesTabType, EFavoriteList } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { CaseData } from 'features/cases-management/ui/cases-table/CasesTable'
import { selectedReportByCaseSelector } from 'features/reports'
import { useReportQuery, useReportsDataQuery } from 'features/reports/api/query'
import { ShareModal } from 'features/share/ui/ShareModal'
import { useSlideGrouped } from 'features/thumbnails/hooks'
import SlideUploadButtonContainer from 'features/thumbnails/SlideUploadButtonContainer'
import { ContextThumbnail } from 'features/thumbnails/ui/ContextThumbnail'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { debounce } from 'lodash'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { viewerPageSlice } from 'pages/viewer'
import { useOpenViewers } from 'pages/viewer/lib/common/ViewerPageProvider'
import { SlideInfo } from 'pages/viewer/model/viewerPageSlice'
import { TusContext } from 'processes/tus'
import { Resizable } from 're-resizable'
import React, { Dispatch, SetStateAction, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useDispatch } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { formatDateForDisplay } from 'shared/lib/date'
import { addToLocalStorage, getFromLocalStorage } from 'shared/lib/local-storage'
import { getBooleanOrDefaultAccess, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { ModalHandle } from 'shared/ui/modal'
import styled from 'styled-components/macro'
import ICase, { ECaseStageQuery, ICaseRelation } from 'types/ICase'
import { ActiveTabEnum } from 'types/ICaseInfoPanel'
import IUserRole from 'types/IUserRole'
import { SettingsNameEnum } from 'types/IWorkspaces'
import { useViewerDispatch } from 'viewer/container'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'

import {
  handleAddImageClick,
  handleAddReportClick,
  handleDeclineClick,
  handleEditClick,
  handleRemoveClick,
  handleStatusModalClick,
  handleToArchiveClick,
  handleUrgentlyClick,
  handleWatchingListChange,
  IButtonData,
} from './common'
import { DescriptionTabContent, ImagesTabContent, RelatedCasesTabContent, ReportTabContent } from './content-tabs'
import HeaderPreview from './Header/HeaderPreview'
import TabsPanelPreview from './Header/TabsPanelPreview'

type IConfigPreview = {
  /** Активная вкладка в панели */
  activeTab: ActiveTabEnum
  /** Высота панели */
  height: number
}

/** Информация о этикетке для разных слайдов. */
type LabelInfo = {
  /** Идентификатор слайда, соответствующий информации о слайде. */
  [key: number]: SlideInfo
}

/** Максимально допустимое число слайдов в кейсе */
const MAX_SLIDES_LENGTH = 20
const UPDATE_DELAY = 1

export const CaseInfoPanel: React.FC<{
  /** Идентификатор случая. */
  caseId: ICase['caseId']
  /** Функция для установки текущего случая. */
  setCurrentCase: Dispatch<SetStateAction<CaseData>>
  /** Функция, вызываемая при закрытии. */
  onClose: () => void
  /** Ссылка на модальное окно формы случая. */
  caseFormModal: React.MutableRefObject<ModalHandle | null>
  /** Ссылка на модальное окно отказа. */
  declineModal: React.MutableRefObject<ModalHandle | null>
  /** Ссылка на модальное окно истории статусов. */
  statusHistoryModal: React.MutableRefObject<ModalHandle | null>
  /** Ссылка на модальное заключения. */
  reportCreationModalRef: React.MutableRefObject<ModalHandle | null>
  /** Функция для закрытия контекстного меню. */
  closeContextMenu: () => void
  /**  Функция для повторного получения данных. */
  refetch: () => void
}> = ({
  caseFormModal,
  caseId,
  closeContextMenu,
  declineModal,
  onClose,
  refetch,
  reportCreationModalRef,
  setCurrentCase,
  statusHistoryModal,
}) => {
  const dispatch = useDispatch()
  const queryClient = useQueryClient()
  const lisMode = useLisMode()
  const { t } = useTranslation()
  const { openPanel } = useContext(TusContext)
  const workspaceId = useCurrentWorkspaceId()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const caseRecord = queryClient.getQueryData<ICase>([QUERY_TYPE.CASE, caseId])
  const { data: caseReports } = useReportsDataQuery(caseId, 'PLATFORM')
  const selectedReportIndex = useTypedSelector(selectedReportByCaseSelector(caseId))
  const selectedReportId =
    caseReports?.[selectedReportIndex]?.medicalReportId || Number(caseReports?.[0]?.medicalReportId)
  const { panelPreviewHeight, setPanelPreviewHeight } = useCaseManagementContext()
  // TODO Отрефакторить, убрать текущий стейт и перенести в общий контекст
  const defaultConfig: IConfigPreview = { activeTab: ActiveTabEnum.IMAGES, height: panelPreviewHeight }
  const configStorage = getFromLocalStorage('configPreviewPanel') as IConfigPreview
  const [configPreviewPanel, setConfigPreviewPanel] = useState<IConfigPreview>(() => configStorage || defaultConfig)
  const { activeTab, height } = configPreviewPanel
  const { similarThumbnailHover, slideThumbnailHover, thumbnailMenuPosition } = useTypedSelector(
    (state) => state.viewerPage,
  )
  const { mutate: changeStatus } = useChangeCaseStatusMutation(caseId)
  const { mutate: deleteCase } = useDeleteCaseMutation(caseId, refetch)
  const { mutate: removeFromWatching } = useDeleteCasesFromFavoritesMutation()
  const { mutate: addToWatchingList } = useAddCasesToFavoritesMutation()
  const { data: selectedReport = caseReports?.[0] } = useReportQuery(caseId, selectedReportId, 'PLATFORM')
  const menuThumbnailRef = useRef(null)
  const onCloseMenu = useCallback(() => {
    dispatch(viewerPageSlice.actions.setThumbnailMenuPosition([]))
    dispatch(viewerPageSlice.actions.setSimilarThumbnailHover(undefined))
    dispatch(viewerPageSlice.actions.setSlideThumbnailHover(undefined))
  }, [])

  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(onCloseMenu, [activeTab])
  useOnClickOutside(menuThumbnailRef, onCloseMenu, undefined, thumbnailMenuPosition)

  const handleTabChange = (key: string) =>
    setConfigPreviewPanel((prevState) => ({ ...prevState, activeTab: key as ActiveTabEnum }))
  const handleResize = (height: number) => {
    setConfigPreviewPanel((prevState) => ({ ...prevState, height }))
  }
  const { currentTab: tabType } = useCaseManagementRouteParam()

  useEffect(() => {
    if (openPanel && activeTab !== IMAGES) {
      handleTabChange(IMAGES)
    }
  }, [activeTab, openPanel])

  const { activeViewerId: viewerId } = useOpenViewers()
  const viewerDispatch = useViewerDispatch(viewerId)
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { data: caseResult } = useCaseQuery({ caseId: caseId, source: 'PLATFORM' })
  const { slides } = useSlideGrouped(caseId)
  const authorities = useTypedSelector((state) => state.user.authorities)
  const {
    roles: { isRoleCaseAssignment, isRoleDoctor, isRoleTestUser, isRoleUser },
  } = useSettingsAndUserRoles()
  const isCompleted = caseRecord?.stage === ECaseStageQuery.COMPLETED
  const isCurrentWorkspace = workspaceId === caseRecord?.workspace?.workspaceId
  const isAssigned = caseRecord?.stage === ECaseStageQuery.ASSIGNED
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const isCaseOwner = caseRecord?.assignedDoctor?.userId === currentUserId
  const archiveEnable = isCurrentWorkspace && authorities?.includes(IUserRole.ROLE_ARCHIVE_MANAGER)
  const reportCreationEnable =
    (lisMode === 'none' && !selectedReport?.signed) || (lisMode === 'gemotest' && !caseReports?.length)
  const changeUrgentEnable = isCaseRouting && !isCompleted && isRoleCaseAssignment
  const historyEnable = isCaseRouting && (isRoleCaseAssignment || isRoleUser) && isCurrentWorkspace
  const declineEnable = !(
    isCurrentWorkspace &&
    isRoleUser &&
    !isCompleted &&
    isCaseOwner &&
    isAssigned &&
    isCaseRouting &&
    !getBooleanOrDefaultAccess(SettingsNameEnum.CaseStageManagement, true, currentWorkspace)
  )
  const deleteEnable =
    isRoleUser &&
    isCurrentWorkspace &&
    lisMode === 'none' &&
    getBooleanOrDefaultAccess(SettingsNameEnum.CanRemoveCase, true, currentWorkspace) &&
    tabType !== EAllCasesTabType.DELETED
  const isOwner = caseRecord?.relation === ICaseRelation.OWNER
  const editEnable = caseRecord?.relation === ICaseRelation.OWNER && lisMode === 'none'
  const addToFavoritesEnable =
    isCaseRouting &&
    isRoleUser &&
    !caseRecord?.favoriteLists.includes(EFavoriteList.WATCHING) &&
    tabType !== EAllCasesTabType.DELETED
  const removeFromFavoritesEnable =
    isCaseRouting && isRoleUser && caseRecord?.favoriteLists.includes(EFavoriteList.WATCHING)
  const caseName = caseResult?.name

  const handleHistoryClick = () => {
    if (caseId && workspaceId) {
      setCurrentCase({ caseId: caseId, name: caseName, wsId: workspaceId })
    }
    handleStatusModalClick(statusHistoryModal)
  }

  const buttonsData: IButtonData[] = [
    {
      hidden: !deleteEnable,
      iconName: 'deleteRed',
      onClick: () => handleRemoveClick(deleteCase, refetch),
      title: t('Удалить'),
    },
    {
      hidden: !archiveEnable,
      iconName: 'archive16',
      onClick: () => handleToArchiveClick(caseResult?.status === 'OPEN' ? 'ARCHIVE' : 'OPEN', changeStatus, refetch),
      title: caseResult?.status === 'OPEN' ? t('В архив') : t('Вернуть из архива'),
    },
    {
      hidden: !changeUrgentEnable,
      iconName: caseResult?.urgent ? 'notAttention' : 'attention',
      onClick: () => handleUrgentlyClick(workspaceId, refetch, caseId, !caseResult?.urgent),
      title: caseResult?.urgent ? t('Несрочно') : t('Срочно'),
    },
    {
      hidden: declineEnable,
      iconName: 'reject',
      onClick: () => handleDeclineClick(declineModal),
      title: t('Отказаться'),
    },
    {
      hidden: !isRoleTestUser && (!reportCreationEnable || !isRoleDoctor),
      iconName: 'reportSmall',
      onClick: () => handleAddReportClick(reportCreationModalRef),
      title: t('Добавить заключение'),
    },
    {
      hidden: !historyEnable,
      iconName: 'clock',
      onClick: () => handleHistoryClick(),
      title: t('История'),
    },
    {
      iconName: 'share16',
      onClick: () => setIsModalOpen(true),
      title: t('Поделиться'),
    },
    {
      customButton: (el) => (
        <SlideUploadButtonContainer
          disabled={Number(slides?.length) >= MAX_SLIDES_LENGTH}
          caseId={caseId}
          key="addButton"
          buttonType={'default'}
        >
          {el}
        </SlideUploadButtonContainer>
      ),
      hidden: !isOwner,
      iconName: 'upload',
      onClick: () => handleAddImageClick,
      title: t('Загрузить изображение'),
    },
    {
      hidden: !editEnable,
      iconName: 'edit16',
      onClick: () => {
        closeContextMenu()
        handleEditClick(dispatch, caseFormModal)
      },
      title: t('Редактировать'),
    },
    {
      hidden: !addToFavoritesEnable,
      iconName: 'star16',
      onClick: () => handleWatchingListChange(addToWatchingList, caseId),
      title: t('Добавить в отслеживаемое'),
    },
    {
      hidden: !removeFromFavoritesEnable,
      iconName: 'notStar',
      onClick: () => handleWatchingListChange(removeFromWatching, caseId),
      title: t('Перестать отслеживать'),
    },
  ]

  const visibleButtons = buttonsData.filter((button) => !button.hidden)

  useEffect(() => {
    if (!slides) return
    const labelInfo: LabelInfo = {}

    slides.forEach((slide) => {
      const { labelUrl, proxiedLabelUrl, slideId, slideMetadata } = slide
      labelInfo[slideId] = {
        labelUrl: labelUrl,
        proxiedLabelUrl: proxiedLabelUrl,
        rotate: slideMetadata?.commonMetadata?.labelRotation || 0,
        slideId: slideId,
      }
    })
    viewerDispatch(viewerPageSlice.actions.setNewSlides(labelInfo))
  }, [slides?.length])

  useEffect(() => {
    addToLocalStorage('configPreviewPanel', configPreviewPanel)
  }, [configPreviewPanel])

  useEffect(() => {
    setPanelPreviewHeight(height)
  }, [height])

  useEffect(() => {
    onCloseMenu()
  }, [window.innerWidth, window.innerHeight])

  const { DESCRIPTION, IMAGES, RELATED, REPORT } = ActiveTabEnum

  /** Дебаунс обработчкика изменения высоты панели превью */
  const debouncedResize = debounce(
    (event, direction, elementRef) => handleResize(elementRef.clientHeight),
    UPDATE_DELAY,
  )
  const { data: selectedRelated, isFetching: isSelectedRelatedFetching } = useCaseLinkQuery({ caseId })
  /** Убираем выбранный кейс */
  const onlyRelatedCases = selectedRelated?.filter((currentCase) => currentCase.caseId !== caseId)

  return (
    <>
      {!!thumbnailMenuPosition.length && (
        <ContextThumbnail
          onCloseMenu={onCloseMenu}
          position={'fixed'}
          addToSplitScreenDisable
          thumbnailMenuPosition={thumbnailMenuPosition}
          menuRef={menuThumbnailRef}
          slideThumbnailHover={slideThumbnailHover}
          similarThumbnailHover={similarThumbnailHover}
          slides={slides}
          caseId={caseId}
        />
      )}
      <PanelContainer
        onResize={debouncedResize}
        maxHeight={600}
        enable={{ top: true }}
        handleStyles={{ top: { left: '49.5%', top: 7, width: '4vh' } }}
        maxWidth={'100%'}
        defaultSize={{
          height,
          width: '100%',
        }}
      >
        <HeaderPreview buttonsData={visibleButtons} reports={caseReports} onClose={onClose}>
          <TabsPanelPreview
            caseId={caseId}
            heightPanel={height}
            defaultActiveKey={activeTab}
            activeTab={activeTab}
            destroyInactiveTabPane={true}
            onChange={handleTabChange}
            isReport={!!selectedReport}
            isRelated={!!onlyRelatedCases?.length}
            selectedReportId={selectedReport?.medicalReportId}
            createReportDate={
              selectedReport && caseReports?.length !== 1 ? formatDateForDisplay(selectedReport?.createdAt) : ''
            }
          >
            {activeTab === IMAGES && (
              <ImagesTabContent caseId={caseId} declinementComment={caseRecord?.declinementComment} />
            )}
            {activeTab === DESCRIPTION && <DescriptionTabContent caseId={caseId} caseRecord={caseRecord} />}
            {activeTab === RELATED && (
              <RelatedCasesTabContent
                isFetching={isSelectedRelatedFetching}
                selectedRelated={onlyRelatedCases}
                declinementComment={caseRecord?.declinementComment}
              />
            )}
            {activeTab === REPORT && (
              <ReportTabContent
                report={selectedReport}
                caseRecord={caseRecord}
                reportCreationModalRef={reportCreationModalRef}
              />
            )}
          </TabsPanelPreview>
        </HeaderPreview>
      </PanelContainer>
      <ShareModal open={isModalOpen} caseId={caseId} setIsOpen={setIsModalOpen} />
    </>
  )
}

const PanelContainer = styled(Resizable)`
  position: absolute;
  bottom: 0 !important;
  overflow: hidden;
`
