import 'shared/lib/i18n/i18n'

import { useTypedSelector } from 'app/redux/lib/selector'
import { SentryRoute, setUserToSentry } from 'app/sentry'
import { syncLogoutAcrossTabs } from 'entities/auth/model/syncAuth'
import { checkInvites } from 'entities/permissions'
import { initDictionaries } from 'features/dictionaries'
import { initNotices } from 'features/notices'
import { useCurrentWorkspaceId, useResetFromCachedUrlWorkspace } from 'features/workspace/lib'
import { CaseExternalRoute } from 'pages/caseExternal'
import CasesRoutes from 'pages/cases/ui/CasesRoutes'
import CasesManagementRoutes from 'pages/cases-management/CasesManagementRoutes'
import { ConfirmRoute } from 'pages/confirm'
import { DashboardRoute } from 'pages/dashboard'
import { DeepLinkRoute } from 'pages/deep-link'
import { InviteRoute } from 'pages/invite'
import { LoginRoute } from 'pages/login'
import { NewPasswordRoute } from 'pages/new-password'
import { OhivViewerRoute } from 'pages/ohiv'
import { PlaceholderRoute } from 'pages/placeholder'
import { isBrowserAccess } from 'pages/placeholder/lib/helper'
import { RegistrationRoute } from 'pages/registration'
import { ResetRoute } from 'pages/reset'
import { selectDeepLink, viewerPageSlice } from 'pages/viewer/model/viewerPageSlice'
import { AtlasViewerRoute } from 'pages/viewer/ui/atlas-viewer/AtlasViewerRoute'
import { ViewerRoute } from 'pages/viewer/ui/default-viewer/ViewerRoute'
import { DefectsViewerRote } from 'pages/viewer/ui/defects-viewer/DefectsViewerRote'
import { TasksViewerRoute } from 'pages/viewer/ui/tasks-viewer/TasksViewerRoute'
import { clearExpiredUploads } from 'processes/tus'
import React, { useEffect, useState } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { initApp } from 'shared/config/init'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import IUserRole from 'types/IUserRole'

const App = () => {
  const token = useTypedSelector((state) => state.user.token)
  const authorities = useTypedSelector((state) => state.user.authorities) //TODO: need better work with Roles
  const user = useTypedSelector((state) => state.user.user)
  const currentLocation = useLocation()
  const workspaceId = useCurrentWorkspaceId()
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const checkAndResetCachedUrlWorkspace = useResetFromCachedUrlWorkspace()
  const { isDefectsVisible } = useSettingsAndUserRoles()
  const isAtlasAvailable = authorities?.includes(IUserRole.ROLE_ATLAS_VALIDATION)
  const isTaskAvailable = authorities?.includes(IUserRole.ROLE_MARKUP_USER)
  const isWorkspaceId = !!workspaceId
  const [isAccessBrowser, setIsAccessBrowser] = useState<boolean>(isBrowserAccess)
  const isDeepLink = useSelector(selectDeepLink)
  useEffect(() => {
    initApp()
    const unsubscribe = syncLogoutAcrossTabs(dispatch)
    clearExpiredUploads(user?.userId)
    initNotices()
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    if (token) {
      checkInvites(dispatch, t)
      setUserToSentry(user)
      try {
        /** Если в URL есть linkParams, не запрашиваем словари */
        if (workspaceId && !isDeepLink) initDictionaries(String(workspaceId))
      } catch (e) {
        console.log(e)
      }
    }
  }, [token, workspaceId, isAccessBrowser])

  /** Needed to solve problems with redux-persistor
   * problem:
   *  - close viewer page
   *  - redux state saved in ls (onUnmount wasn't called in viewer page)
   *  - open cases
   *  - then navigate to viewer via thumbnail
   *
   *  Conflict because there are slideId and caseId from previous session in state
   */
  useEffect(() => {
    if (
      !currentLocation.pathname.includes('/viewer') &&
      !currentLocation.pathname.includes('/atlas-viewer') &&
      !currentLocation.pathname.includes('/panel-preview') &&
      !currentLocation.pathname.includes('/tasks-viewer')
    ) {
      dispatch(viewerPageSlice.actions.resetAllViewersState())
    }
    if (token) checkAndResetCachedUrlWorkspace()
  }, [])

  useEffect(() => {
    const getWidget = setInterval(() => {
      const widget = document.getElementById('__replain_widget_iframe') as HTMLIFrameElement
      if (widget && widget.contentWindow) {
        const chatBubble = widget.contentWindow.document.getElementsByClassName('close')[0] as HTMLElement
        if (chatBubble) {
          chatBubble.style.left = '254px'
          clearInterval(getWidget)
        }
      }
    }, 20)
    const getMainBlock = setInterval(() => {
      const mainBlock = document.getElementById('__replain_widget') as HTMLElement
      if (!mainBlock) return
      mainBlock.style.display = `none !important`
    }, 5000)
    return () => clearInterval(getMainBlock)
  }, [])

  const [mockObj, setMockObj] = useState({ a: '' })
  const mockArray = [mockObj, mockObj, mockObj]
  const mockError = () => {
    // @ts-ignore
    setMockObj(undefined)
  }
  useHotkeys('a+d+s', () => {
    mockError()
  })

  if (!token) {
    return (
      <Switch>
        <SentryRoute exact path="/login">
          <LoginRoute />
        </SentryRoute>
        <SentryRoute exact path="/registration">
          <RegistrationRoute />
        </SentryRoute>
        <SentryRoute exact path="/request-reset">
          <ResetRoute />
        </SentryRoute>
        <SentryRoute exact path="/confirm/:userId/:token">
          <ConfirmRoute />
        </SentryRoute>
        <SentryRoute exact path="/reset/:userId/:token">
          <NewPasswordRoute />
        </SentryRoute>
        <SentryRoute exact path="/invite/:token">
          <InviteRoute />
        </SentryRoute>
        <SentryRoute exact path="/deep-link/:linkParams/:token">
          <DeepLinkRoute />
        </SentryRoute>
        <SentryRoute exact path="/workspace/:workspaceId/viewer/external/:caseExternalId">
          <CaseExternalRoute />
        </SentryRoute>
        <Redirect
          to={{
            pathname: '/login',
            state: { referrer: currentLocation },
          }}
        />
      </Switch>
    )
  }

  if (!isAccessBrowser) {
    return (
      <Switch>
        <SentryRoute>
          <PlaceholderRoute setIsAccessBrowser={setIsAccessBrowser} />
        </SentryRoute>
      </Switch>
    )
  }

  return (
    <Switch>
      {mockArray.map((item) => item.a)}
      <SentryRoute exact path="/workspace/:workspaceId/viewer/external/:caseExternalId">
        <CaseExternalRoute />
      </SentryRoute>
      <SentryRoute path="/workspace/:workspaceId/viewer/:caseId">
        <ViewerRoute />
      </SentryRoute>
      {isDefectsVisible && (
        <SentryRoute path="/defects-viewer/:caseId/:tab">
          <DefectsViewerRote />
        </SentryRoute>
      )}
      {isAtlasAvailable && (
        <SentryRoute path="/atlas-viewer/:slideId">
          <AtlasViewerRoute />
        </SentryRoute>
      )}
      {isTaskAvailable && (
        <SentryRoute path="/tasks-viewer/:taskId">
          <TasksViewerRoute />
        </SentryRoute>
      )}
      <SentryRoute path="/ohiv">
        <OhivViewerRoute />
      </SentryRoute>
      {isWorkspaceId && (
        <SentryRoute path="/dashboard">
          <DashboardRoute />
        </SentryRoute>
      )}
      <SentryRoute exact path="/invite/:token">
        <InviteRoute />
      </SentryRoute>
      <SentryRoute exact path="/deep-link/:linkParams/:token">
        <DeepLinkRoute />
      </SentryRoute>
      <CasesManagementRoutes />
      <CasesRoutes />
    </Switch>
  )
}
export default App
