import 'antd/es/date-picker/style/index'

import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { CasesTableFilter } from 'features/cases-management/ui/filters/CasesTableFIlter'
import React, { RefObject } from 'react'
import i18next from 'shared/lib/i18n/i18n'
import TableLayout from 'shared/ui/table/ui/TableLayout'

import { useCaseManagementRouteParam } from './CasesManagementRoutes'
import CasesTabsContainer from './tabs/CasesTabsContainer'

export const t = i18next.t

type Props = {
  /** Ссылка на кнопку уведомления. */
  notificationButton?: RefObject<HTMLDivElement | null>
}
export const CasesManagementRoute = ({ notificationButton }: Props) => {
  const { menuTab } = useCaseManagementRouteParam()
  return (
    <TableLayout notificationButton={notificationButton}>
      {menuTab === ECaseTableType.DEFECTS && <CasesTableFilter />}
      <CasesTabsContainer />
    </TableLayout>
  )
}
