export type DictionaryId =
  | 'ICD_10'
  | 'MORPHOLOGY_CODE'
  | 'SERVICE'
  | 'MATERIAL_TYPE'
  | 'DIAGNOSTIC_PROCEDURE_TYPE'
  | 'CASE_RESEARCH_TYPE'
  | 'SITE'
  | 'PROCESS_NATURE'
  | 'STAIN'
  | 'MODALITY'
  | 'DEFECT_TYPE'

export type DictionaryVersion = {
  /** название словарей */
  identifier: DictionaryId
  /** версия словарей */
  version: string
  /** дата/время обновления */
  updatedAt: string
  /** идентификатор воркспейса */
  workspaceId: string
  /** идентификатор словарей */
  hash: string
}

export type DictionaryItem = {
  /** короткое имя МКБ-О */
  shortName: string
  id: number
  name: string
  visible?: boolean
  groupName?: string
  mlClassName?: string
  mlClassColor?: string
  mlThreshold?: number
}

/** Тип словарей для дефекта */
export type TDefectTypeDictionaryItem = Omit<DictionaryItem, 'visible' | 'groupName'> & {
  code: string
  /** Название класса машинного обучения, используемого для определения дефекта */
  mlClassName?: string
  /** Цвет, используемый для подсветки этого типа дефекта при визуализации результатов ML */
  mlClassColor?: string
  /** Пороговое значение уверенности для ML-определения этого типа дефекта (0-1) */
  mlThreshold?: number
}

/**  Тип данных для причины запроса в ЗнД */
export type IResearchRequestReasonDictionaryItem = {
  id: number
  code: string
}

/** Code для объектов из словаря причин ЗнД */
export enum ResearchRequestReasonCode {
  DIAG = 'DIAG',
  DEFECT = 'DEFECT',
}

/** Структура морфологии, которая приходит по вебсокету при поиске похожих */
export type Morphology = {
  /** короткое имя МКБ-О */
  shortName: string
  /** Название/описание морфологии */
  description: string
  /** Id морфологии */
  morphologyCodeId: number
}

export type ICD10Detailed = {
  /** Код по МКБ-10 */
  icd10: DictionaryItem
  /** Топографический код по МКБ-О */
  topographicalIcdO: DictionaryItem
  /** Морфологический код МКБ-О */
  morphologicalIcdO: DictionaryItem
  /** Характер процесса */
  processNature: DictionaryItem
  /** Классификация TNM */
  tnmClassification: string
}
