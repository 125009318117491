import { DictionaryItem } from './IDictionary'
import { ISearchModels, ISlideMetadata } from './ISlideMetadata'
import ISource from './ISource'
import IThumbnails from './IThumbnails'

export enum SlideState {
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED',
  AVAILABLE = 'AVAILABLE',
}

/** Перечисление типов дефектов слайда */
export enum SlideDefectType {
  /** Реальный дефект слайда. */
  DEFECT = 'DEFECT',
  /** Ложноположительный дефект. */
  FALSE_POSITIVE = 'FALSE_POSITIVE',
}

type ISlideState = 'PROCESSING' | 'FAILED' | 'AVAILABLE'
export type IGroupType = 'MACRO' | 'MICRO'

export type ISlideDTO = {
  createdAt?: string
  block?: string | null
  barcode?: string | null
  container?: string | null
  deleted: boolean
  deletedAt: null | string // 2020-09-07T09:43:32.486051
  groupType: IGroupType
  labelUrl?: string
  proxiedLabelUrl?: string
  /** url на фото стекла */
  previewUrl?: string
  /** прокисрованный url на фото стекла */
  proxiedPreviewUrl?: string
  iiif2Url: string
  iiif2AuthToken?: string
  slideId: number
  stain?: DictionaryItem
  state: ISlideState
  slideMetadata?: ISlideMetadata
  thumbnails: IThumbnails
  searchModels?: ISearchModels[]
  shardId?: string
  source?: ISource
  /** Флаг, указывающий, был ли слайд просмотрен. */
  viewed: boolean | null
  /** Флаг, указывающий на наличие дефекта у слайда. */
  slideDefect: SlideDefectType | null
}

export type ISlideDownloadItem = {
  downloadURL: string
  found: boolean
  slideId: number
}

export type IMacroSlides = {
  /** штрихкод слайда */
  barcode?: string | null
  /** номер блока слайда */
  block?: string | null
  /** номер контейнера слайда */
  container?: string | null
  /** название слайда */
  name?: string
  /** идентификатор слайда  */
  slideId: number
}

type ISlide = ISlideDTO & {
  caseId: number
  mock?: boolean
}

export default ISlide
