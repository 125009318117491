import { ESortDir, TOmitCaseManagementQueryParams } from 'features/cases-management/types/TCasePagination'
import {
  EDefectsSortBy,
  SlideWithDefect,
  TDefectsFilterParams,
  TDefectsQueryParams,
} from 'features/defects/types/TDefectsPagination'
import { get, post } from 'shared/api'
import { IListOfItemsCursorCases } from 'types/api/IListOfItemsCursor'
import { TCasesManagementTabs, TTabCount } from 'types/TTab'

interface ICursorResponse {
  previousPageCursor: string
}

const fetchDefectsSlides = async (
  wsId: number,
  tab: TCasesManagementTabs,
  size: number,
  queryParams?: TDefectsQueryParams | TOmitCaseManagementQueryParams,
  cursor?: string,
  filterParams?: TDefectsFilterParams,
) => {
  const params = {
    ...queryParams,
    cursor,
    size,
    sortBy: queryParams?.sortBy || EDefectsSortBy.DEFECT_DATE,
    sortDir: queryParams?.sortDir || ESortDir.ASC,
    tab,
    ...filterParams,
  }

  return get<IListOfItemsCursorCases<SlideWithDefect>>({
    config: {
      params,
    },
    url: `/workspace/${wsId}/defects/slides`,
  })
}
const fetchDefectsCounts = async (wsId: number) =>
  get<TTabCount<TCasesManagementTabs>>({
    url: `workspace/${wsId}/defects/slides/counts`,
  })

const getNextPrevDefectsCursor = async (
  workspaceId: number,
  slideId: number,
  sortBy?: 'DEFECT_DATE' | 'CASE_ID',
): Promise<ICursorResponse> =>
  get<ICursorResponse>({
    config: {
      params: {
        slideId,
        sortBy,
      },
    },
    url: `/workspace/${workspaceId}/defects/cursor`,
  })

const markSlidesAsViewed = async (workspaceId: number, slideIds: number[]): Promise<void> =>
  post<void>({
    data: {
      slideIds,
    },
    url: `/workspace/${workspaceId}/defects/slides/viewed`,
  })

const defectsService = {
  fetchDefectsCounts,
  fetchDefectsSlides,
  getNextPrevDefectsCursor,
  markSlidesAsViewed,
}

export default defectsService
