import 'antd/es/date-picker/style/index'

import { Divider, Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox/Checkbox'
import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { useCaseManagementDoctorsQuery } from 'features/cases-management/api/query'
import { EAllCasesTabType, ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { TCaseManagementFilterParams } from 'features/cases-management/types/TCasePagination'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { caseStagesFilters } from 'features/cases-management/ui/filters/filters'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { SEARCH_BUFFER } from 'pages/sump/lib/constants'
import { viewerPageSlice } from 'pages/viewer'
import { selectDefectsViewerUrlCaseId } from 'pages/viewer/model/viewerPageSlice'
import React, { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { DEFECTS_TABLE_FILTERS_KEY } from 'shared/lib/common/storageKeys'
import { useDebounce } from 'shared/lib/hooks'
import useFilteredDoctors from 'shared/lib/hooks/useFilteredDoctors'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { CheckboxElement, SearchElement } from 'shared/ui/kit'
import { TableFilter } from 'shared/ui/table'
import {
  convertDateForQuery,
  createAssignedDoctorsMenu,
  createSubMenu,
  DataFilterQuery,
  DateType,
  DefectsDataFilterQuery,
  FilterQueryKey,
  FilterType,
  IActiveFilter,
  ISubMenu,
  ISubMenuItem,
  ITableRequestConfig,
  menuConfig,
  removeAssignedDoctors,
  updateFilters,
} from 'shared/ui/table/lib/common'
import {
  ContainerSubmenu,
  DateMenuItem,
  LabelContainer,
  SubMenuTitle,
  UserAvatar,
} from 'shared/ui/table/ui/TableFilter'
import styled from 'styled-components'
import useDeepCompareEffect from 'use-deep-compare-effect'

export const t = i18next.t

export const CasesTableFilter: FC = () => {
  const dispatch = useDispatch()
  const [lastSelectedDoctorId, setLastSelectedDoctorId] = useState<string>()
  const isDefectsViewer = !!useSelector(selectDefectsViewerUrlCaseId)
  const [searchTerm, setSearchTerm] = useState('')
  const [dataMenuConfig, setDataMenuConfig] = useState<ISubMenu[]>(menuConfig)
  const { filterParams, setFilterParams } = useCaseManagementContext()
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const wsId = Number(useCurrentWorkspaceId())
  const { data: doctors } = useCaseManagementDoctorsQuery(wsId)
  const filteredDoctors = useFilteredDoctors({ doctors, filterParams, menuTab, searchTerm })
  const dictionaryDiagnostic = useLiveQuery(() => getDataFromDB(EDictionaryNS.DIAGNOSTIC_PROCEDURE_TYPE), []) as TDict
  const dictionaryCaseType = useLiveQuery(() => getDataFromDB(EDictionaryNS.CASE_RESEARCH_TYPE), []) as TDict
  const dictionaryDefectType = useLiveQuery(() => getDataFromDB(EDictionaryNS.DEFECT_TYPE), []) as TDict
  const dictionaryStainType = useLiveQuery(() => getDataFromDB(EDictionaryNS.STAIN), []) as TDict
  const { data: diagnosticData } = { ...dictionaryDiagnostic }
  const { data: caseTypeData } = { ...dictionaryCaseType }
  const { data: defectTypeData } = { ...dictionaryDefectType }
  const { data: stainTypeData } = { ...dictionaryStainType }
  const [subMenuKey, setSubMenuKeyFilter] = useState('')
  const { isCaseRouting, isEnableSlideDefects } = useSettingsAndUserRoles()
  const { tableQuery } = useCaseManagementContext()
  const debouncedTabQuery = useDebounce(tableQuery, SEARCH_BUFFER)
  const [selectedFilters, setSelectedFilters] = useState<IActiveFilter[]>([])

  /** Фильтр по способу получения материала */
  const diagnosticSubMenu = createSubMenu(
    diagnosticData,
    FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID,
    t('Способ получения материала'),
  )
  /** Фильтр по типу исследования */
  const caseResearchSubMenu = createSubMenu(caseTypeData, FilterQueryKey.CASE_RESEARCH_TYPE_ID, t('Тип исследования'))
  /** Фильтр по типу дефекта */
  const defectSubMenu = createSubMenu(defectTypeData, FilterQueryKey.DEFECT_TYPE_ID, t('Тип дефекта'))
  /** Фильтр по окраске */
  const stainSubMenu = createSubMenu(stainTypeData, FilterQueryKey.STAIN_ID, t('Окраска'))

  const urgentFilter = menuConfig.find((item) => item.key === FilterQueryKey.URGENT) as ISubMenu
  const stageFilter = menuConfig.find((item) => item.key === FilterQueryKey.STAGE) as ISubMenu
  const slidesCountFilter = menuConfig.find((item) => item.key === FilterQueryKey.SLIDES_COUNT) as ISubMenu
  const dateRegistrationFilter = menuConfig.find((item) => item.key === FilterQueryKey.DATE_REGISTRATION) as ISubMenu
  const containsDefectFilter = menuConfig.find((item) => item.key === FilterQueryKey.SLIDE_DEFECT) as ISubMenu
  const falsePositiveFilter = menuConfig.find((item) => item.key === FilterQueryKey.FALSE_POSITIVE) as ISubMenu
  const createdAtFilter = menuConfig.find((item) => item.key === FilterQueryKey.DEFECT_DATE) as ISubMenu
  const defectCreatedByFilter = menuConfig.find((item) => item.key === FilterQueryKey.DEFECT_CREATED_BY) as ISubMenu

  useDeepCompareEffect(() => {
    setDataMenuConfig(() => {
      const commonFilters = [slidesCountFilter, urgentFilter, stageFilter, dateRegistrationFilter]
      let newConfig: ISubMenu[] = []

      switch (menuTab) {
        case ECaseTableType.ROUTING:
          newConfig = [...commonFilters]
          if (caseTypeData) newConfig.push(caseResearchSubMenu)
          if (diagnosticData) newConfig.push(diagnosticSubMenu)
          if (filteredDoctors?.length) {
            newConfig.push(createAssignedDoctorsMenu(filteredDoctors, t('Назначенный врач')))
          }
          break

        case ECaseTableType.WORKSPACE:
        case ECaseTableType.WORKLIST:
          newConfig = [...commonFilters, containsDefectFilter]
          if (caseTypeData) newConfig.push(caseResearchSubMenu)
          if (diagnosticData) newConfig.push(diagnosticSubMenu)
          break

        case ECaseTableType.DEFECTS:
          newConfig = [createdAtFilter, falsePositiveFilter, defectCreatedByFilter]
          if (caseTypeData) newConfig.push(caseResearchSubMenu)
          if (defectSubMenu) newConfig.push(defectSubMenu)
          if (stainSubMenu) newConfig.push(stainSubMenu)
          break

        default:
          break
      }

      return newConfig.filter(Boolean)
    })
  }, [[menuTab], filteredDoctors, caseTypeData, diagnosticData, defectTypeData, stainTypeData])

  useEffect(() => {
    if (menuTab !== ECaseTableType.DEFECTS) return

    const savedFilters = localStorage.getItem(DEFECTS_TABLE_FILTERS_KEY)

    if (savedFilters) setSelectedFilters(JSON.parse(savedFilters))
  }, [menuTab, currentTab])

  useEffect(() => {
    if (menuTab === ECaseTableType.DEFECTS) {
      localStorage.setItem(DEFECTS_TABLE_FILTERS_KEY, JSON.stringify(selectedFilters))
      dispatch(viewerPageSlice.actions.setDefectsFilters(selectedFilters))
    }
  }, [selectedFilters])

  useEffect(
    () => () => {
      setDataMenuConfig((prevConfig) => {
        removeAssignedDoctors(prevConfig)
        return [...prevConfig]
      })
    },
    [menuTab],
  )

  useEffect(() => {
    if (menuTab !== ECaseTableType.DEFECTS) {
      setSelectedFilters(() => [])
    }
  }, [currentTab, menuTab])

  useEffect(() => {
    setFilterParams(mapFiltersForRequest(selectedFilters, tableQuery))
  }, [selectedFilters, debouncedTabQuery])

  const mapFiltersForRequest = (selectedFilters: IActiveFilter[], tableQuery: string): ITableRequestConfig => {
    const filters = selectedFilters.reduce<ITableRequestConfig>((acc, filter) => {
      const { activeFilters, key } = filter

      const processDateRange = (fromKey: string, toKey: string) => {
        if (activeFilters.length > 0 && activeFilters[0].dateRange) {
          acc[fromKey] = convertDateForQuery(activeFilters[0].dateRange.from, DateType.FROM) || undefined
          acc[toKey] = convertDateForQuery(activeFilters[0].dateRange.to, DateType.TO) || undefined
        }
      }

      switch (key) {
        case FilterQueryKey.URGENT:
        case FilterQueryKey.FALSE_POSITIVE:
        case FilterQueryKey.DEFECT_CREATED_BY:
        case FilterQueryKey.SLIDE_DEFECT:
          if (filter.activeFilters.length === 1) acc[key] = activeFilters[0].queryValue
          break

        case FilterQueryKey.DEFECT_DATE:
          processDateRange(DefectsDataFilterQuery.FROM, DefectsDataFilterQuery.TO)
          break

        case FilterQueryKey.DATE_REGISTRATION:
          processDateRange(DataFilterQuery.FROM, DataFilterQuery.TO)
          break

        case FilterQueryKey.STAGE:
        case FilterQueryKey.ASSIGNED_DOCTORS:
          acc[key] = activeFilters.map((activeFilter) => activeFilter.queryValue as string)
          break

        case FilterQueryKey.SLIDES_COUNT:
          acc[key] = activeFilters[0].queryValue
          break

        case FilterQueryKey.CASE_RESEARCH_TYPE_ID:
        case FilterQueryKey.DEFECT_TYPE_ID:
        case FilterQueryKey.STAIN_ID:
        case FilterQueryKey.DIAGNOSTIC_PROCEDURE_TYPE_ID:
          acc[key] = activeFilters.map((activeFilter) => activeFilter.queryValue as number)
          break

        default:
          activeFilters.forEach((activeFilter) => {
            acc[key] = activeFilter.queryValue ? activeFilter.queryValue : activeFilter.filterValue
          })
      }

      return acc
    }, {})

    if (tableQuery) {
      filters[FilterQueryKey.QUERY] = tableQuery
    }

    return filters
  }

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleDataRangeChange = (dates: [Date | null, Date | null] | null, dateStrings: [string, string]) => {
    const isDefectsTab = menuTab === ECaseTableType.DEFECTS
    const key = isDefectsTab ? FilterQueryKey.DEFECT_DATE : FilterQueryKey.DATE_REGISTRATION
    const title = isDefectsTab ? t('Дата обнаружения дефекта') : t('Дата регистрации в ЛИС')
    setSelectedFilters((prevFilters) =>
      updateFilters(
        prevFilters,
        null,
        true,
        {
          filterType: FilterType.DATA_RANGE,
          items: [],
          key,
          title,
        },
        dateStrings,
      ),
    )
  }

  const assignedDoctorUserId = (filterParams as TCaseManagementFilterParams)?.assignedDoctorUserId

  useEffect(() => {
    if (assignedDoctorUserId?.length) {
      setLastSelectedDoctorId(String(assignedDoctorUserId[assignedDoctorUserId.length - 1]))
    } else {
      setLastSelectedDoctorId('')
    }
  }, [assignedDoctorUserId])

  const handleFilterChange = (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => {
    setSelectedFilters((prevFilters) => updateFilters(prevFilters, item, e.target.checked, subMenu))
  }

  const handleRemoveFilter = useCallback((subMenuKey: React.Key) => {
    setSelectedFilters((prevFilters) => prevFilters.filter((filter) => filter.key !== subMenuKey))
  }, [])

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value)
  }

  const handleTitleClick = (subMenuKey: string) => setSubMenuKeyFilter(subMenuKey)

  const menu = dataMenuConfig.map((subMenu) => {
    let menuContent

    const dateRange = selectedFilters.filter((it) => it.key === subMenuKey)[0]?.activeFilters[0]?.dateRange
    const isActive = selectedFilters.some((filter) => filter.key === subMenu.key)

    if (subMenu.key.startsWith(FilterQueryKey.STAGE)) {
      menuContent = caseStagesFilters({ currentTab, handleFilterChange, isCaseRouting, isFilterActive })
    } else {
      switch (subMenu.filterType) {
        case FilterType.SINGLE_SELECT:
        case FilterType.CHECKBOX:
          menuContent = (subMenu.items || []).map((item, index) => (
            <React.Fragment key={`${item.key || index}-${index}`}>
              <Menu.Item className="cases-filter-submenu-item">
                {/** Обёртка для предотвращения закрытия саб меню при клике */}
                <div onClick={(e) => e.stopPropagation()}>
                  <CheckboxElement
                    style={{ alignItems: 'center' }}
                    onChange={handleFilterChange(item, subMenu)}
                    checked={isFilterActive(item.key)}
                  >
                    {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS ? (
                      <ContainerSubmenu>
                        <UserAvatar numberValue={Number(item.queryValue)} fullname={item.label} />
                        <LabelContainer>{item.label}</LabelContainer>
                      </ContainerSubmenu>
                    ) : (
                      item.label
                    )}
                  </CheckboxElement>
                </div>
              </Menu.Item>
              {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS && String(item.queryValue) === lastSelectedDoctorId && (
                <Divider />
              )}
            </React.Fragment>
          ))
          break

        case FilterType.DATA_RANGE:
          menuContent = (
            <DateMenuItem
              handleDataRangeChange={handleDataRangeChange}
              valueFrom={dateRange?.from}
              valueTo={dateRange?.to}
              open={subMenuKey === subMenu.key}
            />
          )

          break

        default:
          setSubMenuKeyFilter('')
          menuContent = null
      }
    }

    if (currentTab === EAllCasesTabType.DELETED && subMenu.key.startsWith(FilterQueryKey.STAGE)) {
      return null
    }

    return (
      <Menu.SubMenu
        key={subMenu.key}
        popupClassName={
          subMenu.filterType === FilterType.DATA_RANGE ? 'submenu-filter-datarange-style' : 'submenu-filter-style'
        }
        style={{ display: !isEnableSlideDefects && subMenu.key === FilterQueryKey.SLIDE_DEFECT ? 'none' : undefined }}
        title={<SubMenuTitle isDefectsViewer={isDefectsViewer} isActive={isActive} title={subMenu.title} />}
        onTitleClick={() => handleTitleClick(subMenu.key)}
        data-testid="submenu-filter"
      >
        {subMenu.key === FilterQueryKey.ASSIGNED_DOCTORS && (
          <>
            <StyledSearchElement
              value={searchTerm}
              onChange={handleSearchChange}
              prefix={null}
              placeholder={`${t(`Поиск`)}`}
            />
            <Divider />
          </>
        )}
        {menuContent}
      </Menu.SubMenu>
    )
  })

  return (
    <TableFilter
      dataMenuConfig={dataMenuConfig}
      handleRemoveFilter={handleRemoveFilter}
      selectedFilters={selectedFilters}
      handleFilterChange={handleFilterChange}
      handleDataRangeChange={handleDataRangeChange}
      setSubMenuKeyFilter={setSubMenuKeyFilter}
      menu={menu}
    />
  )
}

const StyledSearchElement = styled(SearchElement)`
  border-radius: 5px;
  height: 24px;
  width: 94%;
  margin: 8px;

  &.ant-input-affix-wrapper {
    padding-left: 8px;
  }
`
