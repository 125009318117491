import type { SortOrder } from 'antd/lib/table/interface'
import { format, parseISO } from 'date-fns'
import {
  EAllCasesTabType,
  ECaseTableType,
  EDistributionTabType,
  EMyCasesTabType,
} from 'features/cases-management/types/ECaseTableType'
import {
  ECaseSortBy,
  ESortDir,
  TCaseManagementFilterParams,
  TCaseManagementQueryParams,
  TQueryParams,
} from 'features/cases-management/types/TCasePagination'
import { ROW_HEIGHT } from 'features/cases-management/ui/cases-table/CasesTable'
import { SorterState } from 'features/cases-management/ui/doctor-list/DoctorsList'
import { EDefectsSortBy, EDefectsTableType, TDefectsFilterParams } from 'features/defects/types/TDefectsPagination'
import { RefObject } from 'react'
import { QueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { IDoctor } from 'types/IUser'

export const formatDate = (dateString: string) => format(parseISO(dateString), 'dd.MM.yyyy')

/** Получаем текущую страницу */
export const getCurrentPageIndex = () => {
  const firstRowEl = document.querySelector('tr[data-row-key]') as HTMLTableRowElement

  const rowKey = firstRowEl ? (firstRowEl.getAttribute('data-row-key') as string) : undefined
  if (!rowKey) return 0

  const [currentPageIndex] = rowKey.split('-').map(Number)
  return currentPageIndex
}

/** Обновление информации по страницам в табах менеджмента */
export const refetchPages = (
  queryClient: QueryClient,
  menuTab: ECaseTableType,
  queryParams: TCaseManagementQueryParams,
  filterParams: TCaseManagementFilterParams | TDefectsFilterParams,
  wsId: number,
  size: number,
) => {
  const currentPageIndex = getCurrentPageIndex()

  queryClient.invalidateQueries(
    [QUERY_TYPE.CASE_MANAGEMENT, menuTab, JSON.stringify(queryParams), JSON.stringify(filterParams), size, wsId],
    {
      refetchPage: (_, index) => [currentPageIndex - 1, currentPageIndex, currentPageIndex + 1].includes(index),
    },
  )
  queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_CASES_COUNT, menuTab, wsId])
  if (menuTab === ECaseTableType.ROUTING) queryClient.invalidateQueries([QUERY_TYPE.CASE_MANAGEMENT_DOCTORS, wsId])
}

/** Сортировка массива с докторами в зависимости от направления */
export const sortDoctors = (data: IDoctor[], sortOrder: SorterState) => {
  if (sortOrder === SorterState.Initial) return data

  const sortedData = [...data]

  // Функция для сортировки по возрастанию (asc)
  const ascSort = (a: IDoctor, b: IDoctor) => a.fullname.localeCompare(b.fullname)
  // Функция для сортировки по убыванию (desc)
  const descSort = (a: IDoctor, b: IDoctor) => b.fullname.localeCompare(a.fullname)

  sortOrder === SorterState.Ascending ? sortedData.sort(ascSort) : sortedData.sort(descSort)

  return sortedData
}

export const getQueryParams = (
  tabsQueryParams: TQueryParams,
  menuTab: ECaseTableType,
  tabType: EMyCasesTabType | EDistributionTabType | EAllCasesTabType | EDefectsTableType,
  isCaseRouting: boolean,
  isDeadlineMode?: boolean,
  isGemotest?: boolean,
) =>
  tabsQueryParams[menuTab]?.[tabType]?.queryParams ?? {
    sortBy: isDeadlineMode && !isGemotest ? ECaseSortBy.DUE_DATE : ECaseSortBy.CASE_DATE,
    // для бесплатных пользователей меняем сортировку
    // Нужна именно строгая проверка на false (изначально isCaseRouting = undefined)
    sortDir: isCaseRouting === false ? ESortDir.DESC : ESortDir.ASC,
  }

export const setSortOrder = (
  tabsQueryParams: TQueryParams,
  menuTab: ECaseTableType,
  tabType: EMyCasesTabType | EDistributionTabType | EAllCasesTabType | EDefectsTableType,
  sortBy: ECaseSortBy | EDefectsSortBy,
) =>
  tabsQueryParams[menuTab]?.[tabType]?.queryParams.sortBy === sortBy
    ? tabsQueryParams[menuTab]?.[tabType]?.queryParams.sortDir === ESortDir.ASC
      ? ('ascend' as SortOrder)
      : ('descend' as SortOrder)
    : undefined

/** Вычисление видимого количества строк*/
export const calculateVisibleRows = (
  /** Ссылка на таблицу */
  tableContainerRef: RefObject<HTMLDivElement>,
  /** Обновить высоту таблицы */
  setTableBodyHeight: (height: number) => void,
  /** Обновить видимое количество строк */
  setVisibleRowsCount: (count: number) => void,
) => {
  if (!tableContainerRef.current) return
  const tableBody = tableContainerRef.current.querySelector('.ant-table-container')
  if (!tableBody) return
  const localTableBodyHeight = tableBody.clientHeight
  setTableBodyHeight(localTableBodyHeight)
  const rowsCount = Math.floor(localTableBodyHeight / ROW_HEIGHT)
  setVisibleRowsCount(rowsCount)
}
