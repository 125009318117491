import { useSlideGrouped } from 'features/thumbnails/hooks'
import { selectUrlCaseId } from 'pages/viewer'
import { useOpenViewers, useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import CommonViewerRouteLayout from 'pages/viewer/ui/common/CommonViewerRouteLayout'
import { useSelector } from 'react-redux'

import ViewerContainer from './ViewerContainer'

export const ViewerRoute = () => {
  const { activeViewerId: viewerId } = useOpenViewers()
  const { caseId } = useViewerIdSlideState(viewerId)
  const urlCaseId = useSelector(selectUrlCaseId) || caseId
  const { slidesIds: slideIds } = useSlideGrouped(urlCaseId)

  return (
    <CommonViewerRouteLayout slidesIds={slideIds}>
      <ViewerContainer />
    </CommonViewerRouteLayout>
  )
}
