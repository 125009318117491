import { TabPaneProps, Tabs, TabsProps } from 'antd'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React, { FC, PropsWithChildren, ReactNode, useEffect, useRef, useState } from 'react'
import { trimTextWithLastChar } from 'shared/lib/text'
import styled from 'styled-components'
import { EUploadedFileTab } from 'types/IUploadedFile'
import { TCasesManagementTabs } from 'types/TTab'

/** Пропсы для таба */
export type TTabItem = {
  /** Лейбл */
  label: string | ReactNode
  /** Количество кейсов в табе для отображения в название таба */
  count?: number
  /** id таба */
  id: string
  /** Цвет для количества */
  color?: string
  /** Тип таба */
  tabType: TCasesManagementTabs | EUploadedFileTab
  /** Дополнительная информация о количество кейсов */
  specialCount?: number | string
  dataTestId?: string
}

export type TTableTabsProps = {
  tabsItems: TTabItem[]
  activeTabType: any
  /** Ширины блока кнопок для массовых действий */
  actionButtonsWidth?: number
  /** Коллбэк, который вызывается при изменении ширины контейнера табов */
  onTabsWidthChange?: (width: number) => void
} & TabPaneProps &
  TabsProps

export const TableTabs: FC<PropsWithChildren<TTableTabsProps>> = ({
  actionButtonsWidth,
  activeTabType,
  children,
  onChange,
  onTabClick,
  onTabsWidthChange,
  tabBarExtraContent,
  tabsItems,
  ...rest
}) => {
  /** Состояние, определяющее, помещается ли фильтр в текущий размер экрана рядом с табами. */
  const [isFilterFits, setIsFilterFits] = useState(true)
  const [isDefectsFilterFits, setIsDefectsFilterFits] = useState(true)

  const tableTabsRef = useRef<HTMLDivElement | null>(null)
  const filterRef = useRef<HTMLDivElement | null>(null)
  const { menuTab } = useCaseManagementRouteParam()
  const { defectsTableFiltersWidth, filterParams } = useCaseManagementContext()

  const EXTRA_MARGIN = 15

  /** Функция, определяющая, помещается ли фильтр в текущий размер экрана рядом с табами. */
  const checkFilterFit = () => {
    const tableTabs = tableTabsRef.current
    const tabsWidth = tableTabs?.querySelector('.ant-tabs-nav-wrap')?.getBoundingClientRect().width || 0
    const filterWidth = (filterRef.current?.getBoundingClientRect().width || 0) + (actionButtonsWidth || 0)
    const filterWrapperWidth = window.innerWidth - tabsWidth
    const slideActionsButtons =
      tableTabsRef.current?.querySelector('.ant-tabs-extra-content')?.children[0]?.getBoundingClientRect().width || 0
    setIsFilterFits(filterWidth <= filterWrapperWidth)
    setIsDefectsFilterFits(defectsTableFiltersWidth <= filterWrapperWidth - (slideActionsButtons + EXTRA_MARGIN || 0))

    /** Вызываем коллбэк с шириной табов */
    onTabsWidthChange?.(tabsWidth)
  }

  useEffect(() => {
    checkFilterFit()
    window.addEventListener('resize', checkFilterFit)
    return () => {
      window.removeEventListener('resize', checkFilterFit)
    }
  }, [filterParams])

  return (
    <StyledTabs
      menuTab={menuTab}
      {...rest}
      ref={tableTabsRef}
      defaultActiveKey={activeTabType}
      type="card"
      activeKey={activeTabType}
      tabBarGutter={0}
      size="small"
      onTabClick={onTabClick}
      onChange={onChange}
      destroyInactiveTabPane
      tabBarExtraContent={<div ref={filterRef}>{tabBarExtraContent}</div>}
      isFilterFits={isFilterFits}
      isDefectsFilterFits={isDefectsFilterFits}
    >
      {tabsItems.map(({ color, count, dataTestId, label, specialCount, tabType }) => {
        const customCount = specialCount ?? count

        return (
          <Tabs.TabPane
            key={tabType}
            tab={
              <LabelWrapper data-testid={`${(dataTestId ?? tabType).toLowerCase()}-tab`}>
                <div className="custom-tab-border-left" />
                <p style={{ marginRight: '8px' }}>{label}</p>
                <p
                  data-testid={`${tabType.toLowerCase()}-tab-extra-label`}
                  style={{ color: color || 'var(--color-text-1)' }}
                >
                  {trimTextWithLastChar(customCount)}
                </p>
                <div className="custom-tab-border-right" />
              </LabelWrapper>
            }
          >
            <ContentWrapper>{children}</ContentWrapper>
          </Tabs.TabPane>
        )
      })}
    </StyledTabs>
  )
}

const StyledTabs = styled(
  React.forwardRef<
    HTMLDivElement,
    TabsProps & { isFilterFits: boolean; menuTab: string; isDefectsFilterFits: boolean }
  >((props, ref) => (
    <div style={{ height: '100%' }} ref={ref}>
      <Tabs {...props} />
    </div>
  )),
)`
  & .ant-tabs-content {
    height: 100%;
  }

  && .ant-tabs {
    width: 100vw;
  }

  .ant-tabs-nav {
    margin: 0;
    background: var(--color-bg-1);
    display: grid;
    grid-template-areas: ${({ isFilterFits }) => (isFilterFits ? `'tabs filter'` : `'filter' 'tabs'`)};
    grid-template-columns: ${({ menuTab }) => menuTab === 'defects' && 'max-content'};
    margin-top: ${({ isDefectsFilterFits, menuTab }) => {
      if (menuTab === 'defects' && !isDefectsFilterFits) {
        return '-15px'
      }
      if (menuTab === 'defects') {
        return '-48px'
      }
      return '0'
    }};
  }
  & .ant-tabs-nav-list {
    margin-left: 16px;
    margin-right: 8px;
  }

  && .ant-tabs-tab {
    background: var(--color-bg-1);
    color: var(--color-text-1);
    font-size: 14px;
    line-height: 20px;
    margin: 0;
    padding: 0 6px;
    transition-duration: 0s;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab {
    border-radius: 10px;
  }

  & .ant-tabs-tab.ant-tabs-tab-active {
    position: relative;
    color: var(--color-text-1);
    background: var(--color-bg-2);
    border-radius: 10px 10px 0px 0px !important;
  }

  .ant-tabs-tab-btn:hover {
    color: var(--color-text-1);
  }

  && .ant-tabs-nav-operations {
    display: none;
  }

  .ant-tabs-extra-content {
    padding: 8px 0;
    grid-area: filter;
    display: flex;
    justify-content: ${({ menuTab }) => (menuTab === 'defects' ? 'flex-start' : 'flex-end')};
  }

  && .ant-tabs-nav-wrap {
    grid-area: tabs;
    width: fit-content;
  }

  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-left {
    position: absolute;
    width: 10px;
    height: 10px;
    left: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-right-radius: 10px;
    }
  }
  && .ant-tabs-tab.ant-tabs-tab-active .custom-tab-border-right {
    position: absolute;
    width: 10px;
    height: 10px;
    right: -10px;
    bottom: 0px;
    background: var(--color-bg-2);

    &:after {
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      right: 0px;
      bottom: 0px;
      background: var(--color-bg-1);
      border-bottom-left-radius: 10px;
    }
  }
  .ant-tabs-nav-list {
    margin-top: ${({ isFilterFits }) => (isFilterFits ? '16px' : '0px')};
  }
`

const LabelWrapper = styled.div`
  display: flex;
  align-items: center;

  p {
    margin: 0;
  }
`
const ContentWrapper = styled.div`
  padding: 16px 16px 0;
  display: flex;
  height: 100%;
  flex-direction: row;
`
