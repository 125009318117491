import type { SortOrder } from 'antd/lib/table/interface'
import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { SumpSlidesFilters } from 'features/sump/api/query'
import { EUploadedFileSortBy, EUploadedFileTab } from 'types/IUploadedFile'

export const setSortOrder = (filters: SumpSlidesFilters, tabType: EUploadedFileTab, sortBy: EUploadedFileSortBy) =>
  filters?.sortBy === sortBy
    ? filters?.sortDir === 'ASC'
      ? ('ascend' as SortOrder)
      : ('descend' as SortOrder)
    : undefined

/** Преобразует направление сортировки с учетом маршрутизации */
export const gerSortDirectionConfig = (sortDir?: ESortDir, isCaseRouting?: boolean) => {
  if (!sortDir) {
    /** обязательно оставляем строгое сравнение на false */
    return isCaseRouting === false ? ESortDir.descend : ESortDir.ascend
  }
  return sortDir === ESortDir.ASC ? ESortDir.ascend : ESortDir.descend
}

export const snakeToCamel = (str?: string) =>
  str?.toLowerCase().replace(/(_\w)/g, (m: string) => m.toUpperCase().substr(1)) || ''
