import { differenceInDays, differenceInHours, differenceInMinutes, format, isBefore } from 'date-fns'
import { Integration } from 'entities/lis-integration/types'
import { ECaseTableType, EMyCasesTabType } from 'features/cases-management/types/ECaseTableType'
import { t } from 'i18next'
import React, { FC } from 'react'
import { TooltipElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { EExpirationState } from 'types/ICase'
import { TCasesManagementTabs } from 'types/TTab'

/** Формат даты без времени */
const DATE_FORMAT_WITHOUT_TIME = 'dd.MM.yyyy'
/** Формат даты с временем */
const DATE_FORMAT_WITH_TIME = 'dd.MM.yyyy HH:mm'
/** Для "желтых" и "красных" сроков font-weight: 600 */
const FONT_WEIGHT_FOR_EXPIRED_DATE = 600

const StyledDueDate = styled.span<{ expirationState: EExpirationState | null }>`
  font-weight: ${({ expirationState }) =>
    expirationState !== null && expirationState !== EExpirationState.NOT_EXPIRED ? FONT_WEIGHT_FOR_EXPIRED_DATE : ''};
`

interface Props {
  /** Дата окончания срока */
  dueDate: Date | null
  /** Состояние истечения срока */
  expirationState: EExpirationState | null
  /** Режим отображения дедлайна */
  deadlineMode: string
  /** Флаг видимости всплывающей подсказки */
  tooltipVisible: boolean
}

const DueDate: FC<Props> = ({ deadlineMode, dueDate, expirationState, tooltipVisible }) => (
  <div style={{ color: expirationState ? getExpirationColor(expirationState) : '' }}>
    {dueDate ? (
      <TooltipElement
        placement="bottom"
        title={
          tooltipVisible ? (
            <>
              {t('Ожидаемая дата ответа')}:
              <br />
              {format(
                new Date(dueDate),
                deadlineMode === DeadlineMode.daysUntilNextWorkday ? DATE_FORMAT_WITHOUT_TIME : DATE_FORMAT_WITH_TIME,
              )}
            </>
          ) : null
        }
      >
        <StyledDueDate expirationState={expirationState}>
          {calculateTimeDifference(dueDate, deadlineMode)}
        </StyledDueDate>
      </TooltipElement>
    ) : (
      '-'
    )}
  </div>
)

export default DueDate

enum DeadlineMode {
  none = 'none',
  /** В часах */
  hours = 'hours',
  /**
   * Окончание срока ответа dueDate: начало рабочего дня, следующего за последним рабочим,
   * т.е. выходные, следующие за последним рабочим днем,
   * пропускаются, и dueDate является рабочим днем (время 00:00).
   */
  daysUntilNextWorkday = 'daysUntilNextWorkday',
}

/** Количество минут в одном часе */
const MINUTES_IN_HOUR = 60
/** Количество часов в одном дне */
const HOURS_IN_DAY = 24
/** Минимальное количество цифр при форматировании минут */
const MIN_DIGITS = 2
/** Символ для дополнения строки при форматировании */
const DEFAULT_PAD_CHAR = '0'

/**
 * Вычисляет разницу во времени между заданной датой и текущим моментом
 * @param dateString - Строка даты в формате ISO 8601
 * @param deadlineMode - Режим отображения сроков
 * @returns Строка с разницей во времени в нужном формате
 */
const calculateTimeDifference = (dateString: Date, deadlineMode: string) => {
  const inputDate = new Date(dateString)
  const currentDate = new Date()

  const days = Math.abs(differenceInDays(inputDate, currentDate))
  const totalHours = Math.abs(differenceInHours(inputDate, currentDate))
  const minutes = Math.abs(differenceInMinutes(inputDate, currentDate) % MINUTES_IN_HOUR)
  const hoursRemainder = totalHours % HOURS_IN_DAY

  // Определяем знак (прошлое или будущее)
  // Если время ответа уже истекло, но менее 24ч, то нужно отображать 0d.
  const sign = isBefore(inputDate, currentDate) && days >= 0 && totalHours >= 0 ? '-' : ''

  if (deadlineMode === DeadlineMode.daysUntilNextWorkday) {
    return `${sign}${days + 1 + t('д')}`
  }

  if (totalHours > HOURS_IN_DAY) {
    return `${sign}${days + t('д')} ${hoursRemainder + t('ч')}`
  }

  return `${sign}${totalHours + t('ч')} ${minutes.toString().padStart(MIN_DIGITS, DEFAULT_PAD_CHAR) + t('м')}`
}

/**
 * Возвращает цвет в зависимости от статуса срока
 * @param state Статус срока из EExpirationState
 * @returns Цвет для почти истекшего (#FFA726) или истекшего (#F44336) срока.
 */
const getExpirationColor = (state: EExpirationState): string => {
  switch (state) {
    case EExpirationState.ALMOST_EXPIRED:
      return 'var(--color-yellow)' // желтый
    case EExpirationState.EXPIRED:
      return 'var(--color-red)' // красный
    default:
      return '' // для NOT_EXPIRED цвет не нужен
  }
}

/**
 * Проверяет видимость контроля сроков в зависимости от типа таба и интеграции
 * @param {ECaseTableType} menuTab - Тип текущего таба меню
 * @param {TCasesManagementTabs} caseManagementTabs - Тип таба управления кейсами
 * @param {Integration} [lisMode] - Тип интеграции
 * @returns {boolean} Флаг видимости срока выполнения
 */
export const checkDueDateVisibility = (
  menuTab: ECaseTableType,
  caseManagementTabs: TCasesManagementTabs,
  lisMode?: Integration,
): boolean => {
  switch (lisMode) {
    case 'none':
    case 'DeGenie':
      return false
    case 'gemotest':
      return menuTab === 'routing'
    case 'DZM':
      return (
        menuTab === 'routing' ||
        (menuTab === 'worklist' &&
          [EMyCasesTabType.ASSIGNED_TO_ME, EMyCasesTabType.INCOMING].includes(caseManagementTabs as EMyCasesTabType))
      )
    default:
      return false
  }
}
