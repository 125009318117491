import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import React, { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { ButtonElement } from 'shared/ui/kit'
import Flex from 'shared/ui/kit/ui/Flex'
import styled from 'styled-components/macro'

const Wrapper = styled(Flex)`
  height: 52px;
  padding: 12px 16px 16px;
  width: 100%;
`

interface Props {
  onSaveClick: () => void
  /**
   * Функция, вызываемая для удаления заключения.
   */
  onDelete: (reportId: number) => void
  /**
   *  Функция, вызываемая для удаления заключения.
   */
  onCancel: () => void
  /**
   * Состояние сохранения заключения
   */
  isSaving: boolean
  /**
   * Флаг, указывающий, что заключение в данный момент удаляется.
   */
  isDeleting: boolean
  /**
   * Коллбек для сохранения подписания заключения.
   */
  onSign: () => void
  /**
   * Коллбек для сохранения черновика заключения.
   */
  onSaveDraft: () => void
  /**
   * Флаг, указывающий, что заключение в данный момент подписывается.
   */
  isSigning: boolean
}

const ReportCreationFormLeftFooterContainer: FC<Props> = ({
  isDeleting,
  isSaving,
  isSigning,
  onCancel,
  onDelete,
  onSaveClick,
  onSaveDraft,
  onSign,
}) => {
  const { t } = useTranslation()
  const { isLisMode } = useSettingsAndUserRoles()
  const { hasValidateErrors, initialReport, isConflictError } = useReportCreationContext()
  const { medicalReportId } = initialReport

  return (
    <Wrapper align="center" justify="end" gap={12}>
      {isLisMode ? (
        <>
          <ButtonElement onClick={onSaveDraft} loading={isSaving && !isSigning} disabled={isConflictError}>
            {t('Сохранить черновик')}
          </ButtonElement>
          <ButtonElement type="primary" onClick={onSign} loading={isSigning} disabled={isConflictError}>
            {t('Подписать')}
          </ButtonElement>
        </>
      ) : (
        <>
          {medicalReportId && (
            <ButtonElement
              danger
              style={{ marginRight: 'auto' }}
              onClick={() => medicalReportId && onDelete(medicalReportId)}
              loading={isDeleting}
              disabled={isDeleting}
            >
              {t('Удалить')}
            </ButtonElement>
          )}
          <ButtonElement onClick={onCancel}>{t('Отменить')}</ButtonElement>
          <ButtonElement
            type="primary"
            onClick={onSaveClick}
            loading={isSaving}
            disabled={isSaving || hasValidateErrors}
          >
            {t('Сохранить')}
          </ButtonElement>
        </>
      )}
    </Wrapper>
  )
}
export default ReportCreationFormLeftFooterContainer
