import { getDataFromDB } from 'app/indexedDB'
import { useTypedSelector } from 'app/redux/lib/selector'
import { useLiveQuery } from 'dexie-react-hooks'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import { Feature } from 'ol'
import GeoJSON from 'ol/format/GeoJSON'
import Polygon from 'ol/geom/Polygon'
import { viewerPageSlice } from 'pages/viewer'
import { selectDefectsViewerUrlCaseId } from 'pages/viewer/model/viewerPageSlice'
import { createContext, useContext, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { TDefectTypeDictionaryItem } from 'types/IDictionary'
import { DatalayerStatus, DataLayerTypes, ISlideDataLayer } from 'types/ISlideDataLayer'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch } from 'viewer/container'
import { useCurrentDataLayer, useSlideDataLayers } from 'viewer/tools/api/query'
import { getValidDefectTypes } from 'viewer/tools/ui/artefacts/lib/utils'

/** Контекст Детекции артефактов  */
type IPathVisionSegmentation = {
  /** Массив features из GeoJSON-а dataLayer ARTEFACTS */
  features: Feature<Polygon>[]
  /** Уровень прозрачность полигонов на карте */
  opacity: number
  /** Коллбэк для установки уровня прозрачности полигонов на карте */
  setOpacity: (val: number) => void
  /** Класс подсвечиваемой фичи */
  hoveredClass?: string
  /** Коллбэк для установки класса подсвечиваемой фичи */
  setHoveredClass: (val?: string) => void
  /** Слой с артефактами для слайда */
  artefactsDataLayer: ISlideDataLayer | undefined
  /** Загрузка артефактов */
  isLoading: boolean
  /** Доступность панели артефактов */
  artefactsPanelEnable: boolean
  /** Информация из словаря по features */
  defectsInfoDict?: Record<string, TDefectTypeDictionaryItem>
}

/** Начальный уровень прозрачности полигонов */
const DEFAULT_OPACITY = 50
/** Начальный уровень прозрачности полигонов */
const GEO_JSON_RERENDER_TIME = 100

const ArtefactsContext = createContext<IPathVisionSegmentation>({
  artefactsDataLayer: undefined,
  artefactsPanelEnable: false,
  features: [],
  isLoading: false,
  opacity: DEFAULT_OPACITY,
  setHoveredClass: () => {},
  setOpacity: () => {},
})

export const useArtefactsContext = () => useContext(ArtefactsContext)

/** Props for ArtefactsProvider */
type Props = {
  /** Идентификатор текущего вьювера */
  viewerId: TViewerId
  /** Идентификатор текущего случая */
  caseId: number
  /** Идентификатор текущего слайда */
  slideId: number
  /** Флаг, отслеживающий видимость панели */
  isVisible: boolean
}

export const ArtefactsProvider: React.FC<Props> = ({ caseId, children, isVisible, slideId, viewerId }) => {
  const viewerDispatch = useViewerDispatch(viewerId)
  const [features, setFeatures] = useState<Feature<Polygon>[]>([])
  const [opacity, setOpacity] = useState(DEFAULT_OPACITY)
  const [hoveredClass, setHoveredClass] = useState<string | undefined>()
  const { artefactsVisibility } = useTypedSelector((state) => state.viewerPage)
  const [artefactsIsRender, setArtefactsIsRender] = useState(false)
  const dictionary = useLiveQuery(() => getDataFromDB(EDictionaryNS.DEFECT_TYPE)) as TDict<TDefectTypeDictionaryItem[]>
  const { data: defectTypesDict } = { ...dictionary }
  /**
   * Информация по дефектам, отфильтрованная для списка features
   */
  const [defectsInfoDict, setDefectsInfoDict] = useState<Record<string, TDefectTypeDictionaryItem>>()
  const slideDefectsCaseId = useSelector(selectDefectsViewerUrlCaseId)

  useEffect(() => {
    if (!!defectTypesDict?.length && !!features?.length) {
      setDefectsInfoDict(getValidDefectTypes(defectTypesDict, features))
    }
  }, [defectTypesDict, features])

  const { data: dataLayers, isLoading: slideDataLayersLoading } = useSlideDataLayers({ caseId, slideId })
  const artefactsDataLayer = dataLayers?.find((dataLayer) => isVisible && dataLayer.type === DataLayerTypes.ARTEFACTS)
  const { emptyResult, inProgress, status } = artefactsDataLayer || {}
  const { data: artefactsGeoJSON, isLoading: currentDataLayerLoading } = useCurrentDataLayer({
    caseId,
    slideDataLayerId: artefactsDataLayer?.slideDataLayerId,
    slideId,
  })

  useEffect(() => {
    if (!slideDefectsCaseId) {
      const isVisible = artefactsVisibility ?? false
      viewerDispatch(viewerPageSlice.actions.setTool({ tool: 'ARTEFACTS', value: isVisible }))
    }
  }, [slideId, artefactsVisibility, slideDefectsCaseId])

  useEffect(() => {
    if (artefactsGeoJSON) {
      const features = new GeoJSON().readFeatures(artefactsGeoJSON)
      setFeatures(features)
    }
  }, [artefactsGeoJSON])

  useEffect(() => {
    setArtefactsIsRender(true)
    setTimeout(() => {
      setArtefactsIsRender(false)
    }, GEO_JSON_RERENDER_TIME)
  }, [artefactsGeoJSON])

  const isLoading = currentDataLayerLoading || slideDataLayersLoading || artefactsIsRender

  const artefactsPanelEnable =
    !isLoading && artefactsDataLayer && !inProgress && !emptyResult && status !== DatalayerStatus.FAILED

  /** Фильтруем дефекты которых нет в словаре */
  const filteredFeatures = useMemo(
    () => features.filter((feature) => defectsInfoDict?.[feature.get('class') as string]),
    [features, defectsInfoDict],
  )

  return (
    <ArtefactsContext.Provider
      value={{
        artefactsDataLayer,
        artefactsPanelEnable: !!artefactsPanelEnable,
        defectsInfoDict,
        features: filteredFeatures,
        hoveredClass,
        isLoading,
        opacity,
        setHoveredClass,
        setOpacity,
      }}
    >
      {children}
    </ArtefactsContext.Provider>
  )
}
