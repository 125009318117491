import { Input } from 'antd'
import { SearchProps } from 'antd/es/input'
import styled from 'styled-components/macro'

import { IconElement } from './IconElement'

type Props = SearchProps

const StyledSearch = styled(Input)`
  height: 32px;
  border-radius: 16px;
  background: var(--color-border-1);
  transition: none;
  z-index: 1;

  .ant-input {
    background: none;
    color: var(--color-text-1);
    line-height: 18px;
    font-size: 13px;
  }

  .ant-input-group-addon {
    visibility: hidden;
  }

  .ant-input-prefix {
    color: var(--color-text-3);
  }

  .anticon.ant-input-clear-icon,
  .ant-input-clear-icon {
    margin-top: 1px;
    color: var(--color-text-3);
  }

  &.ant-input-affix-wrapper:focus,
  &.ant-input-affix-wrapper-focused {
    border: none;
    box-shadow: 0 0 0 1px var(--color-purple);
  }

  &.ant-input-affix-wrapper {
    padding: 0 8px 0 4px;
`

export const SearchElement = (props: Props) => (
  <StyledSearch allowClear prefix={<IconElement name="search" />} {...props} />
)
