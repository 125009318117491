import { Button } from 'antd'
import styled from 'styled-components'

export const BubbleButton = styled(Button)<{ isDropdownVisible: boolean }>`
  display: flex;
  align-items: center;
  height: 32px;
  margin-left: 8px;
  padding-right: 8px;
  border-radius: 16px;
  font-weight: 600;
  background-color: var(--color-border-1);
  box-shadow: ${({ isDropdownVisible }) => (isDropdownVisible ? '0 0 0 1px var(--color-purple)' : 'none')};
  color: var(--color-text-1);
  z-index: 1;
  border: none;
  &:hover {
    background-color: var(--color-bg-3);
    color: var(--color-text-1);
    border: none;
  }
  &:active {
    border: 1px solid var(--color-purple);
    color: var(--color-text-1);
  }

  &:focus {
    color: var(--color-text-1);
    border: none;
  }

  .cross-icon-bubble-button {
    margin-left: 10px;
    color: var(--color-text-3);
  }

  .cross-icon-bubble-button:hover {
    color: var(--color-text-1);
  }
`
