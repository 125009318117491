import { ReactComponent as one_mm } from './icons/1mm.svg'
import { ReactComponent as two_mm } from './icons/2mm.svg'
import { ReactComponent as actionArrow } from './icons/actionArrow.svg'
import { ReactComponent as add } from './icons/add.svg'
import { ReactComponent as addExam } from './icons/addExam.svg'
import { ReactComponent as addFrame } from './icons/addFrame.svg'
import { ReactComponent as addImageSmall } from './icons/addImageSmall.svg'
import { ReactComponent as addNote } from './icons/addNote.svg'
import { ReactComponent as aiMistakeSmall } from './icons/aiMistakeSmall.svg'
import { ReactComponent as aiSmall } from './icons/aiSmall.svg'
import { ReactComponent as alert } from './icons/alert.svg'
import { ReactComponent as angle } from './icons/angle.svg'
import { ReactComponent as annotations } from './icons/annotations.svg'
import { ReactComponent as annotationsList } from './icons/annotationsList.svg'
import { ReactComponent as archive } from './icons/archive.svg'
import { ReactComponent as archive16 } from './icons/archive16.svg'
import { ReactComponent as archiveSmall } from './icons/archiveSmall.svg'
import { ReactComponent as archiveSmall2 } from './icons/archiveSmall2.svg'
import { ReactComponent as arrowBack } from './icons/arrowBack.svg'
import { ReactComponent as arrowLeftSmall } from './icons/arrowLeftSmall.svg'
import { ReactComponent as arrowReturn } from './icons/arrowReturn.svg'
import { ReactComponent as arrowRight } from './icons/arrowRight.svg'
import { ReactComponent as arrowRightSmall } from './icons/arrowRightSmall.svg'
import { ReactComponent as atlas } from './icons/atlas.svg'
import { ReactComponent as attention } from './icons/attention.svg'
import { ReactComponent as bigTile } from './icons/bigTile.svg'
import { ReactComponent as bigTiles } from './icons/bigTiles.svg'
import { ReactComponent as bin16 } from './icons/bin16.svg'
import { ReactComponent as brightness } from './icons/brightness.svg'
import { ReactComponent as calendar } from './icons/calendar.svg'
import { ReactComponent as cameraSight } from './icons/cameraSight.svg'
import { ReactComponent as Case } from './icons/case.svg'
import { ReactComponent as caseLink } from './icons/caseLink.svg'
import { ReactComponent as checkmark } from './icons/checkmark.svg'
import { ReactComponent as checkSmall } from './icons/checkSmall.svg'
import { ReactComponent as checkSmall2 } from './icons/checkSmall2.svg'
import { ReactComponent as circle } from './icons/circle.svg'
import { ReactComponent as cito } from './icons/cito.svg'
import { ReactComponent as clock } from './icons/clock.svg'
import { ReactComponent as colorCorrection } from './icons/colorCorrection.svg'
import { ReactComponent as comment } from './icons/comment.svg'
import { ReactComponent as commentSmall } from './icons/commentSmall.svg'
import { ReactComponent as contrast } from './icons/contrast.svg'
import { ReactComponent as copySmall } from './icons/copySmall.svg'
import { ReactComponent as coregDropDown } from './icons/coregDropDown.svg'
import { ReactComponent as coregError } from './icons/coregError.svg'
import { ReactComponent as counter } from './icons/counter.svg'
import { ReactComponent as cross } from './icons/cross.svg'
import { ReactComponent as cross16 } from './icons/cross16.svg'
import { ReactComponent as defectSlideSmall } from './icons/defectSlideSmall.svg'
import { ReactComponent as deleteIcon } from './icons/delete.svg'
import { ReactComponent as deleteFrame } from './icons/deleteFrame.svg'
import { ReactComponent as deleteRed } from './icons/deleteRed.svg'
import { ReactComponent as deleteSmall2 } from './icons/deleteSmall2.svg'
import { ReactComponent as dislike } from './icons/dislike.svg'
import { ReactComponent as dislikeFill } from './icons/dislikeFill.svg'
import { ReactComponent as done } from './icons/done.svg'
import { ReactComponent as dot } from './icons/dot.svg'
import { ReactComponent as dots } from './icons/dots.svg'
import { ReactComponent as downloadSmall } from './icons/downloadSmall.svg'
import { ReactComponent as drag } from './icons/drag.svg'
import { ReactComponent as drawArrow } from './icons/drawArrow.svg'
import { ReactComponent as drawPen } from './icons/drawPen.svg'
import { ReactComponent as dropDown } from './icons/dropDown.svg'
import { ReactComponent as edit } from './icons/edit.svg'
import { ReactComponent as edit16 } from './icons/edit16.svg'
import { ReactComponent as editSmall } from './icons/editSmall.svg'
import { ReactComponent as editSmall2 } from './icons/editSmall2.svg'
import { ReactComponent as exclamationMark } from './icons/exclamationMark.svg'
import { ReactComponent as exclude } from './icons/exclude.svg'
import { ReactComponent as expand } from './icons/expand.svg'
import { ReactComponent as eye } from './icons/eye.svg'
import { ReactComponent as eyeClose } from './icons/eyeClose.svg'
import { ReactComponent as falseDefectSlideSmall } from './icons/falseDefectSlideSmall.svg'
import { ReactComponent as fastMove } from './icons/fastMove.svg'
import { ReactComponent as favorites } from './icons/favorites.svg'
import { ReactComponent as feedBack } from './icons/feedBack.svg'
import { ReactComponent as filterSmall } from './icons/filterSmall.svg'
import { ReactComponent as flip } from './icons/flip.svg'
import { ReactComponent as flipHorizontally } from './icons/flipHorizontally.svg'
import { ReactComponent as flipVertically } from './icons/flipVertically.svg'
import { ReactComponent as folder } from './icons/folder.svg'
import { ReactComponent as fullScreen } from './icons/fullScreen.svg'
import { ReactComponent as fullScreenExit } from './icons/fullScreenExit.svg'
import { ReactComponent as glassPreview } from './icons/glassPreview.svg'
import { ReactComponent as googleChrome } from './icons/googleChrome.svg'
import { ReactComponent as goTo } from './icons/goTo.svg'
import { ReactComponent as guide } from './icons/guide.svg'
import { ReactComponent as headerFilter } from './icons/headerFilter.svg'
import { ReactComponent as headerSelectedFilter } from './icons/headerSelectedFilter.svg'
import { ReactComponent as help } from './icons/help.svg'
import { ReactComponent as helpSmall } from './icons/helpSmall.svg'
import { ReactComponent as history } from './icons/history.svg'
import { ReactComponent as historySmall } from './icons/historySmall.svg'
import { ReactComponent as imageList } from './icons/imageList.svg'
import { ReactComponent as imagesSmall } from './icons/imagesSmall.svg'
import { ReactComponent as inbox } from './icons/inbox.svg'
import { ReactComponent as info } from './icons/info.svg'
import { ReactComponent as infoSmall } from './icons/infoSmall.svg'
import { ReactComponent as inside } from './icons/inside.svg'
import { ReactComponent as intersect } from './icons/intersect.svg'
import { ReactComponent as issueDate } from './icons/issueDate.svg'
import { ReactComponent as jpeg } from './icons/jpeg.svg'
import { ReactComponent as keyboard } from './icons/keyboard.svg'
import { ReactComponent as ki67 } from './icons/ki67.svg'
import { ReactComponent as ki67_green_dot } from './icons/ki67_green_dot.svg'
import { ReactComponent as ki67_red_plus } from './icons/ki67_red_plus.svg'
import { ReactComponent as ki67_white_dot } from './icons/ki67_white_dot.svg'
import { ReactComponent as ki67cross } from './icons/ki67cross.svg'
import { ReactComponent as ki67rectangle } from './icons/ki67rectangle.svg'
import { ReactComponent as label } from './icons/label.svg'
import { ReactComponent as language } from './icons/language.svg'
import { ReactComponent as languageSmall } from './icons/languageSmall.svg'
import { ReactComponent as layers } from './icons/layers.svg'
import { ReactComponent as leftSideBar } from './icons/leftSideBar.svg'
import { ReactComponent as like } from './icons/like.svg'
import { ReactComponent as likeFill } from './icons/likeFill.svg'
import { ReactComponent as link } from './icons/link.svg'
import { ReactComponent as linkSmall } from './icons/linkSmall.svg'
import { ReactComponent as list } from './icons/list.svg'
import { ReactComponent as logoutSmall } from './icons/logoutSmall.svg'
import { ReactComponent as loupe } from './icons/loupe.svg'
import { ReactComponent as mail } from './icons/mail.svg'
import { ReactComponent as management } from './icons/management.svg'
import { ReactComponent as manufacturer } from './icons/manufacturer.svg'
import { ReactComponent as map } from './icons/map.svg'
import { ReactComponent as marking } from './icons/marking.svg'
import { ReactComponent as markingSmall } from './icons/markingSmall.svg'
import { ReactComponent as mdt } from './icons/mdt.svg'
import { ReactComponent as minimizeSmall } from './icons/minimizeSmall.svg'
import { ReactComponent as mitosis } from './icons/mitosis.svg'
import { ReactComponent as mitosisAnnotation } from './icons/mitosis_annotation.svg'
import { ReactComponent as mitosis_blue_circle } from './icons/mitosis_blue_circle.svg'
import { ReactComponent as mitosis_green_circle } from './icons/mitosis_green_circle.svg'
import { ReactComponent as moon } from './icons/moon.svg'
import { ReactComponent as more } from './icons/more.svg'
import { ReactComponent as mouseCenter } from './icons/mouseCenter.svg'
import { ReactComponent as mouseCenterLight } from './icons/mouseCenterLight.svg'
import { ReactComponent as mouseLeft } from './icons/mouseLeft.svg'
import { ReactComponent as mouseLeftLight } from './icons/mouseLeftLight.svg'
import { ReactComponent as mouseRight } from './icons/mouseRight.svg'
import { ReactComponent as mouseRightLight } from './icons/mouseRightLight.svg'
import { ReactComponent as multipolygon } from './icons/multipolygon.svg'
import { ReactComponent as navigateLeft } from './icons/navigateLeft.svg'
import { ReactComponent as navigateLeftDouble } from './icons/navigateLeftDouble.svg'
import { ReactComponent as navigateRight } from './icons/navigateRight.svg'
import { ReactComponent as navigateRightDouble } from './icons/navigateRightDouble.svg'
import { ReactComponent as newCase } from './icons/newCase.svg'
import { ReactComponent as newCuts } from './icons/newCuts.svg'
import { ReactComponent as newSerialCutsIcon } from './icons/newSerialCutsIcon.svg'
import { ReactComponent as newSlideSmall } from './icons/newSlideSmall.svg'
import { ReactComponent as newStain } from './icons/newStain.svg'
import { ReactComponent as newWindow } from './icons/newWindow.svg'
import { ReactComponent as newWindowBold } from './icons/newWindowBold.svg'
import { ReactComponent as notAttention } from './icons/notAttention.svg'
import { ReactComponent as notification } from './icons/notification.svg'
import { ReactComponent as notStar } from './icons/notStar.svg'
import { ReactComponent as numberDown } from './icons/numberDown.svg'
import { ReactComponent as numberDownFilled } from './icons/numberDownFilled.svg'
import { ReactComponent as numberUp } from './icons/numberUp.svg'
import { ReactComponent as numberUpFilled } from './icons/numberUpFilled.svg'
import { ReactComponent as objects } from './icons/objects.svg'
import { ReactComponent as overview } from './icons/overview.svg'
import { ReactComponent as password } from './icons/password.svg'
import { ReactComponent as pause } from './icons/pause.svg'
import { ReactComponent as paused } from './icons/paused.svg'
import { ReactComponent as pdf } from './icons/pdf.svg'
import { ReactComponent as pdfPreview } from './icons/pdfPreview.svg'
import { ReactComponent as pen } from './icons/pen.svg'
import { ReactComponent as person } from './icons/person.svg'
import { ReactComponent as pointer } from './icons/pointer.svg'
import { ReactComponent as pointer2 } from './icons/pointer2.svg'
import { ReactComponent as preview } from './icons/preview.svg'
import { ReactComponent as printScreen } from './icons/printScreen.svg'
import { ReactComponent as questionCircle } from './icons/questionCircle.svg'
import { ReactComponent as questionCircleSolid } from './icons/questionCircleSolid.svg'
import { ReactComponent as rcInfo } from './icons/rcInfo.svg'
import { ReactComponent as rectangle } from './icons/rectangle.svg'
import { ReactComponent as refuse } from './icons/refuse.svg'
import { ReactComponent as reject } from './icons/reject.svg'
import { ReactComponent as releaseVersion } from './icons/releaseVersion.svg'
import { ReactComponent as relink } from './icons/relink.svg'
import { ReactComponent as reportSmall } from './icons/reportSmall.svg'
import { ReactComponent as rescan } from './icons/rescan.svg'
import { ReactComponent as resize } from './icons/resize.svg'
import { ReactComponent as resizeVertical } from './icons/resizeVertical.svg'
import { ReactComponent as rightSideBar } from './icons/rightSideBar.svg'
import { ReactComponent as rotate } from './icons/rotate.svg'
import { ReactComponent as rotate90AllSmall } from './icons/rotate90AllSmall.svg'
import { ReactComponent as rotate90Left } from './icons/rotate90Left.svg'
import { ReactComponent as rotate90Right } from './icons/rotate90Right.svg'
import { ReactComponent as rotate90Small } from './icons/rotate90Small.svg'
import { ReactComponent as ruler } from './icons/ruler.svg'
import { ReactComponent as sadEmoji } from './icons/sadEmoji.svg'
import { ReactComponent as saveSmall } from './icons/saveSmall.svg'
import { ReactComponent as screenRecord } from './icons/screenRecord.svg'
import { ReactComponent as screenRecordInProgress } from './icons/screenRecordInProgress.svg'
import { ReactComponent as search } from './icons/search.svg'
import { ReactComponent as searchAtlas } from './icons/searchAtlas.svg'
import { ReactComponent as searchCase } from './icons/searchCase.svg'
import { ReactComponent as sectionIsClose } from './icons/sectionIsClose.svg'
import { ReactComponent as sectionIsOpen } from './icons/sectionIsOpen.svg'
import { ReactComponent as share } from './icons/share.svg'
import { ReactComponent as share16 } from './icons/share16.svg'
import { ReactComponent as shareSmall } from './icons/shareSmall.svg'
import { ReactComponent as shareSmallCircle } from './icons/shareSmallCircle.svg'
import { ReactComponent as signed } from './icons/signed.svg'
import { ReactComponent as similar } from './icons/similar.svg'
import { ReactComponent as simplification } from './icons/simplification.svg'
import { ReactComponent as slidePlaceholder } from './icons/slidePlaceholder.svg'
import { ReactComponent as smallTile } from './icons/smallTile.svg'
import { ReactComponent as smallTiles } from './icons/smallTiles.svg'
import { ReactComponent as splitAdd } from './icons/splitAdd.svg'
import { ReactComponent as splitSmall } from './icons/splitSmall.svg'
import { ReactComponent as star16 } from './icons/star16.svg'
import { ReactComponent as stats } from './icons/stats.svg'
import { ReactComponent as subtact } from './icons/subtact.svg'
import { ReactComponent as sum } from './icons/sum.svg'
import { ReactComponent as summary } from './icons/summary.svg'
import { ReactComponent as sumpCard } from './icons/sumpCard.svg'
import { ReactComponent as sumpTable } from './icons/sumpTable.svg'
import { ReactComponent as sun } from './icons/sun.svg'
import { ReactComponent as support } from './icons/support.svg'
import { ReactComponent as supportChat } from './icons/supportChat.svg'
import { ReactComponent as tasks } from './icons/tasks.svg'
import { ReactComponent as text } from './icons/text.svg'
import { ReactComponent as textArea } from './icons/textArea.svg'
import { ReactComponent as thumb } from './icons/thumb.svg'
import { ReactComponent as tools } from './icons/tools.svg'
import { ReactComponent as toolsAI } from './icons/toolsAI.svg'
import { ReactComponent as uncito } from './icons/uncito.svg'
import { ReactComponent as undoSmall } from './icons/undoSmall.svg'
import { ReactComponent as union } from './icons/union.svg'
import { ReactComponent as unsaveSmall } from './icons/unsaveSmall.svg'
import { ReactComponent as upload } from './icons/upload.svg'
import { ReactComponent as verticalSeparator } from './icons/verticalSeparator.svg'
import { ReactComponent as yandexBrowser } from './icons/yandexBrowser.svg'
import { ReactComponent as zoom } from './icons/zoom.svg'

const icons = {
  Case,
  actionArrow,
  add,
  addExam,
  addFrame,
  addImageSmall,
  addNote,
  aiMistakeSmall,
  aiSmall,
  alert,
  angle,
  annotations,
  annotationsList,
  archive,
  archive16,
  archiveSmall,
  archiveSmall2,
  arrowBack,
  arrowLeftSmall,
  arrowReturn,
  arrowRight,
  arrowRightSmall,
  atlas,
  attention,
  bigTile,
  bigTiles,
  bin16,
  brightness,
  calendar,
  cameraSight,
  caseLink,
  checkSmall,
  checkSmall2,
  checkmark,
  circle,
  cito,
  clock,
  colorCorrection,
  comment,
  commentSmall,
  contrast,
  copySmall,
  coregDropDown,
  coregError,
  counter,
  cross,
  cross16,
  defectSlideSmall,
  delete: deleteIcon,
  deleteFrame,
  deleteRed,
  deleteSmall2,
  dislike,
  dislikeFill,
  done,
  dot,
  dots,
  downloadSmall,
  drag,
  drawArrow,
  minimizeSmall,
  drawPen,
  dropDown,
  filterSmall,
  edit,
  edit16,
  editSmall,
  editSmall2,
  exclamationMark,
  exclude,
  expand,
  eye,
  newWindowBold,
  eyeClose,
  falseDefectSlideSmall,
  fastMove,
  favorites,
  feedBack,
  flip,
  flipHorizontally,
  flipVertically,
  folder,
  fullScreen,
  fullScreenExit,
  glassPreview,
  goTo,
  googleChrome,
  guide,
  headerFilter,
  headerSelectedFilter,
  help,
  helpSmall,
  history,
  historySmall,
  imageList,
  imagesSmall,
  inbox,
  info,
  infoSmall,
  inside,
  intersect,
  issueDate,
  jpeg,
  keyboard,
  ki67,
  ki67_green_dot,
  ki67_red_plus,
  ki67_white_dot,
  ki67cross,
  ki67rectangle,
  label,
  language,
  languageSmall,
  layers,
  leftSideBar,
  like,
  likeFill,
  link,
  linkSmall,
  list,
  logoutSmall,
  loupe,
  mail,
  management,
  manufacturer,
  map,
  marking,
  markingSmall,
  mdt,
  mitosis,
  mitosisAnnotation,
  mitosis_blue_circle,
  mitosis_green_circle,
  moon,
  more,
  mouseCenter,
  mouseCenterLight,
  mouseLeft,
  mouseLeftLight,
  mouseRight,
  mouseRightLight,
  multipolygon,
  navigateLeft,
  navigateLeftDouble,
  navigateRight,
  navigateRightDouble,
  newCase,
  newCuts,
  newSerialCutsIcon,
  newSlideSmall,
  newStain,
  newWindow,
  notAttention,
  notStar,
  notification,
  numberDown,
  numberDownFilled,
  numberUp,
  numberUpFilled,
  objects,
  one_mm,
  overview,
  password,
  pause,
  paused,
  pdf,
  pdfPreview,
  pen,
  person,
  pointer,
  pointer2,
  preview,
  printScreen,
  questionCircle,
  questionCircleSolid,
  rcInfo,
  rectangle,
  refuse,
  reject,
  releaseVersion,
  relink,
  reportSmall,
  rescan,
  resize,
  resizeVertical,
  rightSideBar,
  rotate,
  rotate90AllSmall,
  rotate90Left,
  rotate90Right,
  rotate90Small,
  ruler,
  sadEmoji,
  saveSmall,
  screenRecord,
  screenRecordInProgress,
  search,
  searchAtlas,
  searchCase,
  sectionIsClose,
  sectionIsOpen,
  share,
  share16,
  shareSmall,
  shareSmallCircle,
  signed,
  similar,
  simplification,
  slidePlaceholder,
  smallTile,
  smallTiles,
  splitAdd,
  splitSmall,
  star16,
  stats,
  subtact,
  sum,
  summary,
  sumpCard,
  sumpTable,
  sun,
  support,
  supportChat,
  tasks,
  text,
  textArea,
  thumb,
  tools,
  toolsAI,
  two_mm,
  uncito,
  undoSmall,
  union,
  unsaveSmall,
  upload,
  verticalSeparator,
  yandexBrowser,
  zoom,
}

export default icons
