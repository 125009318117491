import 'antd/es/date-picker/style/index'

import { Badge, Dropdown, Menu } from 'antd'
import { CheckboxChangeEvent } from 'antd/es/checkbox'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import icons from 'assets'
import { parseISO } from 'date-fns'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import CasesTableSearch from 'features/cases-management/ui/filters/CasesTableSearch'
import { FilterBubble } from 'features/cases-management/ui/filters/FilterBubble'
import { EUploadedFileFilterType } from 'features/uploaded-file/lib/common'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import { selectDefectsViewerUrlCaseId } from 'pages/viewer/model/viewerPageSlice'
import React, { FC, useCallback, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getDatePickerLocale } from 'shared/lib/date/getDatePickerLocale'
import i18next from 'shared/lib/i18n/i18n'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { DateRangePicker } from 'shared/ui/DatePicker'
import { AvatarElement, IconElement } from 'shared/ui/kit'
import { IActiveFilter, ISubMenu, ISubMenuItem, ISubMenuTitleProps } from 'shared/ui/table/lib/common'
import { DropdownFilterButton } from 'shared/ui/table/ui/DropdownFilterButton'
import styled from 'styled-components'

export const t = i18next.t

type TProps = {
  handleDataRangeChange: (
    dates: [Date | null, Date | null] | null,
    dateStrings: [string, string],
    filterType?: EUploadedFileFilterType,
  ) => void
  menu: (null | JSX.Element)[]
  selectedFilters: IActiveFilter[]
  handleRemoveFilter: (subMenuKey: React.Key) => void
  dataMenuConfig: ISubMenu[]
  handleFilterChange: (item: ISubMenuItem, subMenu: ISubMenu) => (e: CheckboxChangeEvent) => void
  setSubMenuKeyFilter: (key: string) => void
  /** Доступность кнопки фильтра */
  disabled?: boolean
  /** Функциоя обработки поиска */
  handleTableSearch?: (event: React.ChangeEvent<HTMLInputElement>) => void
  /** Настройка, при включении которой игнорируется видимость фильтра от параметры caseRouting  */
  ignoreCaseRouting?: boolean
}

export const TableFilter: FC<TProps> = ({
  dataMenuConfig,
  disabled,
  handleDataRangeChange,
  handleFilterChange,
  handleRemoveFilter,
  ignoreCaseRouting,
  menu,
  selectedFilters,
  setSubMenuKeyFilter,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false)
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { defectsTableFiltersWidth, isFiltersPicked, setDefectsTableFiltersWidth, setIsFiltersPicked, tableQuery } =
    useCaseManagementContext()
  const isDefectsViewer = !!useSelector(selectDefectsViewerUrlCaseId)
  const { menuTab } = useCaseManagementRouteParam()
  const wrapperRef = useRef<HTMLDivElement>(null)

  const checkFilterFit = () => {
    if (wrapperRef.current) {
      const children = Array.from(wrapperRef.current.children)
      const width = children.reduce((sum, child) => {
        const rect = child.getBoundingClientRect()
        return sum + rect.width
      }, 0)

      if (defectsTableFiltersWidth !== width) {
        setDefectsTableFiltersWidth(width)
      }
    }
  }

  useEffect(() => {
    checkFilterFit()
  }, [])

  useEffect(() => {
    setIsFiltersPicked(!!selectedFilters.length)
    checkFilterFit()
  }, [selectedFilters])

  const isFilterActive = (itemKey: string) =>
    selectedFilters.some((filter) => filter.activeFilters.some((activeFilter) => activeFilter.key === itemKey))

  const handleVisibleDropdown = useCallback(() => {
    setIsDropdownOpen((prev) => !prev)
    setSubMenuKeyFilter('')
  }, [])

  return ignoreCaseRouting || isCaseRouting ? (
    <DropdownWrapper menuTab={menuTab} ref={wrapperRef} isDefectsViewer={isDefectsViewer}>
      <FilterWrapper>
        <FilterDropdown
          disabled={disabled}
          isDefectsViewer={isDefectsViewer}
          isFiltersPicked={isFiltersPicked}
          menu={menu}
          isDropdownOpen={isDropdownOpen}
          isActiveFilter={!!selectedFilters.length}
          handleVisibleDropdown={handleVisibleDropdown}
        />
        {!isDefectsViewer &&
          selectedFilters.map((filter) => (
            <FilterBubble
              menuConfig={dataMenuConfig}
              key={filter.key}
              activeFilter={filter}
              handleRemoveFilter={handleRemoveFilter}
              handleFilterChange={handleFilterChange}
              isFilterActive={isFilterActive}
              handleDataRangeChange={handleDataRangeChange}
            />
          ))}
      </FilterWrapper>
      {menuTab === ECaseTableType.DEFECTS && !isDefectsViewer && <CasesTableSearch query={tableQuery} />}
    </DropdownWrapper>
  ) : null
}

export const SubMenuTitle: React.FC<ISubMenuTitleProps> = ({ isActive, isDefectsViewer, title }) => (
  /** Отдельный комопнент для заголовка саб меню, чтоб изменить стандартные иконки Antd на кастомные, по дизайну */
  <div style={{ justifyContent: 'space-between' }} className="sub-menu-title">
    <span>{title}</span>
    <div style={{ alignItems: 'center', display: 'flex' }}>
      {isActive && isDefectsViewer && <StyledBadge subMenu={true} color="var(--color-purple-light)" dot={true} />}
      <icons.sectionIsClose />
    </div>
  </div>
)

const FilterDropdown: FC<{
  menu: (null | JSX.Element)[]
  isFiltersPicked: boolean
  isDropdownOpen: boolean
  /** открыт ли вьювер дефекта */
  isDefectsViewer: boolean
  /** Доступность кнопки фильтра */
  disabled?: boolean
  /** Активны ли фильтры */
  isActiveFilter: boolean
  handleVisibleDropdown: () => void
}> = ({ disabled, handleVisibleDropdown, isActiveFilter, isDefectsViewer, isDropdownOpen, isFiltersPicked, menu }) => (
  <Dropdown
    overlay={<StyledDropdown triggerSubMenuAction="click">{menu}</StyledDropdown>}
    placement="bottomRight"
    visible={isDropdownOpen}
    onVisibleChange={handleVisibleDropdown}
    trigger={['click']}
    disabled={disabled}
  >
    <DropdownFilterButton
      isDefectsViewer={isDefectsViewer}
      data-testid="table-filter-button"
      isOpen={isDropdownOpen}
      isFiltersPicked={isFiltersPicked}
    >
      <FilterButton isDefectsViewer={isDefectsViewer} isFiltersPicked={isFiltersPicked || isActiveFilter} />
      {isFiltersPicked || isDefectsViewer ? ' ' : t('Фильтр')}
    </DropdownFilterButton>
  </Dropdown>
)

export const DateMenuItem: FC<{
  valueFrom?: string | null
  valueTo?: string | null
  open: boolean
  handleDataRangeChange: (
    dates: [Date | null, Date | null] | null,
    dateStrings: [string, string],
    filterType?: EUploadedFileFilterType,
  ) => void
}> = ({ handleDataRangeChange, open, valueFrom, valueTo }) => {
  const { i18n } = useTranslation()
  const dateLocale = getDatePickerLocale(i18n.language)
  const CALENDAR_WIDTH = 496
  return (
    <Menu.Item style={{ background: 'none', padding: 0, width: CALENDAR_WIDTH }}>
      <div onClick={(e) => e.stopPropagation()}>
        <DateRangePicker
          style={{ top: -15 }}
          locale={dateLocale}
          open={open}
          onChange={handleDataRangeChange}
          value={[valueFrom ? parseISO(valueFrom) : null, valueTo ? parseISO(valueTo) : null]}
          getPopupContainer={(trigger) => {
            if (trigger?.parentNode && trigger?.parentNode instanceof HTMLElement) {
              return trigger?.parentNode
            }
            return document.body
          }}
        />
      </div>
    </Menu.Item>
  )
}

const FilterButton = ({ isDefectsViewer, isFiltersPicked }: { isDefectsViewer: boolean; isFiltersPicked: boolean }) => {
  if (isDefectsViewer && isFiltersPicked) {
    return (
      <StyledBadge color="var(--color-purple-light)" dot={true}>
        <IconElement size="md" fill="var(--color-text-3)" name="filterSmall" />
      </StyledBadge>
    )
  }

  if (isDefectsViewer) {
    return <IconElement size="md" fill="var(--color-text-3)" name="filterSmall" />
  }

  return <IconElement size="lg" fill="var(--color-text-1)" name="headerFilter" />
}

const StyledBadge = styled(Badge)<{ subMenu?: boolean }>`
  box-shadow: none;
  height: ${({ subMenu }) => (subMenu ? '6px' : '16px')};
  border: none;
  .ant-badge-dot {
    box-shadow: none;
  }
  .ant-badge-status-dot {
    position: ${({ subMenu }) => subMenu && 'static'};
    display: ${({ subMenu }) => subMenu && 'flex'};
  }
`

export const ContainerSubmenu = styled.div`
  align-items: center;
  display: flex;
  width: 216px;
`

export const FilterWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row-reverse;
  row-gap: 8px;
`

export const LabelContainer = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`

export const UserAvatar = styled(AvatarElement)`
  vertical-align: middle;
  margin: 0 8px;
  flex-shrink: 0;
`

const DropdownWrapper = styled.div<{ menuTab: ECaseTableType; isDefectsViewer: boolean }>`
  display: flex;
  gap: 8px;
  padding: ${({ isDefectsViewer, menuTab }) =>
    menuTab === ECaseTableType.DEFECTS && !isDefectsViewer ? '8px 16px' : '0'};
  flex-direction: row;
  justify-content: flex-end;
  background: ${({ isDefectsViewer }) => !isDefectsViewer && 'var(--color-bg-1)'};
`

export const StyledDropdown = styled(Menu)`
  position: relative;

  width: 236px;
  opacity: 1;

  ${() => modalBackground}
  ${() => modalShadow}

  .ant-dropdown-menu-item {
    align-items: center;
    align-content: center;
  }
  .ant-dropdown-menu-title-content {
    display: flex;
  }

  .ant-dropdown-menu-submenu-expand-icon {
    display: none;
  }
  .ant-dropdown-menu-submenu-title {
    padding-right: 5px;
  }
  .ant-dropdown-menu-submenu-title:hover {
    background-color: var(--color-bg-3);
  }
  .sub-menu-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  .ant-dropdown-menu-submenu-open {
    background-color: var(--color-bg-3) !important;
  }
`
