import LeftPanelWrapper from 'pages/viewer/ui/common/LeftPanelWrapper'
import { ForwardedRef } from 'react'

import DefectsSlidesListContainer from './DefectsSlidesListContainer'

type Props = {
  leftRef: ForwardedRef<HTMLDivElement>
}

const DefectsLeftPanelContainer = ({ leftRef }: Props) => (
  <LeftPanelWrapper leftRef={leftRef}>
    <DefectsSlidesListContainer />
  </LeftPanelWrapper>
)

export default DefectsLeftPanelContainer
