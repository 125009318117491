import { ViewerPageProvider } from 'pages/viewer/lib/common/ViewerPageProvider'
import ViewerContextProvider, { useViewerContext } from 'pages/viewer/ui/ViewerContext'
import { EventBusProvider } from 'shared/lib/EventBus'

type Props = {
  children: any
  slidesIds?: number[]
}

const CommonViewerRouteLayout = ({ children, slidesIds }: Props) => {
  const { setSlideIdsLength } = useViewerContext()

  if (slidesIds) setSlideIdsLength(slidesIds.length)

  return (
    <ViewerContextProvider>
      <ViewerPageProvider>
        <EventBusProvider>{children}</EventBusProvider>
      </ViewerPageProvider>
    </ViewerContextProvider>
  )
}

export default CommonViewerRouteLayout
