import React, { createContext, FC, ReactNode, useContext, useState } from 'react'

type TViewerContext = {
  /** Длинна массива с ID слайдов */
  slideIdsLength: number
  setSlideIdsLength: React.Dispatch<React.SetStateAction<number>>
}

const initialViewerContextState: TViewerContext = {
  setSlideIdsLength: () => {},
  slideIdsLength: 0,
}

const ViewerContext = createContext<TViewerContext>(initialViewerContextState)

export const useViewerContext = () => useContext(ViewerContext)

const ViewerContextProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [slideIdsLength, setSlideIdsLength] = useState(initialViewerContextState.slideIdsLength)

  return (
    <ViewerContext.Provider
      value={{
        setSlideIdsLength,
        slideIdsLength,
      }}
    >
      {children}
    </ViewerContext.Provider>
  )
}

export default ViewerContextProvider
