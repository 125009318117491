import { TCaseManagementFilterParams } from 'features/cases-management/types/TCasePagination'
import { SlideWithDefect, TDefectsFilterParams } from 'features/defects/types/TDefectsPagination'
import { isEmpty } from 'lodash'
import React, { createContext, FC, ReactNode, useContext, useState } from 'react'

/** Количество элементов в странице */
const DEFAULT_PAGE_SIZE = 20
type TCaseManagementContext = {
  /** Фильтры */
  filterParams: TCaseManagementFilterParams | TDefectsFilterParams
  setFilterParams: (filtersParams: TCaseManagementFilterParams) => void
  /** Обновить высоту панели превью */
  setPanelPreviewHeight: React.Dispatch<React.SetStateAction<number>>
  /** Обновить видимость панели превью */
  setPanelPreviewVisible: React.Dispatch<React.SetStateAction<boolean>>
  /** Высота панели превью кейса */
  panelPreviewHeight: number
  /** Видимость панели превью кейса */
  isPanelPreviewVisible: boolean
  isFiltersPicked: boolean
  setIsFiltersPicked: React.Dispatch<React.SetStateAction<boolean>>
  /** Видимое количество строк */
  visibleRowsCount: number
  /** Обновить видимое количество строк */
  setVisibleRowsCount: React.Dispatch<React.SetStateAction<number>>
  /** Запрос для поиска */
  tableQuery: string
  /**Обновить запрос для поиска */
  setTableQuery: React.Dispatch<React.SetStateAction<string>>
  /** Ключи выбранных строк */
  selectedDefectRowKeys: React.Key[]
  setSelectedDefectRowKeys: React.Dispatch<React.SetStateAction<React.Key[]>>
  /** Выбранные слайды с дефектами */
  selectedSlidesWithDefects: SlideWithDefect[]
  setSelectedSlidesWithDefects: React.Dispatch<React.SetStateAction<SlideWithDefect[]>>
  /** Ширина фильтров в таблице Дефекты */
  defectsTableFiltersWidth: number
  setDefectsTableFiltersWidth: React.Dispatch<React.SetStateAction<number>>
}

const initialCaseManagementContextState: TCaseManagementContext = {
  defectsTableFiltersWidth: 0,
  filterParams: {},
  isFiltersPicked: false,
  isPanelPreviewVisible: false,
  panelPreviewHeight: 250,
  selectedDefectRowKeys: [],
  selectedSlidesWithDefects: [],
  setDefectsTableFiltersWidth: () => {},
  setFilterParams: () => {},
  setIsFiltersPicked: () => {},
  setPanelPreviewHeight: () => {},
  setPanelPreviewVisible: () => {},
  setSelectedDefectRowKeys: () => {},
  setSelectedSlidesWithDefects: () => {},
  setTableQuery: () => {},
  setVisibleRowsCount: () => {},
  tableQuery: '',
  visibleRowsCount: 20,
}

const CaseManagementContext = createContext<TCaseManagementContext>(initialCaseManagementContextState)

export const useCaseManagementContext = () => useContext(CaseManagementContext)

const CaseManagementProvider: FC<{ children: ReactNode }> = ({ children }) => {
  const [filterParams, setParams] = useState<TCaseManagementFilterParams>(
    initialCaseManagementContextState.filterParams,
  )
  const setFilterParams = (mewFiltersParams: TCaseManagementFilterParams) => {
    if (isEmpty(mewFiltersParams) && isEmpty(filterParams)) return
    setParams(mewFiltersParams)
  }
  const [panelPreviewHeight, setPanelPreviewHeight] = useState<number>(
    initialCaseManagementContextState.panelPreviewHeight,
  )
  const [isPanelPreviewVisible, setPanelPreviewVisible] = useState<boolean>(
    initialCaseManagementContextState.isPanelPreviewVisible,
  )
  const [visibleRowsCount, setVisibleRowsCount] = useState(DEFAULT_PAGE_SIZE)
  const [isFiltersPicked, setIsFiltersPicked] = useState<boolean>(initialCaseManagementContextState.isFiltersPicked)
  const [tableQuery, setTableQuery] = useState(initialCaseManagementContextState.tableQuery)
  const [selectedDefectRowKeys, setSelectedDefectRowKeys] = useState<React.Key[]>([])
  const [selectedSlidesWithDefects, setSelectedSlidesWithDefects] = useState<SlideWithDefect[]>([])
  const [defectsTableFiltersWidth, setDefectsTableFiltersWidth] = useState(0)

  return (
    <CaseManagementContext.Provider
      value={{
        defectsTableFiltersWidth,
        filterParams,
        isFiltersPicked,
        isPanelPreviewVisible,
        panelPreviewHeight,
        selectedDefectRowKeys,
        selectedSlidesWithDefects,
        setDefectsTableFiltersWidth,
        setFilterParams,
        setIsFiltersPicked,
        setPanelPreviewHeight,
        setPanelPreviewVisible,
        setSelectedDefectRowKeys,
        setSelectedSlidesWithDefects,
        setTableQuery,
        setVisibleRowsCount,
        tableQuery,
        visibleRowsCount,
      }}
    >
      {children}
    </CaseManagementContext.Provider>
  )
}

export default CaseManagementProvider
