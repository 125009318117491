import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from 'app/redux'
import { migrations } from 'app/redux/lib/migrations'
import { createMigrate } from 'redux-persist'
import storage from 'redux-persist/lib/storage'
import IReport from 'types/IReport'

/**
 * Ширина формы создания отчета по умолчанию.
 */
const DEFAULT_REPORT_FORM_WIDTH = 1152

type State = {
  /** Объект, который сопоставляет номера дел с индексами выбранных отчетов. */
  selectedReportIndexByCase: Record<number, number>
  /** Флаг, который отслеживает загрузку справочников. */
  isDictionaryLoading: boolean
  /** Опциональный начальный отчет, используемый в модальном окне создания, который может быть частично заполнен. */
  creationModalInitialReport?: Partial<IReport>
  /**
   * Ширина модального окна создания отчета.
   */
  modalWidth: number
}

const initialState: State = {
  isDictionaryLoading: false,
  modalWidth: DEFAULT_REPORT_FORM_WIDTH,
  selectedReportIndexByCase: {},
}

export const reportsSlice = createSlice({
  initialState,
  name: 'reportsSlice',
  reducers: {
    setInitialReport(state, { payload }: PayloadAction<Partial<IReport> | undefined>) {
      state.creationModalInitialReport = payload
    },
    setReportModalWidth(state, { payload }: PayloadAction<number>) {
      state.modalWidth = payload
    },
    setSelectedReportIndex(state, { payload: { caseId, index } }: PayloadAction<{ caseId: number; index: number }>) {
      state.selectedReportIndexByCase[caseId] = index
    },
    toggleDictionaryLoading(state, { payload }: PayloadAction<boolean>) {
      state.isDictionaryLoading = payload
    },
  },
})

export const selectedReportByCaseSelector = (caseId: number) =>
  createSelector(
    (state: RootState) => state.reports,
    (reports) => reports.selectedReportIndexByCase[caseId] || 0,
  )

export const reportsPersistConfig = {
  blacklist: ['selectedReportIndexByCase', 'isDictionaryLoading', 'creationModalInitialReport'],
  key: 'reports',
  migrate: createMigrate(migrations, { debug: false }),
  storage,
  version: 1,
  whiteList: ['modalWidth'],
}
