import { SlideWithDefect } from 'features/defects/types/TDefectsPagination'
import { useTranslation } from 'react-i18next'
import { declensionWord } from 'shared/lib/text'
import styled from 'styled-components'

const TextWrapper = styled.div`
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const Wrapper = styled.div`
  display: grid;
  gap: 4px;
`

export type OnSlidesMarkdAsViewedNotificationProps = {
  slideIds: SlideWithDefect[]
}

const OnSlidesMarkdAsViewedNotification = ({ slideIds }: OnSlidesMarkdAsViewedNotificationProps) => {
  const { t } = useTranslation()

  return (
    <Wrapper>
      <TextWrapper>
        {slideIds.length === 1
          ? `${t('Слайд')} ${slideIds[0].slide.barcode || slideIds[0].slide.slideMetadata?.commonMetadata?.caption} ${t(
              'перемещен в просмотренные',
            )}`
          : `${slideIds.length} ${declensionWord(slideIds.length, [t('Слайд'), t('Слайда'), t('Слайдов')])} ${t(
              'перемещены в просмотренные',
            )}`}
      </TextWrapper>
    </Wrapper>
  )
}

export default OnSlidesMarkdAsViewedNotification
