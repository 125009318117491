import { Form, FormInstance, FormProps } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import React, { createContext, Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLisModeAsGemotest } from 'shared/lib/workspaces'
import { ValidateMessages } from 'types/Form'
import IReport from 'types/IReport'

import { ReportFormData } from './right/ReportCreationFormRight'

interface ReportCreationContextProps {
  /**
   * Флаг, указывающий наличие ошибок валидации
   */
  hasValidateErrors: boolean
  /**
   * Инстанс формы
   */
  form: FormInstance<ReportFormData>
  /**
   * Обработчик изменения значений полей формы.
   */
  onValuesChange: FormProps<ReportFormData>['onValuesChange']
  /**
   * Опциональное начальное заключение, которое может быть частично заполнено и используется для предзаполнения формы.
   */
  initialReport: Partial<IReport>
  /**
   * Флаг, указывающий видимость модального окна
   */
  isActionModalVisible: boolean
  /**
   * Функция для изменения видимости модального окна
   */
  setIsActionModalVisible: Dispatch<SetStateAction<boolean>>
  /**
   * Флаг, указывающий наличие конфликта
   */
  isConflictError: boolean
  /**
   * Функция для изменения наличия конфликта
   */
  setIsConflictError: Dispatch<SetStateAction<boolean>>
  /**
   * Сообщение об ошибке при сохранении заключения
   */
  saveReportError: string
  /**
   * Функция для изменения сообщения об ошибке при сохранении
   */
  setSaveReportError: Dispatch<SetStateAction<string>>
  /**
   * Сообщение валидации заключения
   */
  validateMessages: ValidateMessages
}

const ReportCreationContext = createContext<ReportCreationContextProps | null>(null)

export const useReportCreationContext = () => {
  const context = useContext(ReportCreationContext)

  if (!context) {
    throw new Error()
  }

  return context
}

const ReportCreationContextProvider: FC = ({ children }) => {
  const isGemotest = useLisModeAsGemotest()
  const [form] = Form.useForm<ReportFormData>()
  const [hasValidateErrors, setHasValidateErrors] = useState<boolean>(false)
  const initialReport = useTypedSelector((state) => state.reports.creationModalInitialReport) ?? {}
  const { t } = useTranslation()

  const [isActionModalVisible, setIsActionModalVisible] = useState<boolean>(false)
  const [isConflictError, setIsConflictError] = useState<boolean>(false)
  const [saveReportError, setSaveReportError] = useState<string>('')

  /**
   * Пользовательские сообщения валидации для полей формы
   */
  const validateMessages = {
    required: t('Поле не должно быть пустым'),
  }

  /**
   * Проверяет поля формы и обновляет состояние ошибок валидации
   * @param {ReportFormData} values - Объект со значениями формы
   * @param {string} values.complexity - Уровень сложности отчета
   * @param {string} values.icd10 - Код МКБ-10
   * @param {string} values.report - Текст отчета
   * @description
   * Проверяет заполнение обязательных полей:
   * - report (отчет)
   * - icd10 (код МКБ-10)
   * - complexity (сложность, только для Gemotest)
   *
   * При наличии пустых обязательных полей устанавливает флаг ошибки валидации
   */
  const validateFormFields = (values: ReportFormData) => {
    const { complexity, icd10, report } = values

    if (!report || !icd10 || (isGemotest && !complexity)) {
      setHasValidateErrors(true)
    } else {
      setHasValidateErrors(false)
    }
  }

  /**
   * Обработчик изменения значений формы
   * @description
   * Проверяет заполнение обязательных полей:
   * - report (отчет)
   * - icd10 (код МКБ-10)
   * - complexity (сложность, только для Gemotest)
   *
   * Запускает валидацию формы при каждом изменении любого поля
   */
  const onValuesChange: FormProps['onValuesChange'] = (changedValues, values) => {
    validateFormFields(values)
  }

  /**
   * Запуск начальной валидации формы
   * @description
   * Проверяет корректность заполнения полей при первом рендере компонента
   */
  useEffect(() => {
    validateFormFields(form.getFieldsValue())
  }, [])

  return (
    <ReportCreationContext.Provider
      value={{
        form,
        hasValidateErrors,
        initialReport,
        isActionModalVisible,
        isConflictError,
        onValuesChange,
        saveReportError,
        setIsActionModalVisible,
        setIsConflictError,
        setSaveReportError,
        validateMessages,
      }}
    >
      {children}
    </ReportCreationContext.Provider>
  )
}

export default ReportCreationContextProvider
