import { Row } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { SentryRoute } from 'app/sentry'
import { ECaseTableType } from 'features/cases-management/types/ECaseTableType'
import CaseManagementContext from 'features/cases-management/ui/CaseManagementContext'
import { OpenNotificationsButton } from 'features/notifications'
import { UserOptionsContainer } from 'features/user-options'
import { useWorkspacesQuery, workspacesSlice } from 'features/workspace'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { AtlasRoute } from 'pages/atlas'
import { CreateRoute } from 'pages/cases/create'
import { SearchCase } from 'pages/cases/list/SearchCase'
import PrimaryLayout from 'pages/cases/ui/PrimaryLayout'
import { TasksRoute } from 'pages/tasks'
import TaskRoute from 'pages/tasks/task/ui/TaskRoute'
import UploadedFileRoute from 'pages/uploaded-file/ui/UploadedFileRoute'
import { selectDefectsViewerUrlCaseId } from 'pages/viewer/model/viewerPageSlice'
import React, { useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Switch, useLocation } from 'react-router-dom'
import { useManagementTab } from 'shared/lib/hooks/useQueryParams'
import { useAvailableMenuTabs, useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { PageHeader } from 'shared/ui/header'
import { Center, IconElement, SpinElement, TooltipElement } from 'shared/ui/kit'
import { MainToolbarButton } from 'shared/ui/kit/ui/ToolbarButton'
import { TCasesManagementTabs } from 'types/TTab'

import { CasesManagementRoute } from './CasesManagementRoute'

/** Возвращает текущий роут в менеджменте */
export const useCaseManagementRouteParam = () => {
  const location = useLocation()
  const isDefectsViewer = !!useSelector(selectDefectsViewerUrlCaseId)
  const menuTab = isDefectsViewer ? ECaseTableType.DEFECTS : (location.pathname.split('/').at(-1) as ECaseTableType)
  const currentTab = useManagementTab<TCasesManagementTabs>()

  return { currentTab, menuTab }
}

const CasesManagementRoutes = () => {
  const dispatch = useDispatch()
  const currentWorkspace = useTypedSelector((state) => state.workspaces.currentWorkspace)
  const { data: workspaces } = useWorkspacesQuery()
  const {
    roles: { isRoleAtlasValidation: isAtlasAvailable, isRoleMarkupUser: isTaskAvailable },
  } = useSettingsAndUserRoles()
  const visibleMenuTabs = useAvailableMenuTabs()

  // needs to got it only from redux cause of proper working external case links
  const workspaceId = useCurrentWorkspaceId()

  if (!currentWorkspace && workspaceId && workspaces) {
    const newWorkspace = workspaces?.find((workspace) => Number(workspace.workspaceId) === workspaceId) || workspaces[0]
    if (newWorkspace !== undefined) {
      dispatch(workspacesSlice.actions.setCurrentWorkspace(newWorkspace))
    }
  }

  const [activeSearch, setActiveSearch] = useState<boolean>(false)

  const { t } = useTranslation()

  const toggleActiveSearch = () => {
    setActiveSearch((prev) => !prev)
  }
  const notificationButton = useRef<HTMLDivElement | null>(null)

  //todo: Контекст переделать нужно потом с хранением стейта фильтров для каждого таба. Сейчас фильтры контролируется родителем.
  return currentWorkspace ? (
    <CaseManagementContext>
      <PrimaryLayout
        header={
          <PageHeader>
            <Row style={{ alignItems: 'center' }}>
              <UserOptionsContainer />

              {activeSearch && <SearchCase setActive={setActiveSearch} active={activeSearch} />}
              <MainToolbarButton
                active={activeSearch}
                onClick={toggleActiveSearch}
                icon={<IconElement name="searchCase" />}
                data-testid="search-case-button"
              />
              <TooltipElement title={t('Уведомления')} placement="bottom">
                <div ref={notificationButton} style={{ margin: '0 4px' }}>
                  <OpenNotificationsButton />
                </div>
              </TooltipElement>
            </Row>
          </PageHeader>
        }
      >
        <Switch>
          <SentryRoute
            exact={true}
            path="/management/:menuTab"
            render={({ match }) => {
              const { menuTab } = match.params

              if (!visibleMenuTabs.includes(menuTab as ECaseTableType))
                return <Redirect to={'/management/' + visibleMenuTabs[0]} />

              return <CasesManagementRoute notificationButton={notificationButton} />
            }}
          />
          <SentryRoute path="/workspace/:workspaceId/create" key="create">
            <CreateRoute />
          </SentryRoute>
          {isAtlasAvailable && (
            <SentryRoute path="/atlas" key="atlas">
              <AtlasRoute />
            </SentryRoute>
          )}
          {isTaskAvailable && (
            <SentryRoute exact={true} path="/tasks" key="tasks">
              <TasksRoute />
            </SentryRoute>
          )}
          {isTaskAvailable && (
            <SentryRoute path="/tasks/:taskId">
              <TaskRoute />
            </SentryRoute>
          )}
          <SentryRoute exact={true} path={`/workspace/` + workspaceId + `/warehouse`} key="sump">
            <UploadedFileRoute />
          </SentryRoute>
          <SentryRoute exact={true} path={`/workspace/` + workspaceId + `/create`} key="create">
            <CreateRoute />
          </SentryRoute>
          <SentryRoute path="/">
            <Redirect to={'/management/' + visibleMenuTabs[0]} />
          </SentryRoute>
        </Switch>
      </PrimaryLayout>
    </CaseManagementContext>
  ) : (
    <Center>
      <SpinElement />
    </Center>
  )
}

export default CasesManagementRoutes
