import { Divider } from 'antd'
import { useTypedSelector } from 'app/redux/lib/selector'
import { modalBackground, modalShadow } from 'app/styled/GlobalStyles'
import { useThemeContext } from 'app/styled/ThemeProvider'
import { useTaskSlideResult } from 'entities/tasks/api/query'
import {
  IAnnotationQuery,
  QueryFlags,
  updateAnnotationsQuery,
  useAnnotationsQuery,
  useChangeAnnotationMutation,
  useDeleteAnnotationMutation,
} from 'features/annotations/api'
import AnnotationsTypePanelContainer from 'features/annotations/ui/AnnotationsTypePanelContainer'
import { notices } from 'features/notices'
import { Shortcut } from 'features/notices/ui/PasteNotification'
import { useViewerIdSlideState } from 'pages/viewer/lib/common/ViewerPageProvider'
import { selectTasksViewerUrlTaskId } from 'pages/viewer/model/viewerPageSlice'
import React, { useMemo, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { useSelector } from 'react-redux'
import { QUERY_TYPE } from 'shared/api'
import { useOS } from 'shared/lib/hooks'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import styled from 'styled-components/macro'
import { IAnnotation } from 'types/IAnnotations'
import { IMarkupClass, IMarkupSlide, IMarkupSlideResult } from 'types/IMarkupSlide'
import { IMarkupTask } from 'types/IMarkupTask'
import TViewerId from 'types/TViewerId'
import { useViewerDispatch, useViewerMainSelector, viewerSlice } from 'viewer/container'
import { useSlideGroupType } from 'viewer/container/model/viewerSlice'
import { useKeyUp } from 'viewer/map/layers/annotations/lib/hooks/useKeyUp'
import { useOnClickOutside } from 'viewer/map/layers/annotations/lib/hooks/useOnClickOutside'
import { AnnotationDescriptionAndAreaControl } from 'viewer/map/layers/annotations/ui/AnnotationDescriptionAndAreaControl'

import { AnnotationClassesBlock } from './AnnotationClassesBlock'

export const StyledContextMenu = styled.div`
  position: fixed;
  ${() => modalBackground}
  ${() => modalShadow}
  border-radius: 5px;
  z-index: 25;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
const StyledHotkeyAction = styled.div`
  color: var(--color-text-1);
  padding: 4px 15px 4px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  line-height: 16px;
  cursor: pointer;

  &:hover {
    background: var(--color-bg-3);
  }
`
const StyledToolPanel = styled.div`
  display: flex;
  padding: 8px 16px 16px 16px;
`

type Props = {
  viewerId: TViewerId
  mppX: number
  openContext: { x: number; y: number } | null
  /** Открытие/закрытие контекстного меню */
  setOpenContext: (position: { x: number; y: number } | null, hoveredAnnotationId?: number) => void
  /** Данные аннотации для контекстного меню */
  annotation?: IAnnotation
  /** Доступность инструмента аннотаций */
  annotationsDisabled: boolean
}

export const ExtendedAnnotationContextMenu = ({
  annotation,
  annotationsDisabled,
  mppX,
  openContext,
  setOpenContext,
  viewerId,
}: Props) => {
  const theme = useThemeContext()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const OS = useOS()
  const taskId = useSelector(selectTasksViewerUrlTaskId)
  const currentUserId = useTypedSelector((state) => state.user.user?.userId)
  const taskData = queryClient.getQueryData<IMarkupTask>([QUERY_TYPE.TASKS, taskId])
  const userData = taskData?.participants?.find((item) => item.userId === currentUserId)
  const isValidateRole = userData?.access === 'VALIDATE'
  const { selectedAnnotationsIds } = useViewerMainSelector(viewerId)
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const currTaskClasses = queryClient.getQueryData<IMarkupClass[]>([QUERY_TYPE.TASKS_CLASSES, taskId])
  const { extendedContextMenuPosition } = useTypedSelector((state) => state.annotations)
  const viewerDispatch = useViewerDispatch(viewerId)
  const hoveredAnnotationId = useTypedSelector((state) => state.annotations.hoveredAnnotationId)
  const hoveredAnnotationIdState = useMemo(() => hoveredAnnotationId, [extendedContextMenuPosition])
  const slideResults = queryClient.getQueryData<IMarkupSlideResult[]>([QUERY_TYPE.TASKS_SLIDE, slideId])
  const currentSlidesArr = queryClient.getQueryData<IMarkupSlide[]>([QUERY_TYPE.TASKS_SLIDES, taskId])
  const currSlide = currentSlidesArr?.find((item) => item.slideId === slideId)
  const { data: useTasksSlideResult } = useTaskSlideResult(
    currSlide?.markupTaskId,
    currSlide?.markupSlideId,
    currSlide?.slideId,
  )
  const { data: annotationsIds } = useAnnotationsQuery(caseId, slideId, slideResults, useTasksSlideResult)
  /** Если аннотация выбрана, то при нажатии ПКМ на пустую область вьювера, должна открываться панель как если бы было нажатие на выбранную аннотацию.
   *  Не относится к нажатию ПКМ на другие аннотации */
  const annotationsForHandler = hoveredAnnotationIdState
    ? [queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, hoveredAnnotationIdState])]
    : selectedAnnotationsIds.length
    ? selectedAnnotationsIds.map((id) => queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, id]))
    : [annotation]
  const { mutate: editAnnotation } = useChangeAnnotationMutation({
    caseId,
    slideId,
  })
  const slideGroupType = useSlideGroupType(viewerId)
  const hoveredAnnotationUserId = annotationsForHandler[0]?.userId
  const { isRoleQualityManager } = useSettingsAndUserRoles()

  /**
   * Аннотация-дефект
   */
  const isDefectAnnotation = !!annotation?.defect
  /**
   * Может ли текущий пользователь удалить аннотацию
   */
  const canUserDeleteAnnotation = isRoleQualityManager && isDefectAnnotation
  /**
   * Редактирование аннотации разрешено владельцу аннотации || валидатору
   */
  const accessCheck = hoveredAnnotationUserId === currentUserId || isValidateRole

  const isMacro = slideGroupType === 'MACRO'
  const MITOSIS = useTypedSelector((state) => state.viewerPage.tools.MITOSIS)
  const ids = annotationsIds?.ids || []
  // Обработка ошибки при удалении аннотации
  const onAnnotationDeleteError = () => {
    queryClient.setQueryData<IAnnotationQuery>([QUERY_TYPE.ANNOTATION, { slideId }], {
      date: new Date(),
      ids,
    })
    notices.error({
      message: t('Ошибка при удалении аннотации'),
    })
  }

  const { mutate: deleteAnnotation } = useDeleteAnnotationMutation(
    {
      caseId,
      currentUserId,
    },
    {
      onError: onAnnotationDeleteError,
      onSuccess: () => {
        queryClient.invalidateQueries([QUERY_TYPE.ANNOTATION, { caseId }])
        !!annotation?.defect && queryClient.invalidateQueries([QUERY_TYPE.SLIDE_GROUPED, caseId])

        setOpenContext(null, hoveredAnnotationId)
      },
    },
  )

  const onDelete = () => {
    const slideAnnotationId = hoveredAnnotationIdState || annotationsForHandler[0]?.slideAnnotationId
    const annotation = queryClient.getQueryData<IAnnotation>([QUERY_TYPE.ANNOTATION, slideAnnotationId])
    if (annotation && slideAnnotationId) {
      deleteAnnotation({
        annotation,
      })
      updateAnnotationsQuery({
        caseId,
        flag: QueryFlags.DEL,
        ids: [slideAnnotationId],
        queryClient,
        slideId,
      })
      viewerDispatch(viewerSlice.actions.setSelectedAnnotationsIds([]))
    }
  }

  const root = useRef<HTMLDivElement | null>(null)
  useKeyUp(13, () => setOpenContext(null, hoveredAnnotationId))
  useOnClickOutside(root, () => setOpenContext(null, hoveredAnnotationId))

  return (
    <StyledContextMenu style={{ left: openContext?.x, top: openContext?.y }} ref={root}>
      <div>
        {annotationsForHandler !== undefined && annotationsForHandler.length > 0 && (
          <AnnotationDescriptionAndAreaControl
            annotation={annotationsForHandler[0]}
            viewerId={viewerId}
            mppX={mppX}
            canUserEditAnnotation={accessCheck}
          />
        )}
      </div>
      <Divider />
      {currTaskClasses && (
        <AnnotationClassesBlock
          annotationsForHandler={annotationsForHandler}
          editAnnotation={editAnnotation}
          viewerId={viewerId}
          currTaskClasses={currTaskClasses}
          accessCheck={accessCheck}
        />
      )}
      {/*TODO временно скрываем по задаче https://notion.so/ONECELL-3455*/}
      {/*{accessCheck && (
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <StyledButton id={'curs'} onClick={() => zIndexHandler(false)}>
            Переместить наверх
          </StyledButton>
          <StyledButton onClick={() => zIndexHandler(true)}>Переместить вниз</StyledButton>
        </div>
      )}
      {accessCheck && <Divider />}*/}
      <div>
        <StyledHotkeyAction onClick={() => viewerDispatch(viewerSlice.actions.setBuffer(hoveredAnnotationIdState))}>
          <div style={{ userSelect: 'none' }}>{t('Копировать')}</div>
          <div style={{ display: 'flex', gap: 6 }}>
            <Shortcut theme={theme.theme}> {OS === 'MacOS' ? 'Cmd' : 'Ctrl'}</Shortcut> +{' '}
            <Shortcut theme={theme.theme}>C</Shortcut>
          </div>
        </StyledHotkeyAction>
        {(accessCheck || canUserDeleteAnnotation) && (
          <StyledHotkeyAction onClick={onDelete}>
            <div style={{ userSelect: 'none' }}>{t('Удалить')}</div>
            <div style={{ display: 'flex', gap: 6 }}>
              <Shortcut theme={theme.theme}>Delete</Shortcut>
            </div>
          </StyledHotkeyAction>
        )}
        <Divider style={{ marginTop: 8 }} />
        <StyledToolPanel>
          <AnnotationsTypePanelContainer
            isMacro={isMacro}
            onTypeSelect={() => setOpenContext(null, hoveredAnnotationId)}
            disabled={annotationsDisabled || MITOSIS.isVisible}
            openContextMenu
          />
        </StyledToolPanel>
      </div>
    </StyledContextMenu>
  )
}
