import { Form } from 'antd'
import { useTypedDispatch } from 'app/redux/lib/selector'
import { checkIntegration } from 'entities/lis-integration'
import { useReportCreationContext } from 'features/reports/ui/report-creation/ReportCreationContext'
import { Inner, MedicalReportFormItem, Outer, StyledTextArea } from 'features/reports/ui/styled'
import { useLisMode } from 'features/workspace/model/workspacesSlice'
import { debounce } from 'lodash'
import { viewerPageSlice } from 'pages/viewer'
import React, { FC, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DictionaryItem } from 'types/IDictionary'

/**
 * Длина строки ввода в TextArea
 */
const TEXT_AREA_LINE_HEIGHT = 20
/**
 * Максимальная высота для автоматического изменения. 8 строк, одна строка 20px
 */
const MAX_TEXTAREA_AUTO_SIZE = 168
/**
 * Длина метрики пикселей в стилях
 */
const STYLE_PIXEL_LENGTH = 2
/**
 * Буффер для обработки ресайза поля TextArea
 */
const RESIZE_DELAY = 300
/**
 * Расстояние между элементами формы
 */
const INNER_GAP = 8

export type ReportFormData = {
  /** Признак блокировки для отмены подписания */
  locked?: boolean
  /** Признак подписи, для отправки в заключения в ЛИС*/
  signed?: boolean
  icd10?: DictionaryItem
  morphologyCode?: DictionaryItem
  report?: string
  comment?: string
  microDescription?: string
  caseMacroDescription?: string
  complexity?: number
}

export type TextAreaSize = {
  /** Ширина поля */
  width: number
  /** Высота поля */
  height: number
}

const ReportCreationFormRight: FC = () => {
  const dispatch = useTypedDispatch()
  const lisMode = useLisMode()
  const [formInnerHeight, setFormInnerHeight] = useState<number>(0)
  const innerFormRef = useRef<HTMLDivElement>(null)
  const { form, onValuesChange, validateMessages } = useReportCreationContext()
  const toggleInputFocus = (payload: boolean) => dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(payload))

  const { t } = useTranslation()

  /** Мемомизированное значение суммы вертикальных паддингов формы  */
  const formInnerPaddingHeight = useMemo(() => {
    const inner = innerFormRef.current
    const innerStyle = inner ? window.getComputedStyle(inner) : null

    return formInnerHeight && innerStyle
      ? Number(innerStyle.getPropertyValue('padding-top').slice(0, -STYLE_PIXEL_LENGTH)) +
          Number(innerStyle.getPropertyValue('padding-bottom').slice(0, -STYLE_PIXEL_LENGTH))
      : 0
  }, [formInnerHeight])

  /** Мемомизированое значение суммы вертикальных марджинов элементов формы */
  const formItemsMarginHeight = useMemo(() => {
    const inner = innerFormRef.current
    const items = inner?.children

    let marginHeight = 0
    if (formInnerHeight && inner && items?.length) {
      for (let i = 0; i < items.length; i++) {
        const itemStyle = inner ? window.getComputedStyle(items[i]) : null

        if (itemStyle) {
          marginHeight +=
            Number(itemStyle.getPropertyValue('margin-top').slice(0, -STYLE_PIXEL_LENGTH)) +
            Number(itemStyle.getPropertyValue('margin-bottom').slice(0, -STYLE_PIXEL_LENGTH))
        }
      }
    }

    return marginHeight
  }, [formInnerHeight])

  /** Обработчик обновления максимальной высоты для каждого элемента формы TextArea */
  const updateTextAreaMaxHeight = () => {
    const inner = innerFormRef.current
    const innerHeight = Number(inner?.clientHeight) - formInnerPaddingHeight
    const items = inner?.children

    let childrenHeight = formItemsMarginHeight
    if (innerHeight > 0 && items?.length) {
      for (let i = 0; i < items.length; i++) {
        childrenHeight += items[i]?.clientHeight
      }

      /*
        Считаем сумму расстояний между элементами формы
       */
      const totalGapHeight = (items.length - 1) * INNER_GAP
      const freeHeight = innerHeight - childrenHeight - totalGapHeight

      for (let i = 0; i < items.length; i++) {
        const textArea = items[i]?.getElementsByTagName('textarea')[0]

        if (textArea) {
          if (freeHeight >= 0) {
            const maxHeight = textArea.clientHeight + freeHeight
            textArea.style.height = `${textArea.clientHeight}px`
            textArea.style.maxHeight = `${maxHeight}px`
          } else {
            const negativeHeight = freeHeight / items.length
            textArea.style.height = `${textArea.clientHeight + negativeHeight}px`
            textArea.style.maxHeight = `${textArea.clientHeight + negativeHeight}px`
          }
        }
      }
    }
  }

  const debouncedUpdateTextAreaMaxHeight = debounce(updateTextAreaMaxHeight, RESIZE_DELAY)

  const getTextAreaMaxHeight = (fieldName: string) => {
    const target = document.getElementById(fieldName)
    return target ? Number(target.style.maxHeight.slice(0, -STYLE_PIXEL_LENGTH)) : 0
  }

  /** Обработчкик события ресайза TextArea */
  const resizeTextArea = (size: TextAreaSize, fieldName: string) => {
    const maxHeight = getTextAreaMaxHeight(fieldName)
    const height = size.height
    localStorage.setItem(`reportForm.${fieldName}.height`, String(height > maxHeight ? maxHeight : height))
    updateTextAreaMaxHeight()
  }

  /** Дебаунс обработчкика ресайза TextArea */
  const debouncedResizeTextArea = debounce(resizeTextArea, RESIZE_DELAY)

  /** Метод получения сохраненной высоты TextArea */
  const getTextAreaLocalStorageHeight = (fieldName: string) => {
    const height = localStorage.getItem(`reportForm.${fieldName}.height`)

    return height ? Number(height) : undefined
  }

  /** Обработчкик события изменения текста TextArea */
  const onChangeTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    const scrollHeight = target.scrollHeight
    const maxHeight = getTextAreaMaxHeight(target.id)

    // ничего не делаем, когда уже достигли максимальной высоты или поле больше 8 строк
    if (height === maxHeight || height > MAX_TEXTAREA_AUTO_SIZE) {
      return
    }

    // увеличиваем поле, когда есть свободное пространтсво
    if (scrollHeight > height) {
      let autoHeight = scrollHeight >= MAX_TEXTAREA_AUTO_SIZE ? MAX_TEXTAREA_AUTO_SIZE : scrollHeight

      if (autoHeight >= maxHeight) {
        autoHeight = maxHeight
      }

      target.style.height = `${autoHeight}px`
    }
    updateTextAreaMaxHeight()
  }

  /** TODO возможно убрать
   * Обработчик события клика на Enter TextArea */
  const onPressEnterTextArea = (e: React.BaseSyntheticEvent) => {
    const target = e.target
    const height = target.offsetHeight
    if (height < MAX_TEXTAREA_AUTO_SIZE) {
      target.style.height = `${height + TEXT_AREA_LINE_HEIGHT}px`
    }
    updateTextAreaMaxHeight()
  }

  useEffect(() => {
    setFormInnerHeight(Number(innerFormRef?.current?.clientHeight))
    debouncedUpdateTextAreaMaxHeight()
  }, [innerFormRef?.current?.clientHeight])

  useEffect(() => {
    toggleInputFocus(true)
    return () => {
      toggleInputFocus(false)
    }
  }, [])

  return (
    <Form
      form={form}
      layout="vertical"
      style={{ maxWidth: '100%', width: '100%' }}
      validateMessages={validateMessages}
      onValuesChange={onValuesChange}
    >
      <Outer>
        <Inner ref={innerFormRef} style={{ gap: INNER_GAP }}>
          <MedicalReportFormItem label={t('Макроописание')} name="caseMacroDescription">
            <StyledTextArea
              style={{ height: getTextAreaLocalStorageHeight('caseMacroDescription') }}
              onResize={(size) => debouncedResizeTextArea(size, 'caseMacroDescription')}
              onChange={onChangeTextArea}
              onPressEnter={onPressEnterTextArea}
            />
          </MedicalReportFormItem>
          <MedicalReportFormItem label={t('Микроописание')} name="microDescription">
            <StyledTextArea
              style={{ height: getTextAreaLocalStorageHeight('microDescription') }}
              onResize={(size) => debouncedResizeTextArea(size, 'microDescription')}
              onChange={onChangeTextArea}
              onPressEnter={onPressEnterTextArea}
            />
          </MedicalReportFormItem>
          <MedicalReportFormItem label={t('Заключение')} name="report" rules={[{ required: true }]}>
            <StyledTextArea
              style={{ height: getTextAreaLocalStorageHeight('report') }}
              onResize={(size) => debouncedResizeTextArea(size, 'report')}
              onChange={onChangeTextArea}
              onPressEnter={onPressEnterTextArea}
            />
          </MedicalReportFormItem>
          <MedicalReportFormItem
            label={
              checkIntegration('report', 'comment', lisMode) ? t('Доп. замечания и рекомендации') : t('Комментарий')
            }
            name="comment"
          >
            <StyledTextArea
              style={{ height: getTextAreaLocalStorageHeight('comment') }}
              onResize={(size) => debouncedResizeTextArea(size, 'comment')}
              onChange={onChangeTextArea}
              onPressEnter={onPressEnterTextArea}
            />
          </MedicalReportFormItem>
        </Inner>
      </Outer>
    </Form>
  )
}

export default ReportCreationFormRight
