import { useThemeContext } from 'app/styled/ThemeProvider'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { formatDateForDisplay } from 'shared/lib/date'
import { TextElement } from 'shared/ui/kit'

import {
  Block,
  Button,
  Dot,
  Item,
  Mark,
  ShowMoreButton,
  Span,
  Status,
  StyledPanel,
  Title,
  TitleSpan,
  TitleWrapper,
} from './AdditionalOrderInfo.styles'
import { AdditionalOrderInfoProps } from './AdditionalOrderInfo.types'
import { ADDITIONAL_ORDER, STATUSES } from './constants'

const COMMENT_PREVIEW_LENGTH = 70

export function AdditionalOrderInfo({ data, handleClick, isVisiblePanel }: AdditionalOrderInfoProps) {
  const { t } = useTranslation()
  const [expandedItems, setExpandedItems] = useState<Record<number, Record<string, boolean>>>({})
  const colorTheme = useThemeContext()

  const toggleExpanded = (id: number, section: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [id]: {
        ...(prev[id] ?? {}),
        [section]: !prev[id]?.[section],
      },
    }))
  }

  return (
    <>
      <Button isFocus={isVisiblePanel} onClick={handleClick}>
        {t('Дозаказы')} <Mark>{data.length}</Mark>
      </Button>
      {isVisiblePanel && (
        <StyledPanel>
          {data.map((item) => {
            const {
              createdAt,
              createdBy,
              defectTypes,
              preparationComment,
              qualityControlComment,
              researchRequestId,
              researchRequestReason,
              slides,
              status,
              type,
            } = item

            const isExpanded = expandedItems[researchRequestId] || {}
            const defects = `${t('Дефект')}: ${defectTypes?.map((item) => t(item.name)).join(', ')}`

            return (
              <Item key={researchRequestId}>
                <Block>
                  <TitleWrapper>
                    <Title>
                      <TitleSpan>{t(ADDITIONAL_ORDER[type])}</TitleSpan>
                      <Span color="grey">{formatDateForDisplay(createdAt)}</Span>
                    </Title>
                    <Status theme={colorTheme.theme} status={status}>
                      {t(STATUSES[status])}
                    </Status>
                  </TitleWrapper>
                  <TextElement ellipsis style={{ color: 'var(--color-text-3)' }}>
                    {createdBy.fullname}
                  </TextElement>
                </Block>

                <Block>
                  {slides.map(({ caseSlideReferenceId, stain }) => (
                    <Span key={caseSlideReferenceId} color="white">
                      {t('Блок')} {caseSlideReferenceId} <Dot /> {stain?.shortName}
                    </Span>
                  ))}
                </Block>

                {preparationComment && (
                  <Block>
                    <Span color="grey">{t('Комментарий для лаборанта')}</Span>
                    <Span color="white">
                      {isExpanded.preparationComment
                        ? preparationComment
                        : `${preparationComment.slice(0, COMMENT_PREVIEW_LENGTH)}`}
                      {preparationComment.length > COMMENT_PREVIEW_LENGTH && (
                        <ShowMoreButton onClick={() => toggleExpanded(researchRequestId, 'preparationComment')}>
                          {t(isExpanded.preparationComment ? 'Скрыть' : 'Показать все')}
                        </ShowMoreButton>
                      )}
                    </Span>
                  </Block>
                )}

                {!!researchRequestReason?.isDefect && (
                  <Block>
                    <Span color="grey">{t('Причина дозаказа')}</Span>
                    <Span color="white">
                      {isExpanded.reason ? defects : `${defects.slice(0, COMMENT_PREVIEW_LENGTH)}`}
                      {defects.length > COMMENT_PREVIEW_LENGTH && (
                        <ShowMoreButton onClick={() => toggleExpanded(researchRequestId, 'reason')}>
                          {t(isExpanded.reason ? 'Скрыть' : 'Показать все')}
                        </ShowMoreButton>
                      )}
                    </Span>
                  </Block>
                )}

                {qualityControlComment && (
                  <Block>
                    <Span color="grey">{t('Комментарий для контроля качества')}</Span>
                    <Span color="white">
                      {isExpanded.qualityControlComment
                        ? qualityControlComment
                        : `${qualityControlComment.slice(0, COMMENT_PREVIEW_LENGTH)}`}
                      {qualityControlComment.length > COMMENT_PREVIEW_LENGTH && (
                        <ShowMoreButton onClick={() => toggleExpanded(researchRequestId, 'qualityControlComment')}>
                          {t(isExpanded.qualityControlComment ? 'Скрыть' : 'Показать все')}
                        </ShowMoreButton>
                      )}
                    </Span>
                  </Block>
                )}
              </Item>
            )
          })}
        </StyledPanel>
      )}
    </>
  )
}
