import { getQueryParams, refetchPages } from 'features/cases-management/lib/helpers'
import { useCaseManagementContext } from 'features/cases-management/ui/CaseManagementContext'
import { useCaseManagementTabContext } from 'features/cases-management/ui/CaseManagementTabContext'
import { useMarkSlidesAsViewedMutation } from 'features/defects/api/query'
import { EDefectsTableType } from 'features/defects/types/TDefectsPagination'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useCaseManagementRouteParam } from 'pages/cases-management/CasesManagementRoutes'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { getServerUrl } from 'shared/api'
import { TOKEN_KEY } from 'shared/lib/common/storageKeys'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { ButtonElement } from 'shared/ui/kit'

const SlideActions: React.FC = () => {
  const wsId = Number(useCurrentWorkspaceId())
  const { currentTab, menuTab } = useCaseManagementRouteParam()
  const {
    filterParams,
    selectedSlidesWithDefects,
    setSelectedDefectRowKeys,
    setSelectedSlidesWithDefects,
    visibleRowsCount,
  } = useCaseManagementContext()
  const { tabsQueryParams } = useCaseManagementTabContext()
  const { isCaseRouting } = useSettingsAndUserRoles()
  const { mutateAsync: markSlidesAsViewed } = useMarkSlidesAsViewedMutation(wsId)
  const token = localStorage.getItem(TOKEN_KEY)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const SERVER_URL = getServerUrl()

  const clearSelection = () => {
    setSelectedDefectRowKeys([])
    setSelectedSlidesWithDefects([])
  }

  const slideIds = selectedSlidesWithDefects.map((row) => row.slide?.slideId)

  const handleMarkAsViewed = async () => {
    await markSlidesAsViewed(slideIds)

    refetchPages(
      queryClient,
      menuTab,
      {
        tab: currentTab,
        ...getQueryParams(tabsQueryParams, menuTab, currentTab, isCaseRouting),
      },
      filterParams,
      wsId,
      visibleRowsCount,
    )
    notices.openOnSlidesMarkedAsViewedNotification(selectedSlidesWithDefects)
    clearSelection()
  }

  const isSlidesSelected = Boolean(selectedSlidesWithDefects.length)

  return (
    <>
      {isSlidesSelected && (
        <form
          target="_blank"
          action={`${SERVER_URL}/workspace/${wsId}/defects/slides/report/html`}
          method="post"
          encType="multipart/form-data"
        >
          <input type="hidden" name="access_token" value={token || ''} />
          {slideIds.map((id, index) => (
            <input key={index} type="hidden" name="slide_ids" value={id} />
          ))}
          <ButtonElement
            onClick={() => {
              clearSelection()
              document.forms[0].submit()
            }}
          >
            {t('Выгрузить информацию о слайдах')}
          </ButtonElement>
        </form>
      )}
      {isSlidesSelected && currentTab === EDefectsTableType.UNVIEWED && (
        <ButtonElement onClick={handleMarkAsViewed}>{t('Отметить как просмотренные')}</ButtonElement>
      )}
    </>
  )
}

export default SlideActions
