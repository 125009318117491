import { Menu } from 'antd'
import { parseISO } from 'date-fns'
import { caseStagesFilters } from 'features/cases-management/ui/filters/filters'
import { DateRangePicker } from 'shared/ui/DatePicker'
import { CheckboxElement } from 'shared/ui/kit'
import { FilterType } from 'shared/ui/table/lib/common'

import { StyledMenu } from './FilterBubble.styles'
import { ISubMenuProps } from './FilterBubble.types'

/** Отступ календаря */
const DATA_RANGE_BOTTOM = 12

export const subMenuDropdown = ({
  currentTab,
  dateRangeFilter,
  handleDataRangeChange,
  handleFilterChange,
  isCaseRouting,
  isDropdownVisible,
  isFilterActive,
  menuConfig,
  subMenuKey,
}: ISubMenuProps) => {
  const subMenu = menuConfig.find((menu) => menu.key === subMenuKey)

  if (!subMenu) return <></>

  if (subMenu.filterType === FilterType.DATA_RANGE) {
    return (
      <DateRangePicker
        open={isDropdownVisible}
        style={{ bottom: DATA_RANGE_BOTTOM }}
        allowClear={false}
        onChange={handleDataRangeChange}
        getPopupContainer={(trigger) => {
          if (trigger?.parentNode && trigger?.parentNode instanceof HTMLElement) {
            return trigger?.parentNode
          }
          return document.body
        }}
        value={[
          dateRangeFilter?.from ? parseISO(dateRangeFilter.from) : null,
          dateRangeFilter?.to ? parseISO(dateRangeFilter.to) : null,
        ]}
      />
    )
  }

  return (
    <StyledMenu className="bubble-button-submenu">
      {subMenuKey === 'stage'
        ? caseStagesFilters({ currentTab, handleFilterChange, isCaseRouting, isFilterActive })
        : subMenu.items?.map((item) => (
            <Menu.Item key={item.key}>
              <CheckboxElement onChange={handleFilterChange(item, subMenu)} checked={isFilterActive(item.key)}>
                {item.label}
              </CheckboxElement>
            </Menu.Item>
          ))}
    </StyledMenu>
  )
}
