import { useSlidesDefects } from 'features/thumbnails/hooks/useSlidesDefects'
import CommonViewerRouteLayout from 'pages/viewer/ui/common/CommonViewerRouteLayout'

import DefectsViewerContainer from './DefectsViewerContainer'

export const DefectsViewerRote = () => {
  const { slidesIds: defectsSlidesIds } = useSlidesDefects()

  return (
    <CommonViewerRouteLayout slidesIds={defectsSlidesIds}>
      <DefectsViewerContainer />
    </CommonViewerRouteLayout>
  )
}
