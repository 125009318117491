import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { useTypedSelector } from 'app/redux/lib/selector'
import { uniq } from 'lodash'
import { VIEWER_ID_PLACEHOLDER } from 'shared/lib/viewer'
import { AnnotationType } from 'types/IAnnotations'
import { ISelectedBbox } from 'types/ISimilarRegion'
import { IGroupType } from 'types/ISlide'
import ISource from 'types/ISource'
import TViewerId from 'types/TViewerId'

type ISegmentationLayer = {
  visible: boolean
}

export type IViewerMode = 'DEFAULT' | 'SEARCH'

export type ColorParams = {
  red?: number
  green?: number
  blue?: number
  gamma?: number
  black?: number
  white?: number
}

/**
 * screenRecord - область подсчёта активная область экрана
 * one_mm - область подсчёта окружность площадью 1 мм2
 * two_mm - область подсчёта окружность площадью 2 мм2
 */
export type ObjectsCountingMethod = 'screenRecord' | 'one_mm' | 'two_mm'

type State = {
  // Core
  slideId: number
  caseId: number
  slideGroupType: IGroupType
  source: ISource
  gridZoomLevels: number[]
  // Segmentation feature
  segmentationLayers: Record<number, ISegmentationLayer>
  segmentationOpacity: number
  // Search feature
  viewerMode: IViewerMode
  gridIsVisible: boolean
  selectedBbox?: ISelectedBbox
  heatmapOpacity: number
  //Annotation feature
  selectedAnnotationsIds: number[]
  isMulti: boolean
  /** Индикатор анимации приближения к выбранной аннотации */
  isAnimatingIntoAnnotation: boolean

  multiPointsActive: boolean
  isMlToolsModalOpen: boolean
  isScreenshotModalOpen: boolean

  isKi67DrawActive: boolean
  selectedKi67Id?: string

  isObjectsDrawActive: boolean
  objectsCountingMethod?: ObjectsCountingMethod
  selectedObjectId?: number

  buffer?: number
  /** Количество непросмотренных слайдов в сайдбаре Вьювера */
  unviewedSlides: number
}

const initialState: State = {
  buffer: undefined,
  caseId: NaN,
  gridIsVisible: false,
  gridZoomLevels: [],
  heatmapOpacity: 80,
  isAnimatingIntoAnnotation: false,
  isKi67DrawActive: false,
  isMlToolsModalOpen: false,
  isMulti: false,
  isObjectsDrawActive: false,
  isScreenshotModalOpen: false,
  multiPointsActive: false,

  segmentationLayers: {},
  segmentationOpacity: 40,
  selectedAnnotationsIds: [],
  selectedKi67Id: undefined,
  selectedObjectId: undefined,
  slideGroupType: 'MICRO',
  slideId: NaN,
  source: 'PLATFORM',
  unviewedSlides: 0,
  viewerMode: 'DEFAULT',
}

export const createViewerSlice = (id?: string) =>
  createSlice({
    extraReducers: {
      'annotationsSlice/setCurrentAnnotation': (state, { payload }: PayloadAction<AnnotationType | undefined>) => {
        if (payload) {
          state.selectedAnnotationsIds = []
        }
      },

      'viewerPageSlice/resetAllViewersState': () => initialState,
    },
    initialState,
    name: id + '-viewerSlice',
    reducers: {
      resetViewer() {
        return initialState
      },
      selectAnnotations(state, { payload }: PayloadAction<number>) {
        if (state.isMulti) {
          state.selectedAnnotationsIds.includes(payload)
            ? (state.selectedAnnotationsIds = uniq([
                ...state.selectedAnnotationsIds.filter((item) => item !== payload),
              ]))
            : (state.selectedAnnotationsIds = uniq([...state.selectedAnnotationsIds, payload]))
        } else {
          state.selectedAnnotationsIds = [payload]
        }
      },
      selectKi67Id(state, { payload }: PayloadAction<string | undefined>) {
        state.selectedKi67Id = payload
      },
      selectObjectId(state, { payload }: PayloadAction<number | undefined>) {
        state.selectedObjectId = payload
      },
      setAnnotationMultiSelect(state, { payload }: PayloadAction<boolean>) {
        state.isMulti = payload
      },
      setBuffer(state, { payload }: PayloadAction<number | undefined>) {
        state.buffer = payload
      },
      setCaseId(state, { payload }: PayloadAction<number>) {
        state.caseId = payload
      },
      setGridIsVisible(state, { payload }: PayloadAction<boolean>) {
        state.gridIsVisible = payload
      },
      setGridZoomLevels(state, { payload }: PayloadAction<number[]>) {
        state.gridZoomLevels = payload
      },
      setHeatmapOpacity(state, { payload }: PayloadAction<number>) {
        state.heatmapOpacity = payload
      },
      setInitialState() {
        return initialState
      },

      setIsAnimatingIntoAnnotation(state, { payload }: PayloadAction<boolean>) {
        state.isAnimatingIntoAnnotation = payload
      },
      setKi67Mode(state, { payload }: PayloadAction<boolean>) {
        state.isKi67DrawActive = payload
      },
      setMlModal(state, { payload }: PayloadAction<boolean>) {
        state.isMlToolsModalOpen = payload
      },
      setMultiPointsActive(state, { payload }: PayloadAction<boolean>) {
        state.multiPointsActive = payload
      },
      setObjectsMode(state, { payload }: PayloadAction<{ active: boolean; countingMethod?: ObjectsCountingMethod }>) {
        state.isObjectsDrawActive = payload.active
        state.objectsCountingMethod = payload.countingMethod || undefined
      },
      setScreenshotModal(state, { payload }: PayloadAction<boolean>) {
        state.isScreenshotModalOpen = payload
      },
      setSegmentationLayer(state, { payload }: PayloadAction<{ info: ISegmentationLayer; classId: number }>) {
        state.segmentationLayers[payload.classId] = payload.info
      },
      setSegmentationLayers(state, { payload }: PayloadAction<Record<number, ISegmentationLayer>>) {
        state.segmentationLayers = payload
      },
      setSegmentationOpacity(state, { payload }: PayloadAction<number>) {
        state.segmentationOpacity = payload
      },
      setSelectedAnnotationsIds(state, { payload }: PayloadAction<number[]>) {
        state.selectedAnnotationsIds = payload
      },
      setSelectedBbox(state, { payload }: PayloadAction<ISelectedBbox | undefined>) {
        state.selectedBbox = payload
      },
      setSlideGroupType(state, { payload }: PayloadAction<IGroupType>) {
        state.slideGroupType = payload
      },
      setSlideId(state, { payload }: PayloadAction<number>) {
        state.slideId = payload
      },
      setSource(state, { payload }: PayloadAction<ISource>) {
        state.source = payload
      },
      setUnviewedSlides(state, { payload }: PayloadAction<number>) {
        state.unviewedSlides = payload
      },
      setViewerMode(state, { payload }: PayloadAction<IViewerMode>) {
        state.viewerMode = payload
      },
    },
  })

export const useSlideGroupType = (viewerId: TViewerId) =>
  useTypedSelector((state) => state.viewers[viewerId].viewer.slideGroupType)

export const createThumbnailLink = (
  defaultLink: string,
  bbox: ISelectedBbox['bbox'],
  isAttachment = false,
  iiif2AuthToken?: string,
) => {
  const coreLink = defaultLink.split('info.json')[0]
  const x1 = bbox.x1 > 0 ? bbox.x1 : 0
  const y1 = bbox.y1 > 0 ? 0 : -bbox.y1
  const y2 = bbox.y2 > 0 ? bbox.y2 : -bbox.y2
  const h = y2 - y1
  const w = bbox.x2 - x1
  const token = iiif2AuthToken ? `?access_token=${iiif2AuthToken}` : ''
  return isAttachment
    ? `${coreLink}${x1},${y2},${w > 0 ? w : -w},${h > 0 ? h : -h}/pct:10/0/default.jpg` + token
    : `${coreLink}${x1},${y1},${w > 0 ? w : -w},${h > 0 ? h : -h}/256,256/0/default.jpg` + token
}

export const viewerSlice = createViewerSlice(VIEWER_ID_PLACEHOLDER)
