import { ESortDir } from 'features/cases-management/types/TCasePagination'
import { TSlideStats } from 'types/ICase'
import { IPatientDTO } from 'types/IPatient'
import ISlide from 'types/ISlide'
import IUser from 'types/IUser'

/** Сортировка */
export enum EDefectsSortBy {
  DEFECT_DATE = 'DEFECT_DATE',
  CASE_ID = 'CASE_ID',
}

export enum EDefectsTableType {
  /** Новые */
  UNVIEWED = 'UNVIEWED',
  /** Просмотренные */
  VIEWED = 'VIEWED',
  /** Все */
  ALL = 'ALL',
}

export type DefectDictionary = {
  /** Уникальный идентификатор записи в словаре дефектов. */
  id: number
  /** Полное название дефекта. */
  name: string
  /** Код, связанный с дефектом. */
  code: string
  /** Короткое название дефекта (необязательное). */
  shortName?: string
}

export type SlideWithDefect = {
  /** Информация о слайде. */
  slide: ISlide
  /** Идентификатор случая, к которому относится слайд. */
  caseId: number
  /** Название случая. */
  caseName: string
  /** Дата подписания медицинского отчета. */
  medicalReportSignedAt: string
  /** Информация о пациенте. */
  patient: IPatientDTO
  /** Тип исследования в рамках случая. */
  caseResearchType: DefectDictionary
  /** Список дефектов слайда. */
  defects: SlideDefect[]
  /** Статистика слайда. */
  slideStats: TSlideStats
}

export type SlideDefect = {
  /** Уникальный идентификатор дефекта слайда. */
  slideDefectId: number
  /** Источник дефекта (необязательное). */
  defectSource?: string
  /** Тип дефекта (необязательное). */
  defectType?: DefectDictionary
  /** Флаг, указывающий, является ли дефект ложноположительным (необязательное). */
  falsePositive?: boolean
  /** Процент ткани, затронутой дефектом (необязательное). */
  tissuePercentage?: number
  /** Пользователь, создавший дефект (необязательное). */
  createdBy?: IUser
  /** Дата создания дефекта (необязательное). */
  createdAt?: string
  /** Комментарий контроля качества. */
  qualityControlComment?: string
}

export type TDefectsQueryParams = {
  /** Направление сортировки (необязательное). */
  sortDir?: ESortDir
  /** Поле для сортировки (необязательное). */
  sortBy?: EDefectsSortBy
}

export type TDefectsFilterParams = {
  /** Тип исследования */
  caseResearchType?: number[]
  /** По дате ОТ */
  defectCreatedFrom?: string
  /** По дате ДО */
  defectCreatedTo?: string
  /** Общий поиск */
  query?: string
  /** Ложная детекция */
  falsePositive?: boolean
  /** Окраска */
  stain?: number
  /** Тип дефекта */
  defectType?: number
}

export enum EDefectsTableIcons {
  MistakeSmall = 'aiMistakeSmall',
  Small = 'aiSmall',
}
