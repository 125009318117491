import icons from 'assets'
import { t } from 'i18next'
import React, { FC } from 'react'

const Urgent: FC = () => (
  <icons.exclamationMark
    data-testid={'management-cito-table-icon'}
    title={t('Срочно') + '!'}
    style={{ flexShrink: 0 }}
  />
)

export default Urgent
