import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { IconElement } from 'shared/ui/kit'
import styled from 'styled-components'

import { roundAreaToTwoDecimals } from './helpers'

const Row = styled.div`
  cursor: pointer;
  padding: 4px;
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 4px;
  }

  &:hover {
    background: var(--color-bg-3);
    border-radius: 5px;
  }

  & > * {
    margin-right: 4px !important;
  }
`
const ColorMarker = styled.div<{ color: string }>`
  flex: 0 0 14px;
  width: 14px;
  height: 14px;
  background: ${({ color }) => color};
  border-radius: 2px;
`

const Text = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: var(--color-text-1);
`

/** Props for ArtefactsClassElement */
type Props = {
  /** Площадь полигона в мм2 */
  area: number
  /** Цвет полигона */
  color: string
  /** Русскоязычное наименование класса */
  title: string
  /** Табличное наименование класса */
  className: string
  /** Коллбэк для события onMouseEnter */
  onMouseEnterHandle: (val: string) => void
  /** Коллбэк для события onMouseLeave */
  onMouseLeaveHandle: () => void
  /** Коллбэк для события onClick */
  onClickHandle: (val: string) => void
}
const ArtefactsClassElement: FC<Props> = ({
  area,
  className,
  color,
  onClickHandle,
  onMouseEnterHandle,
  onMouseLeaveHandle,
  title,
}) => (
  <Row
    onMouseLeave={() => onMouseLeaveHandle()}
    onMouseEnter={() => onMouseEnterHandle(className)}
    onClick={() => onClickHandle(className)}
  >
    <ColorMarker color={color} />
    <Text style={{ flex: '1' }}>{title}</Text>
    <Text>{`${roundAreaToTwoDecimals(area)}%`}</Text>
  </Row>
)

export default ArtefactsClassElement

const StyledSummaryIcon = styled(IconElement)`
  margin-top: 1px;
  fill: none;
  & > path {
    fill: #99989f !important;
  }
`

/** Props for ArtefactsSumElement */
type ArtefactsSumElementProps = {
  /** Суммарная площадь пересечения классов с тканью */
  totalArea: number
}
export const ArtefactsSumElement = ({ totalArea }: ArtefactsSumElementProps) => {
  const { t } = useTranslation()
  return (
    <Row>
      <StyledSummaryIcon name="summary" size="md" />
      <Text style={{ flex: '1' }}>{t('Итого')}</Text>
      <Text>{`${roundAreaToTwoDecimals(totalArea)}%`}</Text>
    </Row>
  )
}
