import React from 'react'
import TViewerId from 'types/TViewerId'

import { useArtefactsContext } from './ArtefactsContext'
import ArtefactsLayer from './ArtefactsLayer'

type Props = {
  /** Id вьювера */
  viewerId: TViewerId
}

const ArtefactsLayerContainer = ({ viewerId }: Props) => {
  const { artefactsPanelEnable } = useArtefactsContext()

  return artefactsPanelEnable ? <ArtefactsLayer viewerId={viewerId} /> : null
}

export default ArtefactsLayerContainer
