import { useTypedSelector } from 'app/redux/lib/selector'
import { push } from 'connected-react-router'
import { TableTabsExtraContent } from 'features/cases-management/ui/cases-table/CasesTableTabs'
import { useDeleteUploadFiles, useMoveToTrash } from 'features/sump'
import { useUploadedFilesCount } from 'features/uploaded-file/api/query'
import { checkUploadedFileTab } from 'features/uploaded-file/lib/common'
import { useTabAction, useUploadedFileQueryParams } from 'features/uploaded-file/lib/hooks'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import i18next from 'i18next'
import { viewerPageSlice } from 'pages/viewer'
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useSettingsAndUserRoles } from 'shared/lib/workspaces'
import { TableTabs } from 'shared/ui/tabs/TableTabs'
import styled from 'styled-components/macro'
import { EUploadedFileTab } from 'types/IUploadedFile'

import FileAttachAction from './actions/FileAttachAction'
import UploadFileTableFilter from './filter/UploadFileTableFilter'
import UploadedFileGrid from './grid/UploadedFileGrid'
import UploadedFileSearchContainer from './search/UploadedFileSearchContainer'
import UploadedFileSlider from './slider/UploadedFileSlider'
import UploadedFileSwitcherContainer from './switcher/UploadedFileSwitcherContainer'
import { useUploadedFileContext } from './table/UploadedFileContext'
import UploadedFileTable from './table/UploadedFileTable'
import { useUploadedFileTabContext } from './UploadedFileTabContext'

const UploadedFileTabs = () => {
  const { t } = useTranslation()
  const { currentTab } = useUploadedFileQueryParams()
  const dispatch = useDispatch()
  const wsId = useCurrentWorkspaceId()
  const { data: counts } = useUploadedFilesCount()
  const { isLisMode } = useSettingsAndUserRoles()
  const { mutate: deleteFiles } = useDeleteUploadFiles()
  const { mutate: moveToTrash } = useMoveToTrash()
  const { resetSelectedRows, selectedFile, selectedFiles, setSelectedFile, setSelectedFiles, setSelectedRowIndex } =
    useUploadedFileTabContext()
  const dupBarcode = useTypedSelector((state) => state.viewerPage.sumpDuplicateBarcodeShowNumber)
  const { isTableMode, searchString } = useUploadedFileContext()
  const tabActionInfo = useTabAction()
  const actionButtonsRef = useRef<HTMLDivElement | null>(null)

  const uploadedFileTabs = useMemo(
    () =>
      [
        {
          label: t('Ожидает'),
          tabType: EUploadedFileTab.AWAITING_CASE,
          visible: isLisMode,
        },
        {
          label: t('Ошибки'),
          tabType: EUploadedFileTab.NOT_ATTACHED_TO_CASE,
          visible: true,
        },
        {
          label: t('Разобрано'),
          tabType: EUploadedFileTab.ATTACHED_TO_CASE,
          visible: true,
        },
        {
          label: t('Корзина'),
          tabType: EUploadedFileTab.DELETED,
          visible: true,
        },
      ]
        .filter((tab) => tab.visible)
        .map(({ tabType, ...rest }, index) => ({
          ...rest,
          count: counts?.[tabType]?.total,
          id: (index + 1).toString(),
          tabType,
        })),
    [counts, i18next.language],
  )

  const activeTabType = currentTab || uploadedFileTabs[0].tabType
  const filters = useMemo(
    () => ({
      query: dupBarcode,
      uploadedFileAttachmentStates: 'DUPLICATE',
      uploadedFileTab: 'NOT_ATTACHED_TO_CASE',
    }),
    [dupBarcode],
  )

  const onTabClick = (key: string) => {
    if (
      [EUploadedFileTab.SEARCH, EUploadedFileTab.DUPLICATE, EUploadedFileTab.SELECTED].includes(key as EUploadedFileTab)
    )
      return
    dispatch(push(`/workspace/${wsId}/warehouse?tab=${key}`))
  }

  const onDelete = useCallback(
    (uploadedFileIds: number[], uploadedFileTab?: EUploadedFileTab) => {
      setSelectedFile(undefined)
      resetSelectedRows()

      const isTabAttachedOrDeleted = [EUploadedFileTab.ATTACHED_TO_CASE, EUploadedFileTab.DELETED]
      const isTabSet = uploadedFileTab ? isTabAttachedOrDeleted.includes(uploadedFileTab) : false
      const isSelectedFileTabSet = checkUploadedFileTab(selectedFiles, isTabAttachedOrDeleted, selectedFile)

      const isDelete = uploadedFileTab ? isTabSet : isSelectedFileTabSet

      // В зависимости от вкладки, выполняем либо удаление, либо перемещение в корзину
      isDelete ? deleteFiles({ uploadedFileIds }) : moveToTrash({ uploadedFileIds })
    },
    [activeTabType, selectedFiles, selectedFile],
  )

  useEffect(() => {
    setSelectedFiles([])
    setSelectedFile(undefined)
    setSelectedRowIndex(null)
  }, [activeTabType])

  useEffect(() => {
    if (activeTabType === currentTab) return
    dispatch(push(`/workspace/${wsId}/warehouse?tab=${activeTabType}`))
  }, [])

  useEffect(() => {
    setSelectedFiles([])
    setSelectedFile(undefined)
  }, [activeTabType])

  useEffect(() => {
    const { resetSumpSearchQuery, setSumpFilters } = viewerPageSlice.actions

    dispatch(
      dupBarcode.length
        ? setSumpFilters({
            filters: {
              ...filters,
              query: dupBarcode,
            },
            key: currentTab,
          })
        : resetSumpSearchQuery({ key: currentTab }),
    )
  }, [dupBarcode])

  /**Ширина табов */
  const [tabsWidth, setTabsWidth] = useState<number>(0)
  /**Ширина блока с кнопками для массовых действий */
  const [actionButtonsWidth, setActionButtonsWidth] = useState<number>(0)
  /**Функция обновления ширины табов */
  const handleTabsWidthChange = (tabsWidth: number) => {
    setTabsWidth(tabsWidth)
  }

  useEffect(() => {
    /**Блок с кнопками для массовых действий */
    const actionButtons = actionButtonsRef.current
    /**Функция одновления ширины блока с кнопками для массовых действий */
    setActionButtonsWidth(actionButtons?.getBoundingClientRect().width || 0)
  }, [tabActionInfo?.extraContent])

  const tabBarExtraContent = (
    <UploadedFileTabsContainer>
      <ActionButtonsWrapper ref={actionButtonsRef} tabsWidth={tabsWidth}>
        {tabActionInfo?.extraContent}
      </ActionButtonsWrapper>
      <TableTabsExtraContent>
        <UploadFileTableFilter />
        {!isTableMode && <UploadedFileSlider />}
        <UploadedFileSwitcherContainer />
        {!dupBarcode.length && <UploadedFileSearchContainer />}
      </TableTabsExtraContent>
    </UploadedFileTabsContainer>
  )

  return (
    <TableTabs
      tabsItems={tabActionInfo?.actionTab ?? uploadedFileTabs}
      onTabClick={onTabClick}
      activeTabType={tabActionInfo?.tabType ?? activeTabType}
      tabBarExtraContent={tabBarExtraContent}
      onTabsWidthChange={handleTabsWidthChange}
      actionButtonsWidth={actionButtonsWidth}
    >
      {(activeTabType !== EUploadedFileTab.DELETED || !!searchString?.length) && (
        <FileAttachAction onDelete={onDelete} />
      )}
      {isTableMode ? <UploadedFileTable /> : <UploadedFileGrid onDelete={onDelete} />}
    </TableTabs>
  )
}

const ActionButtonsWrapper = styled.div<{ tabsWidth: number }>`
  display: flex;
  gap: 4px;
  align-items: flex-end;
  align-self: flex-end;
  height: 100%;
  position: absolute;
  top: -4px;
  left: ${({ tabsWidth }) => `${tabsWidth - 4}px`};
`

const UploadedFileTabsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`

export default UploadedFileTabs
