import { Divider } from 'antd'
import { RESEARCH_REQUEST_TYPE } from 'features/additional-order/api/type'
import { ADDITIONAL_ORDER } from 'features/additional-order/ui/AdditionalOrderInfo/constants'
import { DefectSelectContainer } from 'features/additional-order/ui/DefectSelectContainer'
import { viewerPageSlice } from 'pages/viewer'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { ButtonElement, IconElement, TextAreaElement } from 'shared/ui/kit'

import { ButtonElems, ErrorMessage, Label, LabelTitle, StyledModal, Wrapper } from './ModalWrapper.styles'
import { IModalWrapperProps } from './ModalWrapper.types'

/** Оболочка для модалок по дозаказам  */
export const ModalWrapper = ({
  children,
  error,
  handleComment,
  handleDefectComment,
  handleSubmit,
  isDisabled,
  isError,
  onClose,
  onDefectChange,
  refModal,
  title,
  valueComment,
  valueDefectComment,
}: IModalWrapperProps) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  const setIsAnyInputFocusing = (value: boolean) => {
    dispatch(viewerPageSlice.actions.setIsAnyInputFocusing(value))
  }
  return (
    <StyledModal
      title={title}
      ref={refModal}
      centered
      footer={null}
      destroyOnClose
      closeIcon={<IconElement name="cross16" size="md" onClick={onClose} />}
    >
      <Wrapper>
        {children}
        <Divider />
        <Label>
          <LabelTitle>{t('Комментарий для лаборанта')}</LabelTitle>
          <TextAreaElement
            onFocus={() => setIsAnyInputFocusing(true)}
            onBlur={() => setIsAnyInputFocusing(false)}
            autoSize={{ maxRows: 10, minRows: 3 }}
            maxLength={300}
            value={valueComment}
            onChange={handleComment}
          />
        </Label>
        {isError && <ErrorMessage>{error?.message}</ErrorMessage>}
        {[ADDITIONAL_ORDER[RESEARCH_REQUEST_TYPE.NEW_CUTS], ADDITIONAL_ORDER[RESEARCH_REQUEST_TYPE.RESCAN]].includes(
          title as RESEARCH_REQUEST_TYPE,
        ) && (
          <>
            <DefectSelectContainer
              setIsAnyInputFocusing={setIsAnyInputFocusing}
              valueDefectComment={valueDefectComment}
              handleDefectComment={handleDefectComment}
              onDefectChange={onDefectChange}
            />
            <Divider />
          </>
        )}

        <ButtonElems>
          <ButtonElement onClick={onClose}>{t('Отменить')}</ButtonElement>
          <ButtonElement disabled={isDisabled} type="primary" onClick={handleSubmit}>
            {t('Отправить')}
          </ButtonElement>
        </ButtonElems>
      </Wrapper>
    </StyledModal>
  )
}
//fgh
