import slideService from 'entities/slide/api/service'
import { SlideWithDefect } from 'features/defects/types/TDefectsPagination'
import { notices } from 'features/notices'
import { useCurrentWorkspaceId } from 'features/workspace/lib'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient, UseQueryOptions } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import ISlide from 'types/ISlide'
import { ICaseSpecificMetadata } from 'types/ISlideMetadata'
import ISource from 'types/ISource'

type Payload = {
  caseId?: number
  slideId?: number
  source: ISource
}

export const useSlideCache = (slideId: number) => {
  const queryClient = useQueryClient()
  return queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
}

export const useSlideQuery = (
  { caseId, slideId, source }: Payload,
  queryConfig?: UseQueryOptions<ISlide | undefined, unknown>,
) => {
  const queryClient = useQueryClient()
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const slideDefectsCaseId = queryClient.getQueryData<SlideWithDefect>([QUERY_TYPE.DEFECT, slideId])?.caseId

  const slideCaseId = caseId || slide?.caseId || slideDefectsCaseId

  return useQuery<ISlide | undefined>(
    [QUERY_TYPE.SLIDE, slideId],
    async () => {
      if (slideCaseId && slideId) {
        const res = await slideService.fetchSingleSlide(slideCaseId, slideId, source)

        return res
          ? {
              ...res,
              caseId: slideCaseId,
            }
          : undefined
      }
    },
    {
      staleTime: Infinity,
      ...queryConfig,
    },
  )
}

export const useMutateSlideCaseSpecificMetadata = (caseId: number, slideId: number, source: ISource) => {
  const queryClient = useQueryClient()

  const { data: slide } = useSlideQuery({ slideId, source })

  const mutation = useMutation(
    async (metadata: Partial<ICaseSpecificMetadata>) => {
      if (caseId && slideId) {
        await slideService.updateSingleSlide(caseId, slideId, {
          caseSpecificMetadata: {
            ...slide?.slideMetadata?.caseSpecificMetadata,
            ...metadata,
            type: 'PROSTATE_CANCER_METADATA',
          },
          morphologies: slide?.slideMetadata?.morphologies,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]),
    },
  )
  return mutation
}

export const useMutateSlideCaseLabelRotation = (caseId: number, slideId: number) => {
  const queryClient = useQueryClient()

  const mutation = useMutation(
    async (labelRotation: number) => {
      if (caseId && slideId) {
        const data = {
          data: [
            {
              labelRotation,
              slideId,
            },
          ],
        }
        await slideService.updateRotateLabels({
          caseId,
          data,
        })
      }
    },
    {
      onSuccess: () => queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId]),
    },
  )
  return mutation
}

export const useLabelImageQuery = (
  { caseId, slideId, source }: { slideId: number; caseId: number; source: ISource },
  options?:
    | UseQueryOptions<string, unknown, string, (QUERY_TYPE | { slideId: number; caseId: number; source: ISource })[]>
    | undefined,
) =>
  useQuery(
    [QUERY_TYPE.LABEL_IMAGE, { caseId, slideId, source }],
    () => slideService.getLabelImage(caseId, slideId, source).then((res) => res.url),
    options,
  )

/**
 * Обновление информации о просмотре слайда.
 *
 * @param caseId
 * @param slideId
 * @param onError
 */
export const useSlideViewedUpdate = (caseId: number, slideId: number, onError: () => void) => {
  const workspaceId = Number(useCurrentWorkspaceId())

  return useMutation(
    async () => {
      await slideService.updateSlideViewed(workspaceId, caseId, slideId)
    },
    { onError },
  )
}

export const useUpdateSlideDefectStatus = (caseId: number, slideId: number, slideDataLayerId: number) => {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  return useMutation(
    async (falsePositive: boolean) => {
      await slideService.markSlideFalsePositive(caseId, slideId, slideDataLayerId, { falsePositive })
    },
    {
      onSuccess: (data, variables) => {
        const notificationKey = 'defect_marked'
        const message = variables
          ? t('Слайд помечен как "ложный дефект".')
          : t('Пометка "ложный дефект" снята со слайда.')

        queryClient.invalidateQueries([QUERY_TYPE.SLIDE, slideId])
        notices.openOnDetectDefectsNotification({ key: notificationKey, message })
      },
    },
  )
}
