import { Divider } from 'antd'
import { useUpdateSlideDefectStatus } from 'entities/slide/api/query'
import { useViewerIdSlideState, useViewerPageProvided } from 'pages/viewer/lib/common/ViewerPageProvider'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useQueryClient } from 'react-query'
import { QUERY_TYPE } from 'shared/api'
import { MapContext } from 'shared/lib/map'
import { ButtonElement } from 'shared/ui/kit'
import ToolOpacityController from 'shared/ui/tool-opacity-controller'
import styled from 'styled-components'
import { ArtefactClasses } from 'types/IArtefact'
import ISlide from 'types/ISlide'
import { ISlideDataLayer } from 'types/ISlideDataLayer'
import { useArtefactsContext } from 'viewer/map/layers/artefacts/ArtefactsContext'
import { getCenterOfExtent } from 'viewer/map/lib/utils'

import ArtefactsClassElement, { ArtefactsSumElement } from './ArtefactsClassElement'

const StyledText = styled.span`
  display: inline-block;
  width: 100%;
  font-size: 11px;
  color: var(--color-text-3);
  margin-bottom: 8px;
`

const Wrapper = styled.div`
  width: 100%;
`

const WideButton = styled(ButtonElement)`
  width: 100%;
`

/** Продолжительность анимации в милисекундах */
const ANIMATION_DURATION = 250

const ArtefactsContainer = ({ artefactsDataLayer }: { artefactsDataLayer: ISlideDataLayer }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { map } = useContext(MapContext)
  const { defectsInfoDict, features, opacity, setHoveredClass, setOpacity } = useArtefactsContext()
  const { activeViewerId: viewerId } = useViewerPageProvided()
  const { caseId, slideId } = useViewerIdSlideState(viewerId)
  const slide = queryClient.getQueryData<ISlide>([QUERY_TYPE.SLIDE, slideId])
  const slideDefect = slide?.slideDefect
  const isFalsePositive = slideDefect === 'FALSE_POSITIVE'

  const updateSlideDefectMutation = useUpdateSlideDefectStatus(
    caseId,
    slideId,
    artefactsDataLayer?.slideDataLayerId || 0,
  )

  const onSlideMark = () => {
    if (artefactsDataLayer) updateSlideDefectMutation.mutate(!isFalsePositive)
  }

  const onSelectElementHandle = (selectedClass: string) => {
    const feature = features.find((f) => f.get('class') === selectedClass)
    const geometry = feature?.getGeometry()
    if (geometry) {
      map.getView().fit(geometry.getExtent(), {
        duration: ANIMATION_DURATION,
      })
      map.getView().animate({
        center: getCenterOfExtent(geometry.getExtent()),
        duration: ANIMATION_DURATION,
      })
    }
  }

  return (
    <Wrapper>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <StyledText style={{ textAlign: 'left' }}>{t('Тип дефекта')}</StyledText>
        <StyledText style={{ textAlign: 'right' }}>{t('% от ткани')}</StyledText>
      </div>
      <div>
        {features.map((it) => {
          const className = it.get('class') as string
          const tissuePercentage = it.get('tissue_percentage') as number

          return className === ArtefactClasses.artefact_union ? (
            <ArtefactsSumElement key={className} totalArea={tissuePercentage} />
          ) : (
            <ArtefactsClassElement
              key={className}
              area={tissuePercentage || 0}
              color={defectsInfoDict?.[className].mlClassColor ?? ''}
              title={t(defectsInfoDict?.[className].name ?? '')}
              className={className}
              onMouseEnterHandle={setHoveredClass}
              onMouseLeaveHandle={setHoveredClass}
              onClickHandle={onSelectElementHandle}
            />
          )
        })}
      </div>
      <Divider />
      <ToolOpacityController value={opacity} onChange={setOpacity} />
      {slideDefect && (
        <>
          <Divider />
          <WideButton onClick={onSlideMark}>
            {slideDefect === 'FALSE_POSITIVE' ? t('Верный дефект') : t('Ложная детекция дефектов')}
          </WideButton>
        </>
      )}
    </Wrapper>
  )
}

export default ArtefactsContainer
