import { SelectProps } from 'antd/es/select'
import { getDataFromDB } from 'app/indexedDB'
import { useLiveQuery } from 'dexie-react-hooks'
import { Label, LabelTitle } from 'features/additional-order/ui/ModalWrapper/ModalWrapper.styles'
import { TDict } from 'features/dictionaries'
import { EDictionaryNS } from 'features/dictionaries/api/service'
import React, { ChangeEvent, FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { SelectElement, TextAreaElement } from 'shared/ui/kit'
import styled from 'styled-components/macro'
import { TDefectTypeDictionaryItem } from 'types/IDictionary'
import { StyledCheckboxElement } from 'viewer/map/layers/annotations/ui/AnnotationDescriptionAndAreaControl'

type TProps = {
  /** Идентификатор кейса */
  caseId?: number
  /** Коллбек для изменения выбранного дефекта */
  onDefectChange?: (defect: TDefectTypeDictionaryItem | null) => void
  /** Комментарий для МКК */
  handleDefectComment?: (value: ChangeEvent<HTMLTextAreaElement>) => void
  valueDefectComment?: string
  setIsAnyInputFocusing: (value: boolean) => void
}

export const DefectSelectContainer: FC<TProps> = ({
  caseId,
  handleDefectComment,
  onDefectChange = () => {},
  setIsAnyInputFocusing,
  valueDefectComment,
}) => {
  const { t } = useTranslation()
  const [isDefect, setIsDefect] = useState(false)
  const [selectedDefectType, setSelectedDefectType] = useState<TDefectTypeDictionaryItem | null>(null)
  const dictionaryDefectType = useLiveQuery(() => getDataFromDB(EDictionaryNS.DEFECT_TYPE)) as TDict<
    TDefectTypeDictionaryItem[]
  >
  const { data: defectTypes } = { ...dictionaryDefectType }

  const AUTO_SIZE_CONFIG = {
    MAX_ROWS: 10,
    MIN_ROWS: 3,
  }

  const MAX_LENGTH = 300

  useEffect(() => {
    setIsDefect(false)
    setSelectedDefectType(null)
  }, [caseId])

  const toggleDefect = () => {
    setIsDefect((prev) => !prev)
    if (!isDefect) {
      onDefectChange(null)
    }
  }

  const onSelectDefectType: SelectProps['onSelect'] = (selectedDefectTypeId: number) => {
    const newSelectedDefectType = defectTypes.find((defectType) => selectedDefectTypeId === defectType.id) || null

    setSelectedDefectType(newSelectedDefectType)
    onDefectChange(newSelectedDefectType)
  }

  return (
    <DefectWrapper>
      <StyledCheckboxElement checked={isDefect} onChange={toggleDefect}>
        {t('Дефект')}
      </StyledCheckboxElement>
      {isDefect && (
        <>
          <SelectElement
            placeholder={t('Выберите тип дефекта')}
            options={defectTypes?.map(({ id, name }) => ({ label: name, value: id }))}
            value={selectedDefectType?.id}
            onSelect={onSelectDefectType}
          />
          <Label>
            <LabelTitle>{t('Комментарий для контроля качества')}</LabelTitle>
            <TextAreaElement
              onFocus={() => setIsAnyInputFocusing(true)}
              onBlur={() => setIsAnyInputFocusing(false)}
              autoSize={{ maxRows: AUTO_SIZE_CONFIG.MAX_ROWS, minRows: AUTO_SIZE_CONFIG.MIN_ROWS }}
              maxLength={MAX_LENGTH}
              value={valueDefectComment}
              onChange={handleDefectComment}
            />
          </Label>
        </>
      )}
    </DefectWrapper>
  )
}

const DefectWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
  height: 100%;
  margin-top: 14px;
`
